/** @format */

import config from 'config';
import { invokeRestApi } from 'utils/invokeRestApi';
import { emailVerificationCodeTemplate } from 'services/emailService/templates/email-verification-code-template';
import { requestSigneeApprovalTemplate } from 'services/emailService/templates/request-signee-approval-template';
import { sendCompanyRegistrationFullyApprovedTemplate } from 'services/emailService/templates/company-registration-fully-approved-template';

const {
  services: {
    SEND_EMAIL_SERVICE_PATHNAME,
    CONFIRM_VERIFICATION_CODE_SERVICE_PATHNAME
  }
} = config.app;

export async function sendVerificationCodeService({ email, firstName }) {
  return await invokeRestApi({
    service: SEND_EMAIL_SERVICE_PATHNAME,
    payload: emailVerificationCodeTemplate({ email, firstName })
  });
}

export async function requestSigneeApprovalService(payload) {
  return await invokeRestApi({
    service: SEND_EMAIL_SERVICE_PATHNAME,
    payload: requestSigneeApprovalTemplate(payload)
  });
}

export async function sendCompanyRegistrationFullyApprovedService(payload) {
  return await invokeRestApi({
    service: SEND_EMAIL_SERVICE_PATHNAME,
    payload: sendCompanyRegistrationFullyApprovedTemplate(payload)
  });
}

export async function confirmVerificationCodeService(payload) {
  return await invokeRestApi({
    service: CONFIRM_VERIFICATION_CODE_SERVICE_PATHNAME,
    payload: { ...payload, isCorporateWeb: true }
  });
}

export default {
  sendVerificationCodeService,
  requestSigneeApprovalService,
  confirmVerificationCodeService,
  sendCompanyRegistrationFullyApprovedService
};
