/** @format */

import _ from 'lodash';
import { queries } from 'services';
import { store } from 'stateContainer/store';
import * as actionTypes from 'stateContainer/action-types';
import { all, put, call, takeLatest } from 'redux-saga/effects';
import {
  removeSignupDate,
  manipulateSignedUpEmployees,
  manipulateEmployeesYetToSignup
} from 'utils/sagaUtils';

function* workerSaga({ ssn }) {
  try {
    const {
      currentUser: { corporateApprovalData }
    } = store.getState();

    const baseData = yield call(queries.fetchBaseDataQuery, ssn);

    if (
      corporateApprovalData &&
      corporateApprovalData.uuid &&
      corporateApprovalData.uuid.length === 36
    ) {
      yield put({
        type: actionTypes.PERSON_DATA_REQUESTED,
        ssn
      });

      yield put({
        type: actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_REQUESTED,
        payload: corporateApprovalData
      });
    } else {
      yield all([
        put({
          type: actionTypes.PERSON_DATA_REQUESTED,
          ssn
        }),

        put({
          type: actionTypes.FETCH_BOARD_MEMBERSHIP_REQUESTED,
          ssn
        })
      ]);

      /**
       * check how far user had completed registration
       */

      yield put({
        type: actionTypes.__APP_ROUTING_DATA_REQUESTED,
        payload: baseData
      });
    }

    /** checks For AdminUser Object */

    /**
     * check if the current user is an AdminUser and if any of the companies of this user are fully approved
     */

    const signeeListForCurrentUserAsAdminUser =
      (baseData.AdminUser &&
        baseData.AdminUser[0] &&
        baseData.AdminUser[0].company &&
        baseData.AdminUser[0].company.signee &&
        baseData.AdminUser[0].company.signee.length > 0 &&
        baseData.AdminUser[0].company.signee) ||
      [];

    const hasEverySigneeApproved =
      (signeeListForCurrentUserAsAdminUser &&
        signeeListForCurrentUserAsAdminUser.length > 0 &&
        signeeListForCurrentUserAsAdminUser.every(
          (signee) => signee.hasApproved
        )) ||
      undefined;

    /** checks For SigneeUser Object */

    /**
     * extract current user from the signee array to check if user is a valid signee and is pending approval
     */

    const listOfCurrentUserAsSignee =
      (baseData.Signee && baseData.Signee) || {};

    let adminUser = // currentuser as admin user
      (baseData.AdminUser && baseData.AdminUser[0] && baseData.AdminUser[0]) ||
      {};

    const listOfCompaniesWithCurrentUserAsSignee = [];

    for (let i = 0; i < listOfCurrentUserAsSignee.length; i++) {
      const company =
        (listOfCurrentUserAsSignee[i].company &&
          listOfCurrentUserAsSignee[i].company.length > 0 &&
          listOfCurrentUserAsSignee[i].company[0]) ||
        {};

      const companyItem = {
        company: company,
        signeeSSN: listOfCurrentUserAsSignee[i].ssn,
        signeeName: listOfCurrentUserAsSignee[i].name,
        hasApproved: listOfCurrentUserAsSignee[i].hasApproved,
        companyName: (company && company.companyName) || undefined,
        organisationId: (company && company.organisationId) || undefined
      };
      listOfCompaniesWithCurrentUserAsSignee.push(companyItem);
    }

    /**
     * fetch company data for which this user is Admin user of
     */

    const company = (adminUser && adminUser.company && adminUser.company) || {};

    /**
     *
     * Manipulate user and employee data (signed up and yet to signup users)
     * and combine them to be displayed in the table
     *
     */

    let listOfEmployees = [];
    if (company && (company.user || company.employee)) {
      /**
       *
       * important :
       * Do not manipulate the company.user object directly by assigning its
       * value to another variable since altering reassigned values will cause non-primitive
       * data types to modify the original values as well. we will use a deep clone instead
       *
       */

      let clonedUser = JSON.parse(JSON.stringify(company.user));

      clonedUser = removeSignupDate(clonedUser);

      const employee = removeSignupDate(company.employee);

      /*
       * Compare employee array with user array to find out users who have not yet signed up
       */

      const employeesYetToSignup = _.differenceWith(
        employee,
        clonedUser,
        _.isEqual
      );

      /**
       * Manipulate array items with custom data including combined first-name and last-name etc...
       */

      const manipulatedListOfEmployeesYetToSignup =
        manipulateEmployeesYetToSignup(employeesYetToSignup);

      const manipulatedListOfSignedUpEmployees = manipulateSignedUpEmployees(
        company.user
      );

      /**
       * Combine signed up users and yet-to-signup users
       */

      listOfEmployees = [
        ...manipulatedListOfSignedUpEmployees,
        ...manipulatedListOfEmployeesYetToSignup
      ];

      /**
       * update company object under admin user with the list of employees
       */

      adminUser['company']['listOfEmployees'] = listOfEmployees;
    }

    /**
     * update approuting state
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: {
        fetchBaseDataComplete: true,
        hasEverySigneeApproved: hasEverySigneeApproved
      }
    });

    /**
     * update redux store with processed data
     */

    yield put({
      type: actionTypes.FETCH_BASEDATA_SUCCESS,
      existingRecords: {
        adminUser: adminUser,
        company: company,
        listOfEmployees: listOfEmployees,
        listOfCompaniesWithCurrentUserAsSignee:
          listOfCompaniesWithCurrentUserAsSignee
      }
    });
  } catch (error) {
    yield put({ type: actionTypes.FETCH_BASEDATA_FAILED });
  }
}
export function* fetchBaseDataSaga() {
  yield takeLatest(actionTypes.FETCH_BASEDATA_REQUESTED, workerSaga);
}
