/** @format */

import { mutations } from 'services';
import { push } from 'connected-react-router';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    yield call(mutations.approveCompanyRegistrationMutation, payload);

    if (
      payload.isFullyApprovedAndSigned ||
      payload.isSingleSigneeAndCurrentUser
    ) {
      yield put({
        type: actionTypes.SEND_COMPANY_REGISTRATION_FULLY_APPROVED_REQUESTED,
        payload
      });
    }

    /**
     * refresh redux store data
     */

    yield put({ type: actionTypes.FETCH_BASEDATA_REQUESTED, ssn: payload.ssn });

    /**
     * update approuting state
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: {
        isFullyApprovedAndSigned: payload.isFullyApprovedAndSigned
      }
    });

    /**
     * update registration completion on the current user state
     */

    yield put({
      type: actionTypes.APPROVE_COMPANY_REGISTRATION_SUCCESS,
      payload: {
        isRegistrationApproved: true
      }
    });

    if (payload.isLastPersonToApprove) {
      yield put({
        type: actionTypes.SEND_COMPANY_REGISTRATION_FULLY_APPROVED_REQUESTED,
        payload
      });
    }

    yield put(push(payload.successUrl));

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { approveRegistrationOfCompany: 'done' }
    });
  } catch (error) {
    yield put({ type: actionTypes.APPROVE_COMPANY_REGISTRATION_FAILED, error });
  }
}

export function* approveCompanyRegistrationSaga() {
  yield takeLatest(
    actionTypes.APPROVE_COMPANY_REGISTRATION_REQUESTED,
    workerSaga
  );
}
