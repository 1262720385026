/** @format */

import React from 'react';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';

function PrevButton(props) {
  const { history, className } = props;

  const goPrevious = () => {
    return history.goBack();
  };
  return (
    <Button
      className={`go-previous-button btn-pink ${className}`}
      onClick={() => goPrevious()}
    />
  );
}

export default withRouter(PrevButton);
