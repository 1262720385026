/** @format */

import Select from 'react-select';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { routeService } from 'services';
import { Redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import FormField from 'components/Forms/FormField';
import ButtonLoader from 'components/ButtonLoader';
import { useSelector, useDispatch } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import {
  companyChoiceUpdatedAction,
  fetchCorporateApprovalDataAction
} from 'stateContainer/actions';
import NotificationMessage from 'components/NotificationMessage';
import HorizontalSeperatorLine from 'components/HorizontalSeperatorLine';
import { validationSchema } from 'views/Registration/ChooseCompany/validationSchema';
import { chooseCompanyUtils } from 'views/Registration/ChooseCompany/chooseCompanyUtils';
import ApproveCompanyRegistrationModal from 'components/Modals/ApproveCompanyRegistrationModal';

export default (function ChooseCompany(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const search = window.location.search;

  if (search.includes('cid') && search.includes('uid')) {
    const queryString = search;
    const urlParams = new URLSearchParams(queryString);
    const organisationId = urlParams.get('cid');
    const uuid = urlParams.get('uid');

    dispatch(fetchCorporateApprovalDataAction({ organisationId, uuid }));
  }

  const [getChosenCompany, setChosenCompany] = useState('');
  const [isCompanyNotChosenError, setCompanyNotChosenError] = useState(false);

  const appRoutingState = useSelector((state) => state.appRouting);
  const companyState = useSelector((state) => state.company) || {};
  const personState = useSelector((state) => state.person.data) || {};
  const currentUserState = useSelector((state) => state.currentUser) || {};
  const boardMembershipState = useSelector((state) => state.boardMembership);
  const signeeCombinationState = useSelector(
    (state) => state.signeeCombination
  );
  const { isContactEmailValidated } = useSelector((state) => state.appRouting);

  const getChooseCompanyData = chooseCompanyUtils({
    t,
    props,
    personState,
    companyState,
    currentUserState,
    boardMembershipState,
    signeeCombinationState
  });

  const {
    firstNameWithHeading,
    isRequesting,
    initialValues,
    selectDropdownOptions
  } = getChooseCompanyData;

  const handleChange = (e) => {
    setChosenCompany({ organisationId: e.value, companyName: e.label });
  };

  const onSubmit = (values) => {
    let data = {
      ssn: personState.personalNumber,
      successUrl: routeService.register_company_info,
      failedUrl: routeService.register_choose_company,
      isEmailVerified: isContactEmailValidated
    };

    if (values && Object.keys(values).length > 1) {
      /** organisationId was manually entered */

      const isValueFromTextInput =
        values.organisationId && values.organisationId !== '';

      /** no company was selected or entered */
      const noChoiceWasMade =
        getChosenCompany.organisationId &&
        getChosenCompany.organisationId !== '';

      if (isValueFromTextInput) {
        data = { ...data, ...values, isValueFromTextInput: true };
        /** user chose company using dropdown */
      } else if (noChoiceWasMade) {
        data = {
          ...data,
          ...getChosenCompany
        };
      } else {
        return setCompanyNotChosenError(true);
      }
    } else {
      /** user chose to approve a pending company registration */

      data.organisationId = values.organisationId;
      data.successUrl = routeService.register_review_company_info;
    }

    return dispatch(companyChoiceUpdatedAction(data));
  };

  const { existingRecords = {} } = currentUserState;

  const { listOfCompaniesWithCurrentUserAsSignee = [] } = existingRecords;

  const pendingListOfApprovals =
    listOfCompaniesWithCurrentUserAsSignee.length > 0 &&
    listOfCompaniesWithCurrentUserAsSignee.filter((item) => {
      return !item.hasApproved && Object.keys(item.company).length > 0;
    });

  const hasMultipleApprovalsPending = pendingListOfApprovals.length > 1;

  const hasSingleApprovalPending = pendingListOfApprovals.length === 1;

  const addClassName = hasSingleApprovalPending
    ? 'choose-company-form-button-with-pending-approval-alert'
    : '';

  const companyNotChosenError = t('lang_error:companyNotChosenError.text');

  return (
    <>
      {appRoutingState.hasEverySigneeApproved && (
        <Redirect to={routeService.dashboard_homepage} />
      )}
      <div className='choose-company-view' id='registration-flow'>
        <SplitLayout
          flow={props.flow}
          hidePreviousButton
          largeLayout
          isRequesting={isRequesting}
          usePageLoader={true}
          stepsWizard={props.stepsWizard}
          className='postlogin-template'>
          <div className='choose-company-inner-container'>
            <div className='content'>
              <div className='split-layout-left-heading-container'>
                <h1>{firstNameWithHeading}</h1>
              </div>

              {hasSingleApprovalPending && (
                <NotificationMessage
                  className='pending-approval-alert'
                  heading={tprop.notifications.approvalPending.heading.text}
                  description={
                    tprop.notifications.approvalPending.description.text
                  }>
                  <Button
                    className={`pending-approval-alert-btn-small ${props.btnColor}`}
                    onClick={() =>
                      onSubmit({
                        organisationId: pendingListOfApprovals[0].organisationId
                      })
                    }>
                    {tprop.notifications.approvalPending.buttons.text}
                  </Button>
                </NotificationMessage>
              )}

              {hasMultipleApprovalsPending && (
                <ApproveCompanyRegistrationModal
                  {...props}
                  ssn={personState.personalNumber}
                  successUrl={routeService.register_review_company_info}
                  pendingListOfApprovals={pendingListOfApprovals}
                />
              )}

              <div className='block-container'>
                <div className='left-block'>
                  <div className='split-layout-left-description-container'>
                    {tprop.description.map((item, key) => (
                      <p className='text-left' key={key}>
                        {item.text}
                      </p>
                    ))}
                  </div>
                </div>
                <div className='right-block'>
                  <div className='split-layout-left-description-container'>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema(t)}
                      onSubmit={onSubmit}>
                      <Form className='form-group'>
                        <label className='form-label'>
                          {tprop.input[0].label}
                        </label>
                        <Select
                          name='chosenCompany'
                          options={selectDropdownOptions}
                          onChange={handleChange}
                          className='select-dropdown'
                          isDisabled={
                            !boardMembershipState.isCurrentUserHasBoardMemberships
                          }
                          classNamePrefix='react-select '
                        />
                        <div className='horizontal-seperator-line-container'>
                          <HorizontalSeperatorLine className='horizontal-seperator-line-pink' />

                          <div className='horizontal-seperator-center-text'>
                            {tprop.seperator.text}
                          </div>

                          <HorizontalSeperatorLine className='horizontal-seperator-line-pink' />
                        </div>

                        <FormField
                          sm={1}
                          name='organisationId'
                          labeltext={tprop.input[1].label}
                        />

                        <div
                          className={`split-layout-left-form-button-container choose-company-form-button ${addClassName}`}>
                          <div className='text-center'>
                            {isRequesting ? (
                              <ButtonLoader />
                            ) : (
                              <Button
                                className={`${props.btnColor}`}
                                type='submit'>
                                {tprop.buttons.text}
                              </Button>
                            )}
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                  {isCompanyNotChosenError && (
                    <div className='validation-error'>
                      <small>{companyNotChosenError}</small>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </SplitLayout>
      </div>
    </>
  );
});
