/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

export default function RegularHeaderLeft(props) {
  return (
    <div className='header-left'>
      <div className='header-logo-container'>
        {props.disabled ? (
          <div className='logo-the-third-act-white' />
        ) : (
          <Link to={props.homePageUrl}>
            <div className='logo-the-third-act-white' />
          </Link>
        )}
      </div>
    </div>
  );
}
