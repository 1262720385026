/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function NavigationLinks() {
  const { t } = useTranslation();
  const sections = t('lang_menu:menu.sections');
  return (
    <>
      {sections.map((item, key) => (
        <div className={`menu-${item.props.position}`} key={key}>
          <div className='menu-heading-container'>
            <h4>{item.heading.text}</h4>
          </div>
          {item.links.map((link, id) => (
            <NavDropdown.Item key={id} href={link.url} className='menu-button'>
              {link.label}
            </NavDropdown.Item>
          ))}
        </div>
      ))}
    </>
  );
}
