/** @format */

/**
 *
 * Get registration completion states based on the user object in the database
 * @param {Object} {user : Object, caller: String}
 *  @user: {Object} Mandatory - the user object
 *  @caller: {String} Optional - the caller from where the user object initited from. This can be used for debugging purposes
 * @returns JSON object with boolean values for every the steps taken by the user in their profile
 */

export const getUserStatus = ({ user, caller }) => {
  /**
   *
   * default response object
   */
  const data = {
    caller,
    isCompanyExists: false
  };

  if (!user) {
    return data;
  }

  /**
   *
   * get company and employment details
   */
  data.isCompanyExists =
    user.company &&
    user.company.companyName &&
    user.company.companyName.length > 0 &&
    !user.company.isDefaultCompany; // ignore default company

  return data;
};
