/** @format */

import React from 'react';
import LinkifyHelper from 'utils/linkifyHelper';
import { Trans, useTranslation } from 'react-i18next';

export default function TermsAndPolicy(props) {
  const { t } = useTranslation();
  const termsOrPolicyContent = t(`${props.selector}`);

  return (
    <div
      className={`terms-policy-content ${
        props.className ? props.className : 'terms-policy-content-dual-column'
      }`}>
      <div className={`terms-policy-${termsOrPolicyContent.heading.type}`}>
        {termsOrPolicyContent.heading.text}
      </div>
      <div className='terms-policy-container'>
        {termsOrPolicyContent.items.map((block, blockKey) => (
          <div key={blockKey}>
            {block.heading && (
              <div className={`terms-policy-${block.heading.type}`}>
                {block.heading.text}
              </div>
            )}

            {block.content &&
              block.content.map((content, contentKey) => (
                <div key={contentKey}>
                  {content.description.map((description, descriptionKey) => (
                    <div key={descriptionKey}>
                      {description.image && (
                        <div className='terms-policy-image-container'>
                          <div className={`image-${description.image}`} />
                        </div>
                      )}
                      <p key={descriptionKey}>
                        <LinkifyHelper>{description.text}</LinkifyHelper>
                      </p>

                      {description.listItems && (
                        <>
                          <p>{description.listItems.text}</p>
                          <ul>
                            {description.listItems.items.map(
                              (item, itemKey) => (
                                <li key={itemKey}>{item.text}</li>
                              )
                            )}
                          </ul>
                        </>
                      )}
                    </div>
                  ))}

                  {content.addressList &&
                    content.addressList.map((addressItem, addressItemKey) => (
                      <div
                        className='terms-policy-address-content'
                        key={addressItemKey}>
                        {addressItem.address.map((address, addressKey) => (
                          <div
                            className={`terms-policy-${address.type}`}
                            key={addressKey}>
                            {!address.type && address.text}

                            {address.type === 'email' && (
                              <>
                                <span>{address.text}</span>{' '}
                                <Trans>
                                  <a
                                    href={address.url}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    {address.destination}
                                  </a>
                                </Trans>
                              </>
                            )}
                            {address.type === 'url' && (
                              <>
                                <Trans>
                                  <a
                                    href={address.url}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    {address.text}
                                  </a>
                                </Trans>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}
