/** @format */

import config from 'config';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'components/ButtonLoader';
import FormField from 'components/Forms/FormField';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { confirmCompanyRegistrationDataAction } from 'stateContainer/actions';
import { validationSchema } from 'views/Common/ReviewCompanyInfo/validationSchema';
import { reviewCompanyInfoUtils } from 'views/Common/ReviewCompanyInfo/reviewCompanyInfoUtils';

export default function ReviewCompanyInfo(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const sessionState = useSelector((state) => state.session.data);
  const companyState = useSelector((state) => state.company) || {};
  const personState = useSelector((state) => state.person) || {};
  const currentUserState = useSelector((state) => state.currentUser) || {};
  const emailVerificationState = useSelector((state) => state.verification);
  const boardMembershipState = useSelector((state) => state.boardMembership);
  const signeeCombinationState = useSelector(
    (state) => state.signeeCombination
  );

  const getCompanyInfo = reviewCompanyInfoUtils({
    t,
    props,
    personState,
    companyState,
    sessionState,
    currentUserState,
    boardMembershipState,
    emailVerificationState,
    signeeCombinationState
  });

  const {
    companyName,
    isRequesting,
    initialValues,
    currentUserIsAdminUser,
    isSingleSigneeAndCurrentUser
  } = getCompanyInfo;

  const successUrl =
    props.flowOrigin === config.constants.flowOrigins.registration
      ? routeService.register_agree_to_terms
      : routeService.dashboard_agree_to_terms;

  const failedUrl =
    props.flowOrigin === config.constants.flowOrigins.registration
      ? routeService.register_choose_company
      : routeService.dashboard_homepage;

  const onSubmit = (values) => {
    const data = {
      fid: sessionState.fid,
      ssn: sessionState.ssn,
      name: currentUserState.chosenCompany.adminUserName,
      contactEmail: currentUserState.chosenCompany.contactEmail,
      contactPhone: currentUserState.chosenCompany.contactPhone,
      organisationId: currentUserState.chosenCompany.organisationId,
      visitingAddress1: values.visitingAddress1,
      visitingAddress2: values.visitingAddress2,
      visitingCity: values.visitingCity,
      visitingPostCode: values.visitingPostCode,
      mailingAddress1: values.mailingAddress1,
      mailingAddress2: values.mailingAddress2,
      mailingCity: values.mailingCity,
      mailingPostCode: values.mailingPostCode,
      visitingAddress: {
        address1: values.visitingAddress1,
        address2: values.visitingAddress2,
        city: values.visitingCity,
        postCode: values.visitingPostCode
      },
      mailingAddress: {
        address1: values.mailingAddress1,
        address2: values.mailingAddress2,
        city: values.mailingCity,
        postCode: values.mailingPostCode
      },
      isSingleSigneeAndCurrentUser: isSingleSigneeAndCurrentUser,
      successUrl: successUrl,
      failedUrl: failedUrl,
      agreeTermsUrl: routeService.register_agree_to_terms,
      isApprovalFlow: true,
      currentUserIsAdminUser: currentUserIsAdminUser
    };

    dispatch(confirmCompanyRegistrationDataAction(data));
  };

  const PageContent = () => (
    <>
      {!companyState.error && !isRequesting && (
        <div className='text-left personal-info-container'>
          <div className='split-layout-left-heading-container'>
            <h1>{companyName}</h1>
          </div>

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(t)}
            onSubmit={onSubmit}>
            {({ values }) => (
              <Form className='form-group'>
                <div className='content'>
                  <div className='block-container'>
                    <div className='left-block'>
                      <Row>
                        <Col>
                          <FormField
                            name='visitingAddress1'
                            labeltext={tprop.input.visitingAddress[0].label}
                          />
                        </Col>
                      </Row>
                      <Row className='row-reset-height'>
                        <Col>
                          <FormField name='visitingAddress2' />
                        </Col>
                      </Row>
                      <Row className='address2-container'>
                        <Col sm={4}>
                          <FormField
                            sm={1}
                            name='visitingPostCode'
                            labeltext={tprop.input.visitingAddress[2].label}
                          />
                        </Col>
                        <Col sm={8}>
                          <FormField
                            sm={1}
                            name='visitingCity'
                            labeltext={tprop.input.visitingAddress[3].label}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormField
                            name='mailingAddress1'
                            labeltext={tprop.input.mailingAddress[0].label}
                          />
                        </Col>
                      </Row>
                      <Row className='row-reset-height'>
                        <Col>
                          <FormField name='mailingAddress2' />
                        </Col>
                      </Row>

                      <Row className='address2-container'>
                        <Col sm={5}>
                          <FormField
                            sm={1}
                            name='mailingPostCode'
                            labeltext={tprop.input.mailingAddress[2].label}
                          />
                        </Col>
                        <Col sm={7}>
                          <FormField
                            sm={1}
                            name='mailingCity'
                            labeltext={tprop.input.mailingAddress[3].label}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='right-block'>
                      <Row>
                        <Col sm={4}>
                          <FormField
                            name='contactPersonFirstName'
                            labeltext={tprop.input.firstName.label}
                            disabled
                          />
                        </Col>
                        <Col className='add-top-margin-14' sm={8}>
                          <FormField
                            name='contactPersonLastName'
                            labeltext={' '}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormField
                            sm={1}
                            name='contactPersonEmail'
                            labeltext={tprop.input.email.label}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormField
                            sm={1}
                            name='contactPersonPhone'
                            labeltext={tprop.input.mobile.label}
                            disabled
                          />
                        </Col>
                      </Row>
                      <div className='split-layout-left-form-button-container'>
                        <div className='text-center'>
                          {isRequesting ? (
                            <ButtonLoader />
                          ) : (
                            <Link to={successUrl}>
                              <Button
                                className={`${props.btnColor}`}
                                onClick={() => onSubmit(values)}>
                                {tprop.buttons.text}
                              </Button>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );

  return (
    <div className='company-info-view' id='registration-flow'>
      {props.flow === config.constants.flows.dashboard ? (
        <DashboardLayout
          {...props}
          isRequesting={isRequesting}
          hidePreviousButton={true}
          goBackUrl={routeService.onboarding_welcome_to_onboarding}>
          <PageContent />
        </DashboardLayout>
      ) : (
        <SplitLayout
          flow={props.flow}
          largeLayout
          isRequesting={isRequesting}
          stepsWizard={props.stepsWizard}
          className='postlogin-template'
          backgroundImage='registration-welcome-left-background-image'>
          <PageContent />
        </SplitLayout>
      )}
    </div>
  );
}
