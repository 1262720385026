/** @format */
import React from 'react';
import config from 'config';
import MobileHeaderMenu from 'components/Layout/Header/MobileHeader/MobileHeaderRight/MobileHeaderMenu';
import MobileProfileBlock from 'components/Layout/Header/MobileHeader/MobileHeaderRight/MobileProfileBlock';

export default function MobileHeaderRight(props) {
  return (
    <div className='mobile-header-right'>
      {props.flow !== config.constants.flows.registration && (
        <MobileProfileBlock {...props} />
      )}
      <MobileHeaderMenu {...props} />
    </div>
  );
}
