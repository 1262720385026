/** @format */

import routeService from 'services/routeService';

let appConfig = process.env.REACT_APP_CONFIG;

if (typeof JSON.parse(JSON.stringify(appConfig)) === 'string') {
  appConfig = JSON.parse(appConfig);
} else {
  appConfig = JSON.parse(JSON.stringify(appConfig));
}

const { register_bankid_redirect } = routeService;

const {
  CLIENT_ID,
  ENVIRONMENT,
  BASE_APP_URL,
  CRIIPTO_BASE_URL,
  FORCE_SAME_DEVICE,
  CRIIPTO_REQUEST_SCOPE,
  DIGITAL_SIGNATURE_RETURN_API_URL
} = appConfig;

const sessionEndTime = new Date().getTime() + 1000 * 60 * 48; // 48 minutes

const AUTHORITY = CRIIPTO_BASE_URL.replace(/(^\w+:|^)\/\//, '');

const config = {
  ENVIRONMENT,
  mobileScreenMaxWidth: 919,
  base_app_uri: `${BASE_APP_URL}`,
  sessionEndTime: sessionEndTime,
  defaultDateValue: '1900-01-01',
  defaultPaymentMode: 'Faktura',
  sortingLocale: 'sv', // determines the language used by the sort algorithm for table headers
  tableDateFormat: 'YYYY-MM-DD HH:MM',
  criipto: {
    settings: {
      authority: AUTHORITY,
      client_id: CLIENT_ID,
      response_type: `id_token`,
      redirect_uri: `${BASE_APP_URL}${register_bankid_redirect}`,
      post_logout_redirect_uri: `${BASE_APP_URL}${register_bankid_redirect}`,
      same_device: `urn:grn:authn:se:bankid:same-device`,
      another_device: `urn:grn:authn:se:bankid:another-device`,
      force_same_device: FORCE_SAME_DEVICE,
      scope: CRIIPTO_REQUEST_SCOPE,
      wa: `wsignin1.0`,
      sign_redirect_uri: `${DIGITAL_SIGNATURE_RETURN_API_URL}`
    },
    endpoints: {
      authorize: `${CRIIPTO_BASE_URL}/oauth2/authorize`,
      token: `${CRIIPTO_BASE_URL}/oauth2/token`,
      userinfo: `${CRIIPTO_BASE_URL}/oauth2/userinfo`,
      logout: `${CRIIPTO_BASE_URL}/oauth2/logout`,
      jwks: `${CRIIPTO_BASE_URL}/.well-known/jwks`,
      issuer: `${CRIIPTO_BASE_URL}`,
      sign: `${CRIIPTO_BASE_URL}/sign/text`
    }
  },

  services: {
    API_GATEWAY_URL: process.env.REACT_APP_API_GATEWAY_URL,
    SEND_EMAIL_SERVICE_PATHNAME: '/emailservice/emailsender',
    FETCH_PERSON_DATA_SERVICE_PATHNAME: '/roaring/person-data',
    FETCH_SIGN_RIGHTS_SERVICE_PATHNAME: '/roaring/sign-rights',
    FETCH_BOARD_MEMBERSHIP_SERVICE_PATHNAME: '/roaring/board-membership',
    FETCH_COMPANY_DATA_SERVICE_PATHNAME: '/roaring/company-data',
    FETCH_SIGNEE_COMBINATION_SERVICE_PATHNAME: '/roaring/company-signee',
    STORE_POWER_OF_ATTORNEY_SERVICE_PATHNAME:
      '/poaservice/store-power-of-attorney',
    CREATE_POWER_OF_ATTORNEY_SERVICE_PATHNAME:
      '/poaservice/create-power-of-attorney',
    CONFIRM_VERIFICATION_CODE_SERVICE_PATHNAME: '/emailservice/verifycode',
    SUBSCRIPTION_SERVICE: '/payment-service/create-subscription',
    CANCEL_SUBSCRIPTION_SERVICE: '/payment-service/cancel-subscription',
    LIST_SUBSCRIPTION_SERVICE: '/payment-service/list-subscription',
    LIST_PAYMENT_SERVICE: '/payment-service/list-payment',
    CREATE_EMPLOYEE_IN_BULK_SERVICE_PATHNAME: '/employee/bulk-upload'
  },

  /**
   * Reserved Routes
   * These are public routes that are reserved for authentictaion actions
   * They will not be validated for valid sessions and are a core part of the app behavior
   * especially when navigating out form the application to third-party integrations
   */

  reservedRoutes: [
    routeService.register_bankid_redirect,
    routeService.profile_bankid_redirect,
    routeService.common_logging_in,
    routeService.common_logging_out
  ]
};

export default config;
