/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = { requesting: false, data: {} };

export const verificationCodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SEND_VERIFICATION_CODE_REQUESTED:
      return {
        ...state,
        requesting: true,
        data: action.payload,
        error: false
      };

    case actionTypes.SEND_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        requesting: false
      };

    case actionTypes.SEND_VERIFICATION_CODE_FAILED:
      return {
        ...state,
        requesting: false,
        data: action.error,
        error: true
      };

    case actionTypes.UPDATE_VERIFICATION_STATUS:
      return {
        ...state,
        data: action.payload.data,
        isEmailVerified: action.payload
      };

    case actionTypes.CONFIRM_VERIFICATION_CODE_REQUESTED:
      return {
        ...state,
        requesting: true,
        data: action.payload,
        error: false
      };

    case actionTypes.CONFIRM_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data,
        isEmailVerified: action.isEmailVerified,
        error: false
      };

    case actionTypes.CONFIRM_VERIFICATION_CODE_FAILED:
      return {
        ...state,
        requesting: false,
        data: action.data,
        error: true
      };

    case actionTypes.UPDATE_EMAIL_REQUESTED:
      return {
        ...state,
        requesting: true
      };

    case actionTypes.UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        requesting: false,
        isEmailVerified: false
      };

    case actionTypes.UPDATE_EMAIL_FAILED:
      return {
        ...state,
        requesting: false
      };
    default:
      return state;
  }
};
