/** @format */
import lang_menu from './lang_menu.json';
import lang_route from './lang_route.json';
import lang_error from './lang_error.json';
import lang_common from './lang_common.json';
import lang_footer from './lang_footer.json';
import lang_cookies from './lang_cookies.json';
import lang_dashboard from './lang_dashboard.json';
import lang_registration from './lang_registration.json';
import lang_privacy_policy from './lang_privacy_policy.json';
import lang_how_we_calculate from './lang_how_we_calculate.json';
import lang_how_we_choose_funds from './lang_how_we_choose_funds.json';
import lang_terms_and_conditions from './lang_terms_and_conditions.json';
import lang_sustainability_policy from './lang_sustainability_policy.json';

const swedish = {
  lang_menu,
  lang_error,
  lang_route,
  lang_common,
  lang_footer,
  lang_cookies,
  lang_dashboard,
  lang_registration,
  lang_privacy_policy,
  lang_how_we_calculate,
  lang_how_we_choose_funds,
  lang_terms_and_conditions,
  lang_sustainability_policy
};

export default swedish;
