/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import getUrlParams from 'utils/getUrlParams';
import PuffLoader from 'react-spinners/PuffLoader';
import SplitLayout from 'components/Layout/SplitLayout';
import getCriiptoAuthorizeUri from 'utils/getCriiptoAuthorizeUri';
import SocialSecurityNumber from 'components/SocialSecurityNumber';

export default function Login(props) {
  const { samedevice } = getUrlParams(['samedevice']);
  const { flow, tprop } = props;
  const authorize_url = getCriiptoAuthorizeUri({ samedevice });

  return (
    <div className='login-view'>
      <SplitLayout
        flow={flow}
        hidePreviousButton={true}
        hideMobileHeader={true}
        hideLogoutButton={true}
        className='prelogin-template prelogin-template-login-page'>
        <Link to={'/'}>
          <div className='logo-the-third-act' />
        </Link>

        {tprop.heading && (
          <h1 className='start-page-heading'>{tprop.heading.text}</h1>
        )}

        {samedevice === 'false' && <div className='logo-bankid' />}

        <div>
          {samedevice === 'false' && (
            <div className='login-ssn-label-container'>
              <p>{tprop.input.label}</p>
            </div>
          )}

          {samedevice === 'true' && (
            <div className='same-device-container'>
              <div className='ssn-input-container'>
                <br />
                <div className='loader-wrapper'>
                  <PuffLoader size={96} color={'var(--color-black)'} loading />
                </div>

                <SocialSecurityNumber
                  src={authorize_url}
                  title={tprop.input.label}
                  placeholder={tprop.input.placeholder}
                />
              </div>
            </div>
          )}
        </div>
        {samedevice === 'false' && (
          <div className='another-device-container'>
            <div className='loader-wrapper'>
              <PuffLoader size={50} color={'var(--color-black)'} loading />
            </div>
            <SocialSecurityNumber
              src={authorize_url}
              title={tprop.input.label}
              placeholder={tprop.input.placeholder}
            />
          </div>
        )}
      </SplitLayout>
    </div>
  );
}
