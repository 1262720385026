/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  requesting: false,
  data: {}
};

export const companyDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPANY_DATA_REQUESTED:
      return { ...state, requesting: true, error: false, errorData: '' };

    case actionTypes.COMPANY_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data,
        error: false,
        errorData: ''
      };

    case actionTypes.COMPANY_DATA_SKIPPED:
      return { ...state, requesting: false };

    case actionTypes.COMPANY_DATA_FAILED:
      return {
        ...state,
        data: {},
        requesting: false,
        error: true,
        errorData: action.error
      };

    default:
      return state;
  }
};
