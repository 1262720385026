/** @format */

import React from 'react';

export default function HorizontalSeperatorLine(props) {
  const { className } = props;
  return (
    <>
      <svg width='70' height='20'>
        <line
          className={`horizontal-seperator-line ${className ? className : ''}`}
          x1='0'
          y1='10'
          x2='68'
          y2='10'
        />
      </svg>
    </>
  );
}
