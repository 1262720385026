/** @format */

import React from 'react';
import { routeService } from 'services';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

export default function PageNotFound(props) {
  const { t } = useTranslation();
  const tprop = t(`lang_${props.flow}:${props.view}`);

  return (
    <>
      <div className='page-not-found-container centered-container image-background-page-not-found'>
        <div className='centered-content  centered-content-theme-black'>
          <div className='centered-content-top registration-complete-top-container'>
            <h1>{tprop.heading.text}</h1>
            <hr className='horizontal-divider' />
            <h2>{tprop.subheading.text}</h2>
          </div>
          <div className='centered-content-bottom page-not-found-description'>
            <p>{tprop.description.text}</p>
            <Link
              to={`${routeService.register_start}`}
              className='mx-auto text-center'>
              <Button className='btn btn-pink'>{tprop.buttons.text}</Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
