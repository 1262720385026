/** @format */

import React from 'react';
import { Formik, Form } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { routeService } from 'services';
import { Link } from 'react-router-dom';
import Radio from 'components/Forms/Radio';
import Button from 'react-bootstrap/Button';
import ButtonLoader from 'components/ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import { storeSigneeCombinationAction } from 'stateContainer/actions';

export default function ChooseSigneeCombination(props) {
  const { tprop } = props;
  const dispatch = useDispatch();

  const signeeCombinationState = useSelector(
    (state) => state.signeeCombination
  );

  const initialValues = {
    signeeCombination: ''
  };

  const isRequesting = false;

  const getSigneeNameCombo = (combination) => {
    let signeeNameCombo = '';

    combination.forEach((item) => {
      return (signeeNameCombo += `${item.name}, `);
    });

    signeeNameCombo = signeeNameCombo.slice(0, -2); // remove comma from the last name string
    signeeNameCombo = signeeNameCombo.trim(); // remove leading spaces
    return signeeNameCombo;
  };

  const onSubmit = (values) => {
    const signeeCombination = JSON.parse(values.signeeCombination);

    dispatch(storeSigneeCombinationAction(signeeCombination));
  };

  return (
    <div className='choose-signee-view'>
      <div id='registration-flow'>
        <SplitLayout
          largeLayout
          flow={props.flow}
          stepsWizard={props.stepsWizard}
          className='postlogin-template agree-terms-container'
          backgroundImage='registration-welcome-left-background-image'>
          <Formik initialValues={initialValues}>
            {({ values }) => (
              <Form className='form-group'>
                <div className='content'>
                  <div className='text-content'>
                    <div className='split-layout-left-heading-container'>
                      <h1>{tprop.heading.text}</h1>
                    </div>
                    <span className='split-layout-left-description-container'>
                      <p>{tprop.description.text}</p>
                    </span>

                    <div className='split-layout-left-subheading-container'>
                      <h2>{tprop.subheading.text}</h2>
                    </div>
                  </div>
                  <div className='block-container'>
                    <div className='left-block text-left'>
                      {signeeCombinationState.data.combinations.map(
                        (combination, key) => (
                          <Row key={key} className='radio-row'>
                            <Col>
                              <Radio
                                name='signeeCombination'
                                value={JSON.stringify(combination)}
                                className='select-combo'
                                labeltext={getSigneeNameCombo(combination)}
                                labelstyle=''
                              />
                            </Col>
                          </Row>
                        )
                      )}

                      <div className='agree-terms-button-container'>
                        <div className='summary-submit-button-container'>
                          {tprop.buttons.map((button, key) => (
                            <span key={key}>
                              {isRequesting ? (
                                <ButtonLoader />
                              ) : (
                                <Link
                                  to={
                                    routeService.register_request_signee_approval
                                  }>
                                  <Button
                                    className={`btn-primary-transparent ${props.btnColor}`}
                                    onClick={() => onSubmit(values)}>
                                    {button.text}
                                  </Button>
                                </Link>
                              )}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </SplitLayout>
      </div>
    </div>
  );
}
