/** @format */

import { mutations } from 'services';
import { push } from 'connected-react-router';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  const {
    firstName,
    successUrl,
    failedUrl,
    contactEmail,
    redirectUserUrl,
    verifyEmailUrl,
    isChosenCompanyExists,
    isContactEmailValidated,
    isSingleSigneeAndCurrentUser,
    isPendingContactEmailValidation,
    currentUserIsSoleSigneeOfACombination
  } = payload;
  try {
    if (isPendingContactEmailValidation) {
      /**
       * skip company and adminuser  creation and verify email
       */

      yield put({ type: actionTypes.REGISTER_COMPANY_SKIPPED });
      yield put({
        type: actionTypes.SEND_VERIFICATION_CODE_REQUESTED,
        payload: { email: contactEmail, firstName }
      });
      yield put(push(verifyEmailUrl));
    } else if (isChosenCompanyExists && isContactEmailValidated) {
      /**
       * skip company and adminuser  creation , verify email again and take the user through the rest of the registration flow
       */

      yield put({ type: actionTypes.REGISTER_COMPANY_SKIPPED });
      yield put(push(redirectUserUrl));
    } else {
      if (
        isSingleSigneeAndCurrentUser ||
        currentUserIsSoleSigneeOfACombination
      ) {
        yield call(mutations.registerCompanyWithSigneeMutation, payload);
        yield put({
          type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
          payload: {
            currentUserIsAdminUser: true
          }
        });
      } else {
        yield call(mutations.registerCompanyMutation, payload);
      }

      /**
       * send email verification code
       */

      yield put({
        type: actionTypes.SEND_VERIFICATION_CODE_REQUESTED,
        payload: { email: contactEmail, firstName }
      });
      yield put(push(successUrl));

      /**
       * create and update user state with create user status
       */

      yield put({
        type: actionTypes.REGISTER_COMPANY_SUCCESS,
        listOfSignees: payload.listOfSignees
      });

      /**
       * update appRouting state with registration progress
       */

      yield put({
        type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
        payload: { registerCompanyAndUser: 'done' }
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.REGISTER_COMPANY_FAILED, error });
    yield put(push(failedUrl));
  }
}

export function* createCompanySaga() {
  yield takeLatest(actionTypes.REGISTER_COMPANY_REQUESTED, workerSaga);
}
