/** @format */

/**
 *
 * check if current user is listed as one of the signees
 */

const getIsCurrentUserOneOfSignee = ({ signeeCombination, personalNumber }) => {
  let isListedAsSignee = false;
  signeeCombination &&
    signeeCombination.combinations &&
    signeeCombination.combinations.forEach((combination) =>
      combination.forEach((signee) => {
        const ssnMatchWasFound = signee.personalNumber === personalNumber;
        /**
         * update isListedAsSignee to true only if it is still false
         * this will prevent any subsequent checks updating the boolean to false
         */
        if (!isListedAsSignee && ssnMatchWasFound) isListedAsSignee = true;
      })
    );
  return isListedAsSignee;
};

/**
 *
 * check if signee is a single person
 */

const getIsSingleSignee = ({ signeeCombination }) => {
  return (
    signeeCombination.combinations.length === 1 &&
    signeeCombination.combinations[0].length === 1
  );
};

/**
 *
 * check if signee is a single person and same as the currently logged in user
 */

const getIsSingleSigneeAndCurrentUser = ({
  signeeCombination,
  personalNumber
}) => {
  return (
    getIsSingleSignee({ signeeCombination }) &&
    signeeCombination.combinations[0][0].personalNumber === personalNumber
  );
};

/**
 *
 * apply correction and reverse the lastname and firstname order on the signees name
 */

const getSigneeNamesReversed = (combinations) => {
  return combinations.forEach((combination) => {
    combination.forEach((person) => {
      const nameStringArray = person.name.split(',');

      const getNamesInReverse = () => {
        let reversedName = '';
        for (let i = nameStringArray.length; i > 0; i--) {
          reversedName += ` ${nameStringArray[i - 1]}`;
        }

        return (person.name = reversedName.trim());
      };

      const result = getNamesInReverse();

      return result.trim();
    });
  });
};

/**
 *
 * check if current user is listed as one of the signees and the combination lists the user as the only signee
 */

const getCurrentUserIsSoleSigneeOfACombination = ({
  signeeCombinations,
  personalNumber
}) => {
  return signeeCombinations.some((combination) => {
    return (
      combination.length === 1 &&
      combination[0].personalNumber === personalNumber
    );
  });
};

const geAnomalyData = ({ signeeCombinations }) => {
  let data;
  signeeCombinations.forEach((combination) => {
    return combination.some((signee) => {
      return (data = {
        isAnomalyDetected: signee.anomalies && signee.anomalies.length > 0,
        organisationIdAnomaly: signee.personalNumber
      });
    });
  });
  return data;
};

export {
  getIsSingleSignee,
  geAnomalyData,
  getSigneeNamesReversed,
  getIsCurrentUserOneOfSignee,
  getIsSingleSigneeAndCurrentUser,
  getCurrentUserIsSoleSigneeOfACombination
};
