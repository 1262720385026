/** @format */

import * as Yup from 'yup';

export const getValidationCodeSchema = (t) =>
  Yup.object({
    verificationCode: Yup.string()
      .required(t(`lang_error:forms.required`))
      .min(5, t(`lang_error:validateEmail.length.text`))
      .max(5)
  });
