/** @format */

import config from 'config';
import moment from 'moment';
import i18next from 'i18next';

const tableDateFormat = config.app.tableDateFormat;

const translations = i18next.t(`lang_dashboard:homepage.tabs`)[1];

/**
 * Remove signupdate from user and employee array items
 * @param {array} data
 * @returns data array with signupDate removed from all of its objects
 */

export const removeSignupDate = (data) => {
  data.forEach((item) => delete item.signupDate);
  return data;
};

/**
 * mark employee items in array those have not yet signed up
 * @param {array} data
 * @returns data array with hasSignedUp flag and signupdate
 */

export const manipulateEmployeesYetToSignup = (data, organisationId) => {
  data.forEach((item) => {
    item.hasSignedUp = translations.table.booleanText.no;
    item.isSignedUpEmployee = false;
    item.signupDate = '-';
    item['fullName'] = `${item.firstName} ${item.lastName}`;
    item['wasManipulated'] = true;
    if (item.identity) {
      item.ssn = item.identity;
    }
    if (organisationId) {
      item.organisationId = organisationId;
    }
  });
  return data;
};

/**
 * mark employee items in array those have signed up
 * @param {array} data
 * @returns data array with hasSignedUp flag and signupdate
 */

export const manipulateSignedUpEmployees = (data) => {
  data.forEach((item) => {
    if ((item.signupDate && item.signupDate.formatted) || item.signupDate) {
      item.hasSignedUp = translations.table.booleanText.yes;

      item.signupDate = moment(item.signupDate.formatted).format(
        tableDateFormat
      );
    } else {
      item.hasSignedUp = translations.table.booleanText.no;

      item.signupDate = '-';
    }
    item['fullName'] = `${item.firstName} ${item.lastName}`;
    item['wasManipulated'] = true;
  });
  return data;
};
