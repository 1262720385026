/** @format */

import config from 'config';
import React, { useEffect } from 'react';
import { routeService } from 'services';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LogoutButton from 'components/LogoutButton';
import DashboardLayout from 'components/Layout/DashboardLayout';

export default withRouter(function RegistrationComplete(props) {
  const { tprop, flow } = props;

  const isDashBoardFlow = props.flowOrigin === config.constants.flows.dashboard;

  const currentUserState = useSelector((state) => state.currentUser.data);
  const appRoutingState = useSelector((state) => state.appRouting);

  const isRegistrationCompletingAdminUser =
    !isDashBoardFlow &&
    appRoutingState.isFullyApprovedAndSigned &&
    appRoutingState.currentUserIsAdminUser;

  const isDashboardApprovalFlow =
    isDashBoardFlow && appRoutingState.isFullyApprovedAndSigned;
  useEffect(() => {
    if (isRegistrationCompletingAdminUser || isDashboardApprovalFlow) {
      const that = props;
      const destinationUrl = `${routeService.dashboard_homepage}`;

      setTimeout(() => {
        that.history.push(destinationUrl);
      }, 3000); // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, []);

  return (
    <>
      {isDashBoardFlow ? (
        <div id='dashboard-flow'>
          <DashboardLayout
            {...props}
            isRequesting={currentUserState.requesting}
            hidePreviousButton={true}
            goBackUrl={routeService.onboarding_welcome_to_onboarding}>
            <div className='registration-complete-container centered-container dashboard-flow'>
              <div className='centered-content'>
                <div className='registration-complete-top-container centered-content-top'>
                  <div className='image-success'></div>

                  <h1>{tprop.heading.text}</h1>
                  <div className='registration-complete-top-contentlist'>
                    {tprop.description.map((item, key) => (
                      <p key={key}>{item.text}</p>
                    ))}
                  </div>
                </div>
                <div className='centered-content-bottom '>
                  <div className='logo-the-third-act' />
                  <div>
                    <h2>{tprop.logo.text}</h2>
                  </div>
                </div>
              </div>
            </div>
          </DashboardLayout>
        </div>
      ) : (
        <>
          <LogoutButton flow={flow} />

          <div className='registration-complete-container centered-container centered-container-default-background-image'>
            <div className='centered-content'>
              <div className='registration-complete-top-container centered-content-top'>
                <div className='image-success'></div>

                <h1>{tprop.heading.text}</h1>
                <div className='registration-complete-top-contentlist'>
                  {tprop.description.map((item, key) => (
                    <p key={key}>{item.text}</p>
                  ))}
                </div>
              </div>
              <div className='centered-content-bottom '>
                <div className='logo-the-third-act-white' />
                <div>
                  <h2>{tprop.logo.text}</h2>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});
