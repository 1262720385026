/** @format */
/**
 * Registration flow template with Left-Right split screens
 * where the left stays the same throughout the flow
 */

import React from 'react';
import Col from 'react-bootstrap/Col';
import Header from 'components/Layout/Header';
import PrevButton from 'components/PrevButton';
import StepsWizard from 'components/StepsWizard';
import LogoutButton from 'components/LogoutButton';
import PuffLoader from 'react-spinners/PuffLoader';

function SplitLayout(props) {
  const {
    flow,
    largeLayout,
    stepsWizard,
    isRequesting,
    hideLogoutButton,
    hideMobileHeader,
    hidePreviousButton
  } = props;

  const hasTopPadding =
    props.className &&
    (props.className.includes('prelogin-template') ||
      props.className.includes('sign-poa-container') ||
      props.className.includes('validate-email-status-container'));

  const paddingClass = hasTopPadding ? 'split-layout-left-add-top-padding' : '';

  const largeLayoutClass = largeLayout ? 'split-layout-left-box-large' : '';
  return (
    <div className='registration-user-left-background-image'>
      {!hideMobileHeader && (
        <Header {...props} hidePreviousButton={hidePreviousButton} />
      )}

      <div
        className={`main-container align-items-center justify-content-center`}>
        <div className='split-layout-container'>
          {!hidePreviousButton && <PrevButton flow={flow} />}
          <Col className={`col-left split-layout split-layout-left `}>
            {stepsWizard && <StepsWizard stepsWizard={stepsWizard} />}

            <div
              className={`text-center split-layout-left-box  ${largeLayoutClass} ${
                props.className || ''
              }`}>
              <div className='top-borderline' />
              <div className={`split-layout-left-inner-box-container `}>
                {isRequesting ? (
                  <div className='choose-company-loader-wrapper'>
                    <div className='loader-box'>
                      <PuffLoader
                        size={96}
                        color={'var(--color-black)'}
                        loading
                      />
                    </div>
                  </div>
                ) : (
                  props.children
                )}
              </div>
            </div>
          </Col>

          <Col className={`col-right split-layout-left ${paddingClass}`}>
            {!hideLogoutButton && <LogoutButton flow={flow} />}
            {/* <div className='split-layout-left-box-container text-center '>
              <div className='split-layout-left-box-content'>{}</div>
            </div> */}
          </Col>
        </div>
      </div>
    </div>
  );
}

export default SplitLayout;
