/** @format */

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Radio from 'components/Forms/Radio';
import { useHistory } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import ButtonLoader from 'components/ButtonLoader';
import { companyChoiceUpdatedAction } from 'stateContainer/actions';

export default function ApproveCompanyRegistrationModal(props) {
  const { tprop, isRequesting } = props;
  let history = useHistory();
  const [showModal, setShowModal] = useState(true);

  const toggleCloseModal = () => {
    setShowModal(false);
    history.push({ pathname: props.redirectUrl });
  };

  const headingText = tprop.modals.choose_company_to_approve.heading.text;

  const descriptionText =
    tprop.modals.choose_company_to_approve.description.text;

  const buttonText = tprop.modals.choose_company_to_approve.buttons;

  const dispatch = useDispatch();

  const initialValues = {
    organisationId: ''
  };

  const onSubmit = (values) => {
    let data = {
      ssn: props.ssn,
      organisationId: values.organisationId,
      isEmailVerified: props.isEmailVerified,
      successUrl: props.successUrl
    };

    return dispatch(companyChoiceUpdatedAction(data));
  };

  return (
    <>
      <div>
        <Modal
          className='message-modal approve-company-registration-modal-container'
          show={showModal}
          onHide={toggleCloseModal}>
          <Modal.Header>
            <Modal.Title className='message-modal-header'>
              {headingText}
            </Modal.Title>
          </Modal.Header>
          <Formik initialValues={initialValues}>
            {({ values, isValid, dirty }) => (
              <>
                <Modal.Body>
                  <p className='modal-description'>{descriptionText}</p>

                  <Form className='form-group'>
                    <div className='content'>
                      <div className='block-container'>
                        <div className='left-block text-left'>
                          {props.pendingListOfApprovals.map((item, key) => (
                            <Row key={key} className='radio-row'>
                              <Col>
                                <Radio
                                  name='organisationId'
                                  value={item.organisationId}
                                  className='select-combo'
                                  labeltext={item.companyName}
                                  labelstyle=''
                                />
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  {isRequesting ? (
                    <ButtonLoader className='resend-validation-button-loader' />
                  ) : (
                    <>
                      <Button
                        className={`btn-primary-transparent ${props.btnColor}`}
                        onClick={() => onSubmit(values)}
                        disabled={!isValid || !dirty}>
                        {buttonText[0].text}
                      </Button>
                      <Button
                        className={`btn-primary-transparent ${props.btnColor}`}
                        onClick={() => toggleCloseModal()}>
                        {buttonText[1].text}
                      </Button>
                    </>
                  )}
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  );
}
