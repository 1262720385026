/** @format */

import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'components/ButtonLoader';
import FormField from 'components/Forms/FormField';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import { createSigneeCombinationOnCompanyAction } from 'stateContainer/actions';

export default function RequestSigneeApproval(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    requesting,
    data: { email = '', name },
    chosenCompany,
    chosenSigneeCombination = []
  } = useSelector((state) => state.currentUser);

  const {
    data: {
      isSingleSignee = false,
      isCurrentUserOneOfSignee = false,
      isSingleSigneeAndCurrentUser = false
    }
  } = useSelector((state) => state.signeeCombination);

  /**
   * dynamically generate initial values for the form
   * based on the number of participants ina signee combination
   */

  const initialValues = {};

  const populateInitialValues = () => {
    if (isSingleSigneeAndCurrentUser) {
      return (initialValues['email_0'] = email);
    } else {
      return chosenSigneeCombination.forEach(
        (item, key) => (initialValues[`email_${key}`] = '')
      );
    }
  };
  populateInitialValues();

  /**
   * dynamically generate validation schema object based on initialValues
   */

  const getValidationSchemaItems = () => {
    const schemaItems = {};

    Object.keys(initialValues).forEach((item) => {
      schemaItems[item] = Yup.string()
        .email(t(`lang_error:forms.formatInvalid`))
        .required(t(`lang_error:forms.required`));
    });
    return schemaItems;
  };

  /**
   * populate validationSchema based on the previously created schemaItems
   */

  const validationSchema = Yup.object(getValidationSchemaItems());

  const getSuccessUrl = () => {
    let successUrl = routeService.register_signee_approval_request_sent;

    if (isSingleSigneeAndCurrentUser) {
      successUrl = routeService.register_agree_to_terms;
      // has only one signee but current user is not the signee
    } else if (!isSingleSignee && isCurrentUserOneOfSignee) {
      successUrl = routeService.register_agree_to_terms;
    }

    return successUrl;
  };

  /**
   * include signee email into signee combinations
   */
  const getUpdatedSigneeCombination = (values) => {
    const valuesInArrayForm = Object.keys(values).map((i) => values[i]);

    const updatedCombination = chosenSigneeCombination.map(
      (item, key) => (item = { ...item, email: valuesInArrayForm[key] })
    );
    return updatedCombination;
  };

  const onSubmit = (values) => {
    const updatedCombination = getUpdatedSigneeCombination(values);

    const successUrl = getSuccessUrl();

    const data = {
      organisationId: chosenCompany.organisationId,
      companyName: chosenCompany.companyName,
      requestersName: name,
      signeeCombination: updatedCombination,
      isSingleSignee,
      isCurrentUserOneOfSignee,
      isSingleSigneeAndCurrentUser,
      failedUrl: routeService.register_request_signee_approval,
      successUrl
    };

    return dispatch(createSigneeCombinationOnCompanyAction({ ...data }));
  };

  const isRequesting = requesting;

  return (
    <div className='agree-terms-view'>
      {/* {!currentUserState.email && !emailVerificationState.isEmailVerified && (
        <Redirect to={routeService.register_choose_company} />
      )} */}

      <div id='registration-flow'>
        <SplitLayout
          flow={props.flow}
          stepsWizard={props.stepsWizard}
          className='postlogin-template agree-terms-container'
          backgroundImage='registration-welcome-left-background-image'>
          <div className='block-container'>
            <div className='content'>
              <div className='text-content'>
                <div className='split-layout-left-heading-container'>
                  <h1>{tprop.heading.text}</h1>
                </div>
                <span className='split-layout-left-description-container'>
                  <p>{tprop.description.text}</p>
                </span>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                <Form className='form-group'>
                  <div className='content'>
                    <div className='block-container'>
                      <div className='left-block text-left'>
                        {chosenSigneeCombination.length > 0 &&
                          chosenSigneeCombination.map((item, key) => (
                            <Row key={key}>
                              <Col>
                                <FormField
                                  name={`email_${key}`}
                                  labeltext={item.name}
                                />
                              </Col>
                            </Row>
                          ))}

                        <div className='agree-terms-button-container'>
                          <div className='summary-submit-button-container'>
                            {tprop.buttons.map((button, key) => (
                              <span key={key}>
                                {isRequesting ? (
                                  <ButtonLoader />
                                ) : (
                                  <Button
                                    type='submit'
                                    className={`btn-primary-transparent ${props.btnColor}`}>
                                    {button.text}
                                  </Button>
                                )}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </SplitLayout>
      </div>
    </div>
  );
}
