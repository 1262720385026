/** @format */

import i18n from 'i18next';
import Translations from './translations';
import { initReactI18next } from 'react-i18next';

const CURRENT_LANGUAGE = 'sv-SE';
const FALLBACK_LANGUAGE = 'en-GB';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: CURRENT_LANGUAGE,
    debug: false,
    returnObjects: true,
    fallbackLng: FALLBACK_LANGUAGE,
    resources: Translations,
    interpolation: {
      escapeValue: false,
      joinArrays: true
    }
  });

export default i18n;
