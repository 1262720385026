/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  sessionEndTime: 0,
  isAuthenticated: false,
  sessionIsAboutToExpire: false,
  showSessionExpiredMessage: false,
  requesting: false,
  data: {}
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUESTED:
      return { ...state, requesting: true, id_token: action.payload.token };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        sessionEndTime: action.sessionEndTime,
        requesting: false,
        data: action.data
      };

    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        requesting: false,
        data: action.data
      };

    case actionTypes.LOGOUT_REQUESTED:
      return {
        ...initialState,
        requesting: true
      };

    case actionTypes.LOGOUT_SUCCESS:
      return initialState;

    case actionTypes.LOGOUT_FAILED:
      return initialState;

    /** Show session will expire in ten minutes message */
    case actionTypes.SESSION_ABOUT_TO_EXPIRE_MESSAGE_REQUESTED:
      return {
        ...state,
        sessionIsAboutToExpire: true
      };

    /** Show session expired confirmation message */
    case actionTypes.CONFIRM_END_OF_SESSION_REQUESTED:
      return {
        ...state,
        showSessionExpiredMessage: true
      };

    default:
      return state;
  }
};
