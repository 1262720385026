/** @format */

import * as actionTypes from 'stateContainer/action-types';

export const loginRequestAction = (payload) => ({
  type: actionTypes.LOGIN_REQUESTED,
  payload
});

export const fetchBaseDataAction = (payload) => ({
  type: actionTypes.FETCH_BASEDATA_REQUESTED,
  ssn: payload
});

export const logoutRequestAction = (payload) => ({
  type: actionTypes.LOGOUT_REQUESTED,
  payload
});

export const sessionIsAboutToExpireMessageAction = (payload) => ({
  type: actionTypes.SESSION_ABOUT_TO_EXPIRE_MESSAGE_REQUESTED,
  payload
});

export const confirmEndOfSessionAction = (payload) => ({
  type: actionTypes.CONFIRM_END_OF_SESSION_REQUESTED,
  payload
});

export const personDataRequestAction = (payload) => ({
  type: actionTypes.PERSON_DATA_REQUESTED,
  payload
});

export const registerCompanyRequestAction = (payload) => ({
  type: actionTypes.REGISTER_COMPANY_REQUESTED,
  payload
});

export const sendVerificationCodeAction = (payload) => ({
  type: actionTypes.SEND_VERIFICATION_CODE_REQUESTED,
  payload
});

export const confirmVerificationCodeAction = (payload) => ({
  type: actionTypes.CONFIRM_VERIFICATION_CODE_REQUESTED,
  payload
});

export const updateEmailAction = (payload) => ({
  type: actionTypes.UPDATE_EMAIL_REQUESTED,
  payload
});

export const companyChoiceUpdatedAction = (payload) => ({
  type: actionTypes.COMPANY_CHOICE_REQUESTED,
  payload
});

export const isTermsAndPolicyAgreedAction = (payload) => ({
  type: actionTypes.SET_TERMS_POLICY_AGREED,
  payload
});

export const setIsPaymentModeChosenAction = (payload) => ({
  type: actionTypes.SET_PAYMENT_MODE_CHOSEN,
  payload
});

export const fetchCurrentUserDataAction = (payload) => ({
  type: actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_REQUESTED,
  payload
});
export const requestSigneeApprovalAction = (payload) => ({
  type: actionTypes.REQUEST_SIGNEE_APPROVAL_REQUESTED,
  payload
});

export const fetchCorporateApprovalDataAction = (payload) => ({
  type: actionTypes.FETCH_REQUESTID_FROM_URL,
  payload
});

export const storeSigneeCombinationAction = (payload) => ({
  type: actionTypes.STORE_SIGNEE_COMBINATION_REQUESTED,
  payload
});

export const createSigneeCombinationOnCompanyAction = (payload) => ({
  type: actionTypes.CREATE_SIGNEE_COMBINATION_ON_COMPANY_REQUESTED,
  payload
});

export const confirmCompanyRegistrationDataAction = (payload) => ({
  type: actionTypes.CONFIRM_COMPANY_REGISTRATION_DATA,
  payload
});

export const approveCompanyRegistrationAction = (payload) => ({
  type: actionTypes.APPROVE_COMPANY_REGISTRATION_REQUESTED,
  payload
});

export const createEmployeeAction = (payload) => ({
  type: actionTypes.CREATE_EMPLOYEE_REQUESTED,
  payload
});

export const createEmployeesInBulkAction = (payload) => ({
  type: actionTypes.CREATE_EMPLOYEE_IN_BULK_REQUESTED,
  payload
});

export const deleteEmployeeAction = (payload) => ({
  type: actionTypes.DELETE_EMPLOYEE_REQUESTED,
  payload
});
