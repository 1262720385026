/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const deleteEmployeeMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    removeEmployeeFromCompany(
        identity: "${data.ssn}",
        organisationId: "${data.organisationId}"
    ){
      _id
      company{
        organisationId
        employee{
          _id
          ssn: identity
          firstName
          lastName
          email
          signupDate{
            formatted
          }
          firstName
          lastName
        }
        user {
          _id
          firstName
          lastName
          email
          ssn
          email
          signupDate: createdAt{
            formatted
          }
          firstName
          lastName
        }
      }
    }
  }`);
};
