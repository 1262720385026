/** @format */
/** A custom hook for publicly accessible routes without requiring authentictation */

import React from 'react';
import config from 'config';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import routeService from 'services/routeService';
import { Route, Redirect } from 'react-router-dom';

export default function PublicRoute({ children, ...rest }) {
  const { data = {}, isAuthenticated = false } = useSelector(
    (state) => state.session || {}
  );
  const isLoggedIn = data && isAuthenticated && data.fid;

  //  public routes that are reserved for criipto bankid authentication actions
  const isReservedRoute = config.app.reservedRoutes.includes(rest.path);

  if (isLoggedIn && !isReservedRoute) {
    return <Redirect to={routeService.register_choose_company} />;
  } else {
    return <Route {...rest}>{children}</Route>;
  }
}

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired
};
