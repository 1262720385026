/** @format */
import config from 'config';
import React from 'react';
import { routeService } from 'services';
import Header from 'components/Layout/Header';
import PrevButton from 'components/PrevButton';
import LogoutButton from 'components/LogoutButton';
import TermsAndPolicy from 'components/TermsAndPolicy';
import DashboardLayout from 'components/Layout/DashboardLayout';

export default function TermsAndConditions(props) {
  const goBackUrl =
    props.flowOrigin === config.constants.flowOrigins.registration
      ? routeService.register_choose_company
      : routeService.dashboard_homepage;

  const selector = `lang_terms_and_conditions:terms_and_conditions`;

  return (
    <>
      <div className='terms-policy-view'>
        {props.flowOrigin === config.constants.flowOrigins.profile ? (
          <DashboardLayout
            {...props}
            hidePreviousButton={false}
            goBackUrl={goBackUrl}>
            <div className='profile-resubscription-flow-container'>
              <TermsAndPolicy selector={selector} />
            </div>
          </DashboardLayout>
        ) : (
          <div id='registration-flow'>
            <Header
              flow={props.flow}
              goBackUrl={goBackUrl}
              hidePreviousButton={false}
            />
            <PrevButton
              className='go-previous-button'
              flow={props.flow}
              goBackUrl={goBackUrl}
            />
            <div
              className='terms-policy-registration-view'
              id='registration-flow'>
              <LogoutButton flow={props.flow} />
              <TermsAndPolicy selector={selector} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
