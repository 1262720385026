/** @format */

import React from 'react';
import { Field, ErrorMessage } from 'formik';

export default function FormField(props) {
  const {
    name,
    type,
    maxLength,
    placeholder,
    className,
    labeltext,
    labelstyle,
    errorstyle
  } = props;

  return (
    <>
      {labeltext !== undefined && (
        <label
          htmlFor={name}
          className={`form-label  ${(labelstyle && labelstyle) || ''}`}>
          {labeltext}
        </label>
      )}
      <ErrorMessage
        name={name}
        component='span'
        className={`form-error ${errorstyle && errorstyle}`}
      />
      <Field
        className={`form-control ${(className && className) || ''}`}
        type={(type && type) || 'text'}
        name={name}
        maxLength={maxLength && maxLength}
        placeholder={(placeholder && placeholder) || ''}
        {...props}
      />
    </>
  );
}
