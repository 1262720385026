/** @format */

import React from 'react';
import { useTranslation } from 'react-i18next';

export default function StepsWizard(props) {
  const { t } = useTranslation();
  const activeState = {};

  const getActiveState = () => {
    props.stepsWizard.forEach((item, key) => {
      if (item === false) {
        activeState[`step${key}`] = 'inactive';
      } else {
        activeState[`step${key}`] = 'active';
      }
    });
  };
  getActiveState();

  let completedDot = {};
  if (props.stepsWizard.indexOf(true) === 1) {
    completedDot.step0 = 'completed';
  } else if (props.stepsWizard.indexOf(true) === 2) {
    completedDot.step0 = 'completed';
    completedDot.step1 = 'completed';
  }

  const translations = t(`lang_common:steps_wizard`);

  return (
    <div className='steps-wizard-container'>
      {/** Circle 1 */}
      <svg>
        <circle
          cx='104.5'
          cy='30'
          r='20'
          className={`steps-wizard-circle-0-${activeState.step0}`}
        />
        <circle
          cx='104.5'
          cy='30'
          r='2'
          className={`steps-wizard-dot-0-${activeState.step0} steps-wizard-dot-0-${completedDot.step0}`}
        />
        <text
          x='104.5'
          y='70'
          className={`steps-wizard-text steps-wizard-text-0-${activeState.step0}`}
          textAnchor='middle'>
          {translations[0].text}
        </text>

        {/** Circle 2 */}
        <circle
          cx='212'
          cy='30'
          r='20'
          className={`steps-wizard-circle-1-${activeState.step1}`}
        />
        <circle
          cx='212'
          cy='30'
          r='2'
          className={`steps-wizard-dot-1-${activeState.step1} steps-wizard-dot-0-${completedDot.step1}`}
        />
        <text
          x='212'
          y='70'
          className={`steps-wizard-text steps-wizard-text-1-${activeState.step1}`}
          textAnchor='middle'>
          {translations[1].text}
        </text>

        {/** Circle 3 */}
        <circle
          cx='314'
          cy='30'
          r='20'
          className={`steps-wizard-circle-2-${activeState.step2}`}
        />
        <circle
          cx='314'
          cy='30'
          r='2'
          className={`steps-wizard-dot-2-${activeState.step2}`}
        />
        <text
          x='314'
          y='70'
          className={`steps-wizard-text steps-wizard-text-2-${activeState.step2} steps-wizard-dot-0-${completedDot.step2}`}
          textAnchor='middle'>
          {translations[2].text}
        </text>

        {/** Circle 4 */}
        <circle
          cx='417'
          cy='30'
          r='20'
          className={`steps-wizard-circle-3-${activeState.step3}`}
        />
        <circle
          cx='417'
          cy='30'
          r='2'
          className={`steps-wizard-dot-3-${activeState.step3}`}
        />
        <text
          x='417'
          y='70'
          className={`steps-wizard-text steps-wizard-text-3-${activeState.step3} steps-wizard-dot-0-${completedDot.step3}`}
          textAnchor='middle'>
          {translations[3].text}
        </text>
      </svg>
    </div>
  );
}
