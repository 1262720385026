/** @format */
/** A custom Hook that redirects users to private routes and redirects them to a given url when not authenticated */

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, useLocation } from 'react-router-dom';
import NotificationMessage from 'components/NotificationMessage';
import SessionExpiredModal from 'components/Modals/SessionExpiredModal';

export default function PrivateRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();

  const { t } = useTranslation();

  const alertHeadingText = t(`lang_common:session_expiry.warning.heading.text`);
  const alertDescriptionText = t(
    `lang_common:session_expiry.warning.description.text`
  );

  const {
    data = {},
    sessionEndTime,
    isAuthenticated,
    sessionIsAboutToExpire,
    showSessionExpiredMessage
  } = useSelector((state) => state.session);

  const isLoggedIn = data && data.fid && isAuthenticated;
  const sessionHasExpired = new Date().getTime() > sessionEndTime;

  return (
    <>
      <Route {...rest}>
        {isLoggedIn ? (
          <>
            {sessionIsAboutToExpire && (
              <NotificationMessage
                className='session-expiry-alert'
                heading={alertHeadingText}
                description={alertDescriptionText}
              />
            )}
            {(showSessionExpiredMessage || sessionHasExpired) && (
              <SessionExpiredModal />
            )}
            {children}
          </>
        ) : (
          <Redirect to={`/?redirect=${pathname}${search}`} />
        )}
      </Route>
    </>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};
