/** @format */

import { store } from 'stateContainer/store';
import { push } from 'connected-react-router';
import { fetchSigneeCombinationService } from 'services';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import {
  getIsSingleSignee,
  geAnomalyData,
  getSigneeNamesReversed,
  getIsCurrentUserOneOfSignee,
  getIsSingleSigneeAndCurrentUser,
  getCurrentUserIsSoleSigneeOfACombination
} from 'utils/getSigneeCombinationStates';

function* workerSaga({ organisationId, failedUrl }) {
  try {
    /**
     * fetch company-data from roaring
     */

    const response = yield call(fetchSigneeCombinationService, {
      organisationId
    });

    if (response.data.status.code === 1) {
      /**
       * update approuting state
       */

      yield put({
        type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
        payload: {
          isFetchSigneeCombinationDataComplete: false,
          isManualRegistrationRequired: true
        }
      });

      throw new Error(response.data.status);
    }

    /**
     * update company data state with info fetched from roaring
     */

    const data =
      (response.data &&
        response.data.records && {
          ...response.data.records[0],
          status: response.data.status
        }) ||
      {};

    const {
      person: {
        data: { personalNumber }
      }
    } = store.getState();

    const isCurrentUserOneOfSignee = getIsCurrentUserOneOfSignee({
      signeeCombination: data,
      personalNumber
    });

    const isSingleSignee = getIsSingleSignee({ signeeCombination: data });

    const isSingleSigneeAndCurrentUser = getIsSingleSigneeAndCurrentUser({
      signeeCombination: data,
      personalNumber
    });

    const currentUserIsSoleSigneeOfACombination =
      getCurrentUserIsSoleSigneeOfACombination({
        signeeCombinations: data.combinations,
        personalNumber
      });

    const anomalyData = geAnomalyData({
      signeeCombinations: data.combinations
    });

    const { isAnomalyDetected, organisationIdAnomaly } = anomalyData || {};

    if (isAnomalyDetected) {
      yield put({
        type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
        payload: {
          isAnomalyDetected: isAnomalyDetected
        }
      });

      //fetch signee combination again using anomaly company this time

      yield put({
        type: actionTypes.FETCH_SIGNEE_COMBINATION_REQUESTED,
        organisationId: organisationIdAnomaly
      });
    }

    /** reverse and fix the order of names of signees in each combination */

    data.combinations &&
      data.combinations.length > 0 &&
      getSigneeNamesReversed(data.combinations);

    /**
     * update approuting state
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: {
        isFetchSigneeCombinationDataComplete: true,
        isAnomalyDetected: isAnomalyDetected
      }
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchCompanyData: 'done' }
    });

    yield put({
      type: actionTypes.FETCH_SIGNEE_COMBINATION_SUCCESS,
      data: {
        ...data
      },
      status: {
        isAnomalyDetected: isAnomalyDetected,
        isSingleSignee,
        isCurrentUserOneOfSignee,
        isSingleSigneeAndCurrentUser,
        currentUserIsSoleSigneeOfACombination
      }
    });
  } catch (error) {
    /**
     * throw error when the roaring call fails
     */

    yield put({
      type: actionTypes.FETCH_SIGNEE_COMBINATION_FAILED,
      error: true
    });

    yield put(push(failedUrl));
  }
}

export function* fetchSigneeCombinationSaga() {
  yield takeLatest(actionTypes.FETCH_SIGNEE_COMBINATION_REQUESTED, workerSaga);
}
