/** @format */

export const companyInfoUtils = ({
  t,
  props,
  personState,
  companyState,
  currentUserState,
  signeeCombinationState,
  boardMembershipState
}) => {
  /**
   * prepare data base data object
   */
  const data = {
    surName: '',
    firstName: '',
    companyName: '',
    listOfSignees: [],
    initialValues: {},
    companyNameText: '',
    isRequesting: false,
    isContactEmailValidated: false
  };

  /**
   * signee list from signee combination
   */

  data.listOfSignees =
    !signeeCombinationState.error &&
    signeeCombinationState.data &&
    signeeCombinationState.data.combinations &&
    signeeCombinationState.data.combinations;

  data.isContactEmailValidated =
    (currentUserState.existingRecords &&
      currentUserState.existingRecords[0] &&
      currentUserState.existingRecords[0].company &&
      currentUserState.existingRecords[0].company.isContactEmailValidated) ||
    false;

  /**
   * extract firstName and surName of user
   */

  let { surName = '', firstName = '' } =
    (personState.details.length > 0 && personState.details[0]) || {};

  if (Object.keys(personState).length === 0) {
    firstName = currentUserState.data.firstName;
    surName = currentUserState.data.lastName;
  }

  data.surName = surName;
  data.firstName = firstName;

  /**
   * include company name with heading text
   */

  const companyName = companyState.data.companyName || '';
  data.companyNameText = t(`lang_${props.flow}:${props.view}.heading.text`, {
    companyName: companyName
  });

  /**
   * set initial values for the Formik form
   */

  const existingValues =
    (currentUserState.existingRecords &&
      currentUserState.existingRecords.adminUser &&
      currentUserState.existingRecords.adminUser[0] &&
      currentUserState.existingRecords.adminUser[0]) ||
    {};

  /**
   *
   * - If we don't have a visiting address, we should not set a City or a postalCode
   * - The Fields under Postadress (postal address) are Mandatory. The visiting address is not mandatory.
   * - Besöksadress = Visiting address
   * - Postadress = Postal address   Mandatory
   */

  const isVisitAddressPresent =
    companyState.data.visitAddress && companyState.data.visitAddress.length > 0;

  data.initialValues = {
    visitingAddress1: companyState.data.visitAddress || '',
    visitingAddress2: '',
    visitingCity: isVisitAddressPresent ? companyState.data.visitTown : '',
    visitingPostCode: isVisitAddressPresent
      ? companyState.data.visitZipCode
      : '',
    mailingAddress1: companyState.data.address || '',
    mailingAddress2: '',
    mailingCity: companyState.data.town || '',
    mailingPostCode: companyState.data.zipCode || '',
    contactPersonFirstName: data.firstName || '',
    contactPersonLastName: data.surName || '',
    contactPersonEmail:
      currentUserState.data.email ||
      (existingValues && existingValues.email) ||
      '',
    contactPersonPhone:
      currentUserState.data.phone ||
      (existingValues && existingValues.phone) ||
      ''
  };

  /**
   * check for states that are still requesting
   */

  data.isRequesting =
    currentUserState.requesting ||
    companyState.requesting ||
    personState.requesting ||
    signeeCombinationState.requesting ||
    boardMembershipState.requesting;

  return data;
};
