/** @format */

import React from 'react';
import AddEmployee from 'views/Dashboard/HomePage/AddEmployee';
import SortableTable from 'views/Dashboard/HomePage/SortableTable';

export default function EmployeeListContent(props) {
  const {
    tprop,
    addEmployee,
    editEmployee,
    showEmployee,
    listOfEmployees,
    chosenEmployeeData,
    setShowAddEmployee
  } = props;

  const headingText =
    Object.keys(chosenEmployeeData).length === 0
      ? tprop.tabs[1].add_employee_prompt.add_new.heading.text
      : tprop.tabs[1].add_employee_prompt.edit_employee.heading.text;

  return (
    <div className='profile-heading'>
      {showEmployee && (
        <>
          <div className='homepage-tabitem-header-container'>
            <div className='homepage-tabitem-header-left'>
              <h1>{headingText}</h1>
            </div>
            <div className='homepage-tabitem-header-right'></div>
          </div>
          <AddEmployee
            {...props}
            tabkey={1}
            chosenEmployeeData={chosenEmployeeData}
            listOfEmployees={listOfEmployees}
            setShowAddEmployee={setShowAddEmployee}></AddEmployee>
        </>
      )}
      {!showEmployee && (
        <>
          <div className='homepage-tabitem-header-container'>
            <div className='homepage-tabitem-header-left'>
              <h1>{tprop.tabs[1].heading.text}</h1>
            </div>
            <div className='homepage-tabitem-header-right'>
              <div>{tprop.tabs[1].buttons.edit.text}</div>
              <button
                onClick={() => addEmployee()}
                className='add-new-button icon-plus '
              />
            </div>
          </div>
          <SortableTable
            data={listOfEmployees}
            columns={tprop.tabs[1].table.columns}
            onClick={editEmployee}
          />
        </>
      )}
    </div>
  );
}
