/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

export default function MembershipLogos(props) {
  const { membership } = props;
  return (
    <div className='footer-membership-logos'>
      <Link
        to={{ pathname: membership.links.swedsec.url }}
        target='_blank'
        rel='noopener noreferrer'>
        <div className='logo-swedsec-white' />
      </Link>
      <Link
        to={{ pathname: membership.links.insuresec.url }}
        target='_blank'
        rel='noopener noreferrer'>
        <div className='logo-insuresec-white' />
      </Link>
      <Link
        to={{ pathname: membership.links.sfm.url }}
        target='_blank'
        rel='noopener noreferrer'>
        <div className='logo-sfm-white' />
      </Link>
    </div>
  );
}
