/** @format */

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import routeService from 'services/routeService';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DashboardLayout from 'components/Layout/DashboardLayout';
import NotificationMessage from 'components/NotificationMessage';
import { companyChoiceUpdatedAction } from 'stateContainer/actions';
import DashboardContent from 'views/Dashboard/HomePage/DashboardContent';
import EmployeeListContent from 'views/Dashboard/HomePage/EmployeeListContent';
import ApproveCompanyRegistrationModal from 'components/Modals/ApproveCompanyRegistrationModal';

export default function HomePage(props) {
  const { tprop } = props;
  const dispatch = useDispatch();

  const appRoutingState = useSelector((state) => state.appRouting);
  const personState = useSelector((state) => state.person.data) || {};
  const companyState = useSelector((state) => state.companyUser) || {};
  const currentUserState = useSelector((state) => state.currentUser) || {};
  const boardMembershipState =
    useSelector((state) => state.boardMembership.data) || {};
  const signeeCombinationState =
    useSelector((state) => state.signeeCombination) || {};

  const [tabIndex, setTabIndex] = useState('dashboard');
  const [showEmployee, setShowAddEmployee] = useState(false);
  const [chosenEmployeeData, setChosenEmployeeData] = useState({});
  const {
    existingRecords: {
      listOfEmployees = [],
      listOfCompaniesWithCurrentUserAsSignee = []
    }
  } = currentUserState;

  const pendingListOfApprovals = listOfCompaniesWithCurrentUserAsSignee.filter(
    (item) => {
      return !item.hasApproved && Object.keys(item.company).length > 0;
    }
  );

  const hasMultipleApprovalsPending = pendingListOfApprovals.length > 1;

  const hasSingleApprovalPending = pendingListOfApprovals.length === 1;

  const editEmployee = (data) => {
    setChosenEmployeeData({ ...data });
    setShowAddEmployee(true);
  };

  const addEmployee = () => {
    setChosenEmployeeData({});
    setShowAddEmployee(true);
  };

  const changeTab = (tabIndex) => {
    setTabIndex(tabIndex);
    setShowAddEmployee(false);
  };

  const onSubmit = (values) => {
    const data = {
      ssn: personState.personalNumber,
      successUrl: routeService.dashboard_review_company_info,
      failedUrl: routeService.dashboard_homepage,
      organisationId: values.organisationId
    };
    return dispatch(companyChoiceUpdatedAction(data));
  };

  const isRequesting =
    personState.requesting ||
    companyState.requesting ||
    currentUserState.requesting ||
    signeeCombinationState.requesting ||
    boardMembershipState.requesting;

  return (
    <>
      {!appRoutingState.hasEverySigneeApproved && (
        <Redirect to={routeService.register_choose_company} />
      )}
      <div>
        <div className='profile profile-view '>
          <DashboardLayout
            {...props}
            hidePreviousButton={true}
            goBackUrl={routeService.onboarding_welcome_to_onboarding}>
            <>
              {/**
               *  used when user has only one approval pending
               *  needs an external submit function
               * */}
              {hasSingleApprovalPending && (
                <NotificationMessage
                  className='pending-approval-alert'
                  heading={tprop.notifications.approvalPending.heading.text}
                  description={
                    tprop.notifications.approvalPending.description.text
                  }>
                  <Link to={routeService.dashboard_review_company_info}>
                    <Button
                      className={`pending-approval-alert-btn-small ${props.btnColor}`}
                      onClick={() =>
                        onSubmit({
                          organisationId:
                            pendingListOfApprovals[0].organisationId
                        })
                      }>
                      {tprop.notifications.approvalPending.buttons.text}
                    </Button>
                  </Link>
                </NotificationMessage>
              )}

              {/**
               *  used when use multiple approvals pending
               *  handles its own submit
               * */}
              {hasMultipleApprovalsPending && (
                <ApproveCompanyRegistrationModal
                  {...props}
                  isRequesting={isRequesting}
                  ssn={personState.personalNumber}
                  successUrl={routeService.dashboard_review_company_info}
                  pendingListOfApprovals={pendingListOfApprovals}
                />
              )}
              <Tabs
                defaultActiveKey='profile'
                id='tab'
                activeKey={tabIndex}
                onSelect={(index) => changeTab(index)}
                className='tab-container mb-3'>
                <Tab
                  eventKey='dashboard'
                  tabkey={0}
                  title={tprop.tabs[0].label}
                  className='tab-item tab-item-dashboard'>
                  <DashboardContent
                    {...props}
                    changeTab={changeTab}
                    listOfEmployees={listOfEmployees}
                  />
                </Tab>
                <Tab
                  eventKey='employees'
                  tabkey={1}
                  title={tprop.tabs[1].label}
                  className='tab-item tab-item-employees'>
                  <EmployeeListContent
                    {...props}
                    addEmployee={addEmployee}
                    editEmployee={editEmployee}
                    showEmployee={showEmployee}
                    listOfEmployees={listOfEmployees}
                    setShowAddEmployee={setShowAddEmployee}
                    chosenEmployeeData={chosenEmployeeData}
                    setChosenEmployeeData={setChosenEmployeeData}
                  />
                </Tab>
                <Tab
                  eventKey='billing'
                  tabkey={2}
                  title={tprop.tabs[2].label}
                  className='tab-item tab-item-billing'>
                  @placeholder for tab 3
                </Tab>
              </Tabs>
            </>
          </DashboardLayout>
        </div>
      </div>
    </>
  );
}
