/** @format */

import React from 'react';
import config from 'config';
import SplitLayout from 'components/Layout/SplitLayout';
import ValidationStatus from 'components/ValidatingEmailStatus/ValidationStatus';

export default function ValidatingEmailStatus(props) {
  return (
    <>
      {props.flow !== config.constants.flows.registration ? (
        <ValidationStatus {...props} />
      ) : (
        <SplitLayout
          flow={props.flow}
          className=' postlogin-template validate-email-status-container'
          backgroundImage='registration-welcome-left-background-image'>
          <ValidationStatus {...props} />
        </SplitLayout>
      )}
    </>
  );
}
