/** @format */

import React, { Component } from 'react';
import { createPortal } from 'react-dom';

const SANDBOX_ATTRIBUTES =
  'allow-same-origin allow-top-navigation allow-forms allow-scripts';

export default class SocialSecurityNumber extends Component {
  constructor(props) {
    super(props);

    this.setContentRef = (node) => {
      if (!node) return;
      this.contentRef =
        ((!node || !node.contentWindow) && null) || node.contentDocument;
    };
  }

  render() {
    const { children, className } = this.props; // eslint-disable-line

    return (
      // eslint-disable-next-line
      <iframe
        {...this.props}
        importance='high' // Experimental feature not recommended for use in production my MDN as of 28/05/2020
        ref={this.setContentRef}
        className={`iframe-ssn ${className}`}
        allowtransparency='true'
        sandbox={SANDBOX_ATTRIBUTES}>
        {this.contentRef &&
          createPortal(React.Children.only(<>{children}</>), this.contentRef)}
      </iframe>
    );
  }
}
