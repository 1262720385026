/** @format */

import React from 'react';
import RegularHeaderLeft from 'components/Layout/Header/RegularHeader/RegularHeaderLeft';
import RegularHeaderRight from 'components/Layout/Header/RegularHeader/RegularHeaderRight';
import RegularHeaderCenter from 'components/Layout/Header/RegularHeader/RegularHeaderCenter';

export default function RegularHeader(props) {
  const fullName = `${props.firstName} ${props.lastName}`;

  return (
    <>
      <div className='header'>
        <div className='header-inner'>
          <RegularHeaderLeft homePageUrl={props.homePageUrl} {...props} />

          <RegularHeaderCenter {...props} />

          <RegularHeaderRight
            flow={props.flow}
            location={''}
            fullName={fullName}
            profileUrl={props.profileUrl}
            {...props}
          />
        </div>
      </div>
    </>
  );
}
