/** @format */

import { mutations } from 'services';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  const { newEmail, firstName } = payload;
  try {
    yield call(mutations.updateEmailMutation, payload);

    /**
     * create and update user state with create user status
     */

    yield put({ type: actionTypes.UPDATE_EMAIL_SUCCESS });

    /**
     * update user data in the redux store
     */

    yield put({
      type: actionTypes.UPDATE_USER_DATA,
      payload: { email: newEmail, isEmailVerified: false }
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { updateEmail: 'done' }
    });

    /**
     * resend email verification code
     */

    yield put({
      type: actionTypes.SEND_VERIFICATION_CODE_REQUESTED,
      payload: { email: newEmail, firstName }
    });
  } catch (error) {
    yield put({ type: actionTypes.UPDATE_EMAIL_FAILED, error });
  }
}

export function* updateEmailSaga() {
  yield takeLatest(actionTypes.UPDATE_EMAIL_REQUESTED, workerSaga);
}
