/** @format */

import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import PrevButton from 'components/PrevButton';
import PuffLoader from 'react-spinners/PuffLoader';

export default function DashboardLayout(props) {
  const { children, goBackUrl, disabled, hidePreviousButton, isRequesting } =
    props;
  return (
    <>
      <Header
        flow={props.flow}
        goBackUrl={goBackUrl}
        disabled={disabled}
        hidePreviousButton={hidePreviousButton}
      />

      <div className='dashboard-view'>
        <div className='dashboard-container'>
          {/* Regular previous button is independent of the header */}
          {!hidePreviousButton && (
            <PrevButton
              className='go-previous-button-dashboard'
              flow={props.flow}
              goBackUrl={goBackUrl}
            />
          )}
          {isRequesting ? (
            <div className='dashboard-content-wrapper'>
              <div className='loader-box'>
                <PuffLoader size={96} color={'var(--color-black)'} loading />
              </div>
            </div>
          ) : (
            <div className='dashboard-content-wrapper'>{children}</div>
          )}
        </div>
        <Footer {...props} />
      </div>
    </>
  );
}
