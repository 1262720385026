/** @format */

import { mutations } from 'services';
import { push } from 'connected-react-router';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  const {
    organisationId,
    companyName,
    requestersName,
    signeeCombination,
    successUrl,
    failedUrl
  } = payload;
  try {
    /**
     * create signees for every member of combinations
     */
    for (let i = 0; i < signeeCombination.length; i++) {
      yield call(mutations.createSigneeOnCompanyMutation, {
        organisationId: organisationId,
        ssn: signeeCombination[i].personalNumber,
        name: signeeCombination[i].name,
        email: signeeCombination[i].email
      });

      yield put({
        type: actionTypes.REQUEST_SIGNEE_APPROVAL_REQUESTED,
        payload: {
          name: signeeCombination[i].name,
          signeeEmail: signeeCombination[i].email,
          signeeName: signeeCombination[i].name,
          ssn: signeeCombination[i].personalNumber,
          url: window.location.origin,
          companyName,
          requestersName,
          organisationId
        }
      });
    }

    /**
     * take user to the next page
     */

    yield put({
      type: actionTypes.CREATE_SIGNEE_COMBINATION_ON_COMPANY_SUCCESS
    });
    yield put(push(successUrl));

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { createSigneeCombinationsAndSendEmails: 'done' }
    });
  } catch (error) {
    yield put({
      type: actionTypes.CREATE_SIGNEE_COMBINATION_ON_COMPANY_FAILED,
      error
    });
    yield put(push(failedUrl));
  }
}

export function* createSigneeCombinationOnCompanySaga() {
  yield takeLatest(
    actionTypes.CREATE_SIGNEE_COMBINATION_ON_COMPANY_REQUESTED,
    workerSaga
  );
}
