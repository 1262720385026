/** @format */

import { fetchBaseDataQuery } from 'services/graphqlService/queries/fetch-base-data-query';
import { isUserEmailVerifiedQuery } from 'services/graphqlService/queries/is-user-email-verified-query';
import { fetchCurrentRegistrationStateOfChosenCompanyQuery } from 'services/graphqlService/queries/fetch-current-registration-state-query';

export const queries = {
  fetchBaseDataQuery,
  isUserEmailVerifiedQuery,
  fetchCurrentRegistrationStateOfChosenCompanyQuery
};
