/** @format */

import { push } from 'connected-react-router';
import { put, take, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import { store } from 'stateContainer/store';

function* workerSaga({ payload }) {
  try {
    /**
     * clear existing choices
     */

    const { appRouting } = store.getState();

    delete appRouting.isCompanyExists;
    delete appRouting.isContactEmailValidated;
    delete appRouting.isManualRegistrationRequired;
    delete appRouting.isPendingContactEmailValidation;

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_REFRESHED,
      payload: appRouting
    });

    /**
     * fetch existing data for chosen company
     */

    yield put({
      type: actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_REQUESTED,
      payload
    });

    /**
     * wait for existing data fetch to complete before executing other actions
     */

    yield take([
      actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SUCCESS,
      actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_FAILED
    ]);

    /**
     * fetch sign-rights and company-data from roaring
     */

    yield put({
      type: actionTypes.COMPANY_DATA_REQUESTED,
      ...payload
    });

    /**
     * wait for existing data fetch to complete before executing other actions
     */

    yield take([
      actionTypes.COMPANY_DATA_SUCCESS,
      actionTypes.COMPANY_DATA_FAILED
    ]);

    yield put({
      type: actionTypes.FETCH_SIGNEE_COMBINATION_REQUESTED,
      ...payload
    });

    /**
     * wait for existing data fetch to complete before executing other actions
     */

    yield take([
      actionTypes.FETCH_SIGNEE_COMBINATION_SUCCESS,
      actionTypes.FETCH_SIGNEE_COMBINATION_FAILED
    ]);

    /*
     * update user-chosen-company for registration
     */

    yield put({
      type: actionTypes.COMPANY_CHOICE_SUCCESS,
      payload
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: {}
    });

    yield put(push(payload.successUrl));
  } catch (error) {
    /**
     * throw error when the saga fails
     */

    yield put({ type: actionTypes.COMPANY_CHOICE_FAILED, error });
  }
}

export function* chooseCompanySaga() {
  yield takeLatest(actionTypes.COMPANY_CHOICE_REQUESTED, workerSaga);
}
