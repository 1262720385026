/** @format */

import React from 'react';
import config from 'config';
import { routeService } from 'services';
import { Switch, Route } from 'react-router-dom';
import RoutedComponent from 'components/RoutedComponent';

/** Containers sorted according to the flow order */

/**
 * Common views
 */
import PageNotFound from 'views/Static/PageNotFound';
import Start from 'views/Common/Start';
import LoginMode from 'views/Common/LoginMode';
import Login from 'views/Common/Login';
import BankIdRedirect from 'components/BankIdRedirect';
import LoggingIn from 'views/Common/LoggingIn';
import LoggingOut from 'views/Common/LoggingOut';

/**
 * Registration views
 */
import ChooseCompany from 'views/Registration/ChooseCompany';
import CompanyInfo from 'views/Registration/CompanyInfo';
import ValidateEmail from 'views/Registration/ValidateEmail';
import ValidateEmailSuccess from 'views/Registration/ValidateEmailSuccess';
import RequestSigneeApproval from 'views/Registration/RequestSigneeApproval';
import ChooseSigneeCombination from 'views/Registration/ChooseSigneeCombination';
import SigneeApprovalRequestSent from 'views/Registration/SigneeApprovalRequestSent';
import ReviewCompanyInfo from 'views/Common/ReviewCompanyInfo';
import AgreeToTerms from 'views/Common/AgreeToTerms';
import ModeOfPayment from 'views/Common/ModeOfPayment';
import Summary from 'views/Common/Summary';
import TermsAndConditions from 'views/Common/TermsAndConditions';
import PrivacyPolicy from 'views/Common/PrivacyPolicy';
import RegistrationComplete from 'views/Registration/RegistrationComplete';

/**
 * common imports
 */
import Alert from 'react-bootstrap/Alert';
import { isMobile } from 'react-device-detect';

/**
 * Dashboard
 */
import HomePage from 'views/Dashboard/HomePage';

/**
 * Redirect and route user according to how far registration has been completed
 */
import RedirectUser from 'components/RedirectUser';

const {
  constants: { flows, views, colors, flowOrigins }
} = config;

export default function Routes() {
  const [show, setShow] = React.useState(false);

  return (
    <>
      {/** Temporary alert for users on mobile devices */}
      {show && isMobile && (
        <Alert
          variant='danger'
          className='small-screen-alert'
          onClose={() => setShow(false)}
          dismissible>
          <p>
            Webb-versionen av tjänsten är inte anpassad för mindre skärmar och
            vi rekommenderar att du registrerar på en enhet med större skärm, t
            ex dator.
          </p>
        </Alert>
      )}
      <Switch>
        <RoutedComponent /*** start-page ***/
          exact={true}
          isPrivateRoute={false}
          Component={Start}
          flow={flows.registration}
          view={views.start}
          path={routeService.register_start}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** login-mode : same-device or another-device ***/
          exact={true}
          isPrivateRoute={false}
          Component={LoginMode}
          flow={flows.registration}
          view={views.login_mode}
          path={routeService.register_login_mode}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** login-page: enter ssn and submit ***/
          exact={true}
          isPrivateRoute={false}
          Component={Login}
          flow={flows.registration}
          view={views.login}
          path={routeService.register_login}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** redirect page to redirect from criipto iframe back to thethirdact ***/
          exact={true}
          isPrivateRoute={false}
          Component={BankIdRedirect}
          flow={flows.registration}
          view={views.bankid_redirect}
          path={routeService.register_bankid_redirect}
        />
        <RoutedComponent /*** logging-in page: this is where the criipto tokens will be sent to aws for authentication ***/
          exact={true}
          isPrivateRoute={false}
          Component={LoggingIn}
          flow={flows.common}
          view={views.logging_in}
          path={routeService.common_logging_in}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** logout-loading page: this is where the criipto tokens will be sent to aws for authentication ***/
          exact={true}
          isPrivateRoute={false}
          Component={LoggingOut}
          flow={flows.common}
          view={views.logging_out}
          path={routeService.common_logging_out}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** choose_company-page: welcome page where user chooses organisation to register ***/
          exact={true}
          isPrivateRoute={true}
          Component={ChooseCompany}
          flow={flows.registration}
          view={views.choose_company}
          path={routeService.register_choose_company}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false]}
        />
        <RoutedComponent /*** company-info page: collect or update company  details obtained from roaring.io ***/
          exact={true}
          isPrivateRoute={true}
          Component={CompanyInfo}
          flow={flows.registration}
          view={views.company_info}
          path={routeService.register_company_info}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false]}
        />
        <RoutedComponent /*** validate-email: email validation step using aws cognito ***/
          exact={true}
          isPrivateRoute={true}
          Component={ValidateEmail}
          flow={flows.registration}
          view={views.validate_email}
          path={routeService.register_validate_email}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false]}
        />
        <RoutedComponent /*** loading page: this view will show when the email validation is processing in the background ***/
          exact={true}
          isPrivateRoute={true}
          Component={ValidateEmailSuccess}
          flow={flows.registration}
          view={views.validating_email}
          path={routeService.register_validating_email}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** temporary page that checks where a user should be redirected to and sends them there ***/
          exact={true}
          isPrivateRoute={true}
          Component={RedirectUser}
          flow={flows.registration}
          view={views.redirect_user}
          path={routeService.register_redirect_user}
          btnColor={colors.pink}
          stepsWizard={[false, false, true, false]}
        />

        <RoutedComponent /*** choose signee combination view ***/
          exact={true}
          isPrivateRoute={true}
          Component={ChooseSigneeCombination}
          flow={flows.registration}
          view={views.choose_signee_combination}
          path={routeService.register_choose_signee_combination}
          btnColor={colors.pink}
          stepsWizard={[false, false, true, false]}
        />
        <RoutedComponent /*** request for approval since user has no signright to complete registration- view ***/
          exact={true}
          isPrivateRoute={true}
          Component={RequestSigneeApproval}
          flow={flows.registration}
          view={views.request_signee_approval}
          path={routeService.register_request_signee_approval}
          btnColor={colors.pink}
          stepsWizard={[false, false, true, false]}
        />
        <RoutedComponent /*** request for approval since user has no sign right  view ***/
          exact={true}
          isPrivateRoute={true}
          Component={SigneeApprovalRequestSent}
          flow={flows.registration}
          view={views.signee_approval_request_sent}
          path={routeService.register_signee_approval_request_sent}
          btnColor={colors.pink}
          stepsWizard={[false, false, true, false]}
        />
        <RoutedComponent /*** review company info view for signees before approving the company registration  ***/
          exact={true}
          isPrivateRoute={true}
          Component={ReviewCompanyInfo}
          flow={flows.registration}
          flowOrigin={flowOrigins.registration}
          view={views.review_company_info}
          path={routeService.register_review_company_info}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false]}
        />
        <RoutedComponent /*** agree-to-terms-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={AgreeToTerms}
          flow={flows.registration}
          flowOrigin={flowOrigins.registration}
          view={views.agree_to_terms}
          path={routeService.register_agree_to_terms}
          btnColor={colors.pink}
          stepsWizard={[false, false, true, false]}
        />
        <RoutedComponent /*** mode-of-payment-page***/
          exact={true}
          isPrivateRoute={true}
          Component={ModeOfPayment}
          flow={flows.registration}
          flowOrigin={flowOrigins.registration}
          view={views.mode_of_payment}
          path={routeService.register_mode_of_payment}
          btnColor={colors.pink}
          stepsWizard={[false, false, false, true]}
        />
        <RoutedComponent /*** registration-summary-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={Summary}
          flow={flows.registration}
          flowOrigin={flowOrigins.registration}
          view={views.summary}
          path={routeService.register_summary}
          btnColor={colors.pink}
          stepsWizard={[false, false, false, true]}
        />
        <RoutedComponent /*** terms-and-conditions-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={TermsAndConditions}
          flow={flows.registration}
          flowOrigin={flowOrigins.registration}
          view={views.terms_and_conditions}
          path={routeService.register_terms_and_conditions}
          btnColor={colors.blue}
        />
        <RoutedComponent /*** privacy-policy-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={PrivacyPolicy}
          flowOrigin={flowOrigins.registration}
          flow={flows.registration}
          view={views.privacy_policy}
          path={routeService.register_privacy_policy}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** thank-you-for-registering-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={RegistrationComplete}
          flowOrigin={flowOrigins.registration}
          flow={flows.registration}
          view={views.registration_complete}
          path={routeService.register_registration_complete}
        />
        <RoutedComponent /*** dashboard-home-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={HomePage}
          flow={flows.dashboard}
          view={views.homepage}
          path={routeService.dashboard_homepage}
          btnColor={colors.pink}
        />

        {/** dashboard version of approve registration */}

        <RoutedComponent /*** review company info view for signees in dashboard  ***/
          exact={true}
          isPrivateRoute={true}
          Component={ReviewCompanyInfo}
          flow={flows.dashboard}
          flowOrigin={flowOrigins.dashboard}
          view={views.review_company_info}
          path={routeService.dashboard_review_company_info}
          btnColor={colors.pink}
          stepsWizard={[true, false, false, false]}
        />

        <RoutedComponent /*** agree-to-terms-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={AgreeToTerms}
          flow={flows.dashboard}
          flowOrigin={flowOrigins.dashboard}
          view={views.agree_to_terms}
          path={routeService.dashboard_agree_to_terms}
          btnColor={colors.pink}
          stepsWizard={[false, false, true, false]}
        />
        <RoutedComponent /*** mode-of-payment-page***/
          exact={true}
          isPrivateRoute={true}
          Component={ModeOfPayment}
          flow={flows.dashboard}
          flowOrigin={flowOrigins.dashboard}
          view={views.mode_of_payment}
          path={routeService.dashboard_mode_of_payment}
          btnColor={colors.pink}
          stepsWizard={[false, false, false, true]}
        />
        <RoutedComponent /*** registration-summary-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={Summary}
          flow={flows.dashboard}
          flowOrigin={flowOrigins.dashboard}
          view={views.summary}
          path={routeService.dashboard_summary}
          btnColor={colors.pink}
          stepsWizard={[false, false, false, true]}
        />
        <RoutedComponent /*** terms-and-conditions-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={TermsAndConditions}
          flow={flows.common}
          flowOrigin={flowOrigins.dashboard}
          view={views.terms_and_conditions}
          path={routeService.dashboard_terms_and_conditions}
          btnColor={colors.blue}
        />
        <RoutedComponent /*** privacy-policy-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={PrivacyPolicy}
          flow={flows.common}
          flowOrigin={flowOrigins.dashboard}
          view={views.privacy_policy}
          path={routeService.dashboard_privacy_policy}
          btnColor={colors.pink}
        />
        <RoutedComponent /*** thank-you-for-registering-page ***/
          exact={true}
          isPrivateRoute={true}
          Component={RegistrationComplete}
          flow={flows.dashboard}
          flowOrigin={flowOrigins.dashboard}
          view={views.registration_complete}
          path={routeService.dashboard_registration_complete}
        />

        <Route>
          <PageNotFound flow={flows.common} view={views.page_not_found} />
        </Route>
      </Switch>
    </>
  );
}
