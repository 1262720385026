/** @format */

import { API } from 'aws-amplify';
import { store } from 'stateContainer/store';
import { confirmEndOfSessionAction } from 'stateContainer/actions';

export async function invokeRestApi({ service, payload }) {
  const {
    session: { requesting, sessionEndTime }
  } = store.getState();

  const currentTime = new Date().getTime();

  if (!requesting && currentTime > sessionEndTime) {
    /**
     * Logout user if session credentials are missing
     */
    return store.dispatch(confirmEndOfSessionAction());
  } else {
    return await API.post('apiBasePath', service, {
      body: payload
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  }
}
