/** @format */

import { routeService } from 'services';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ValidatingEmailStatus from 'components/ValidatingEmailStatus';

export default withRouter(function ValidateEmailSuccess(props) {
  useEffect(() => {
    const that = props;
    const destinationUrl = routeService.register_redirect_user;
    setTimeout(() => {
      that.history.push(destinationUrl);
    }, 2000); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ValidatingEmailStatus isEmailVerified={true} flow={props.flow} />;
});
