/** @format */

import React from 'react';
import getCriiptoLogoutUri from 'utils/getCriiptoLogoutUri';
import SocialSecurityNumber from 'components/SocialSecurityNumber';
import CenteredMessageLayout from 'components/Layout/CenteredMessageLayout';

export default function LoggingOut(props) {
  const { tprop } = props;
  const logout_url = getCriiptoLogoutUri();

  return (
    <CenteredMessageLayout
      description={tprop.description.text}
      className='centered-content-theme-transparent'>
      <SocialSecurityNumber src={logout_url} className='d-none' />
    </CenteredMessageLayout>
  );
}
