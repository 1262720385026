/** @format */

import * as actionTypes from 'stateContainer/action-types';

const initialState = {
  requesting: false,
  data: {}
};

export const boardMembershipReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_BOARD_MEMBERSHIP_REQUESTED:
      return { ...state, requesting: true };

    case actionTypes.FETCH_BOARD_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data,
        isCurrentUserHasBoardMemberships:
          action.isCurrentUserHasBoardMemberships
      };

    case actionTypes.FETCH_BOARD_MEMBERSHIP_SKIPPED:
      return { ...state, requesting: false };

    case actionTypes.FETCH_BOARD_MEMBERSHIP_FAILED:
      return {
        ...state,
        requesting: false
      };

    default:
      return state;
  }
};
