/** @format */

import config from 'config';
import React, { useState } from 'react';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import SummaryBox from 'components/SummaryBox';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ToastMessage from 'components/ToastMessage';
import ButtonLoader from 'components/ButtonLoader';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { approveCompanyRegistrationAction } from 'stateContainer/actions';
import { getSummaryObject } from 'views/Common/Summary/getSummaryObject';

export default function Summary(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { requesting, isTermsAgreed, isPolicyAgreed } = useSelector(
    (state) => state.currentUser
  );

  const appRoutingState = useSelector((state) => state.appRouting) || {};
  const companyState = useSelector((state) => state.company) || {};
  const sessionState = useSelector((state) => state.session.data) || {};
  const currentUserState = useSelector((state) => state.currentUser) || {};
  const signeeCombinationState =
    useSelector((state) => state.signeeCombination) || {};

  const { search } = useLocation();
  const ssnMismatchFound = search.includes('ssnMismatch=true');
  const ssnErrorHeading = t(`lang_error:ssnMismatch.heading.text`);
  const ssnErrorDescription = t(`lang_error:ssnMismatch.description.text`);

  const summary = getSummaryObject({
    sessionState,
    companyState,
    currentUserState
  });

  /**
   * eliminate currentuser from signee list if they are on the signee flow and not isSingleSigneeAndCurrentUser
   *
   * @param {String} ssn
   * @returns array
   */

  const isSingleSigneeAndCurrentUser =
    signeeCombinationState.isSingleSigneeAndCurrentUser ||
    appRoutingState.isSingleSigneeAndCurrentUser;

  /**
   * check if every other signee has approved the registration
   * @param {String} ssn
   * @returns boolean
   */

  const remainingSigneeInListIsCurrentUser = () => {
    let isRemainingSignee = false;
    const remainingSigneeList = [];
    summary.listOfSignees.filter((signee) => {
      if (signee.hasApproved === false) {
        remainingSigneeList.push(signee);
      }
    });

    if (
      remainingSigneeList.length === 1 &&
      remainingSigneeList[0].hasApproved === false &&
      remainingSigneeList[0].ssn === sessionState.ssn
    ) {
      isRemainingSignee = true;
    }

    return isRemainingSignee;
  };

  const isFullyApprovedAndSigned = () => {
    const listOfSignees = summary.listOfSignees;
    if (listOfSignees.length === 1) {
      return true;
    } else if (remainingSigneeInListIsCurrentUser()) {
      return true;
    } else {
      return listOfSignees.every((signee) => {
        return signee.hasApproved === true;
      });
    }
  };

  const successUrl =
    props.flowOrigin === config.constants.flows.registration
      ? routeService.register_registration_complete
      : `${routeService.dashboard_registration_complete}`;

  const onSubmit = () => {
    const dataObject = {
      ssn: sessionState.ssn,
      fid: sessionState.id,
      organisationId: summary.organisationId,
      companyName: summary.companyName,
      isTermsAgreed,
      isPolicyAgreed,
      signeeEmail: summary.contact.signeeEmail,
      listOfSignees: summary.listOfSignees,
      signeeName: `${sessionState.name} ${sessionState.surname}`,
      adminUserName: summary.adminUserName,
      paymentMode: summary.paymentMode,
      visitingAddress1: summary.company[0].address.address1,
      visitingAddress2: summary.company[0].address.address2,
      visitingCity: summary.company[0].city.value,
      visitingPostCode: summary.company[0].postalCode.value,
      mailingAddress1: summary.company[1].address.address1,
      mailingAddress2: summary.company[1].address.address2,
      mailingCity: summary.company[1].city.value,
      mailingPostCode: summary.company[1].postalCode.value,
      isFullyApprovedAndSigned: isFullyApprovedAndSigned(sessionState.ssn),
      isSingleSigneeAndCurrentUser: isSingleSigneeAndCurrentUser,
      successUrl: successUrl
    };
    dispatch(approveCompanyRegistrationAction(dataObject));
  };

  const PageContent = () => {
    return (
      <>
        <div className='content'>
          {ssnMismatchFound && (
            <div className='form-error'>
              <h5>{ssnErrorHeading}</h5>
              <p>{ssnErrorDescription}</p>
            </div>
          )}
          <div className='summary-box-heading'>
            <h1>{`${tprop.heading.text} ${summary.companyName}`}</h1>
          </div>

          <div className='block-container'>
            <div className='left-block'>
              {tprop.summary.left.map((item, key) => (
                <span key={key}>
                  {summary.company[key].address.address1.length > 0 ? (
                    <SummaryBox
                      key={key}
                      iteration={key}
                      companyData={summary.company[key]}
                      labelData={item}
                    />
                  ) : (
                    <></>
                  )}
                </span>
              ))}
            </div>
            <div className='right-block'>
              {tprop.summary.right.map((item, key) => {
                if (key === 3) {
                  // the 4th item in the array (key = 3) holds signee emails
                  return (
                    <div className='summary-box-right' key={key}>
                      <div className='summary-box-subheading'>{item.label}</div>
                      <div className='summary-box-content font-weight-bold'>
                        {summary.listOfSignees.map((signee, ref) => (
                          <div key={ref} className='signee-email-container'>
                            <div
                              className={`icon-container ${
                                signee.hasApproved && 'icon-tick-pink'
                              }`}></div>
                            <div className='signee-email'>{signee.email}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className='summary-box-right' key={key}>
                      <div className='summary-box-subheading'>{item.label}</div>
                      <div className='summary-box-content font-weight-bold'>
                        {Object.values(summary.contact)[key]}
                      </div>
                    </div>
                  );
                }
              })}

              {requesting ? (
                <ButtonLoader />
              ) : (
                <Button
                  className={`${props.btnColor}`}
                  onClick={() => onSubmit()}>
                  {tprop.buttons.text}
                </Button>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const [showApprovalSuccess, setShowApprovalSuccess] = useState(false);

  return (
    <div className='summary-view'>
      {props.flow === config.constants.flows.dashboard ? (
        <div id='dashboard-flow'>
          <DashboardLayout
            {...props}
            hidePreviousButton={true}
            goBackUrl={routeService.onboarding_welcome_to_onboarding}>
            <ToastMessage
              show={showApprovalSuccess}
              setShow={setShowApprovalSuccess}
              heading={'saveSuccessMessage.heading.text'}
              description={'saveSuccessMessage.description.text'}
              delay={3000}
              autohide={true}
            />
            <PageContent />
          </DashboardLayout>
        </div>
      ) : (
        <div id='registration-flow'>
          <SplitLayout
            flow={props.flow}
            largeLayout
            stepsWizard={props.stepsWizard}
            className='postlogin-template summary-container'
            backgroundImage='registration-welcome-left-background-image'>
            <PageContent />
          </SplitLayout>
        </div>
      )}
    </div>
  );
}
