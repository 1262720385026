/** @format */

import config from 'config';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logoutRequestAction } from 'stateContainer/actions';
import LogoutConfirmation from 'components/Modals/LogoutConfirmationModal';

function LogoutButton(props) {
  const { flow, isMobile, className } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { session } = useSelector((state) => state);

  const handleLogout = () => {
    if (session && session.isAuthenticated) {
      return dispatch(logoutRequestAction());
    }
  };

  const logoutConfirmation = () => {
    setShowModal(true);
  };

  const isRegistrationMenuFormat = flow === config.constants.flows.registration;

  const logoutText = t('lang_common:logout.buttons.text');
  return (
    <>
      {/* registration - desktop */}
      {isRegistrationMenuFormat && !isMobile && (
        <div
          className={`logout-button-container ${className ? className : ''}`}
          onClick={() => logoutConfirmation()}>
          <div className='logout-button-text'>{logoutText}</div>
        </div>
      )}

      {/* registration and dashboard - mobile */}
      {((!isRegistrationMenuFormat && isMobile) ||
        (isRegistrationMenuFormat && isMobile)) && (
        <div
          className={`${className ? className : ''}`}
          onClick={() => logoutConfirmation()}>
          <div className='logout-button-text'>{logoutText}</div>
        </div>
      )}

      {/* dashboard - desktop */}
      {!isRegistrationMenuFormat && !isMobile && (
        <div
          className={`${className ? className : ''}`}
          onClick={() => logoutConfirmation()}>
          {logoutText}
        </div>
      )}

      {showModal && (
        <LogoutConfirmation
          handleLogout={() => handleLogout()}
          setShowModal={() => setShowModal()}
          showModal={showModal}
        />
      )}
    </>
  );
}

export default LogoutButton;
