/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const fetchCurrentRegistrationStateOfChosenCompanyQuery = ({
  organisationId,
  ssn
}) => {
  return makeGraphQlRequest(`
  {
    Company (organisationId:"${organisationId}") {
      organisationId
      companyName: name
      contactEmail
      contactPhone
      phoneNumber
      paymentMode
      website
      isContactEmailValidated
      mailingAddress {
        address1
        address2
        postCode
        city
      }
      visitingAddress {
        address1
        address2
        postCode
        city
      }
      adminUser {
        fid
        ssn
        firstName
        lastName
        name
        phone
        email
        validationCode {
          isVerified
        }
      }
      signee{
        ssn
        fid
        name
        email
        hasApproved
        company {
          organisationId
          companyName: name
          contactEmail
          contactPhone
          phoneNumber
          paymentMode
          website
          isContactEmailValidated
          visitingAddress{
            address1
            address2
            postCode
            city
          }
          mailingAddress{
            address1
            address2
            postCode
            city
          }
          adminUser {
            fid
            ssn
            firstName
            lastName
            name
            phone
            email
          }
          signeeList : signee{
            ssn
            fid
            name
            email
            hasApproved
          }
        }
      }
    }
    Signee (ssn: "${ssn}") {
      ssn
      fid
      name
      email
      hasApproved
    }
  }
  `);
};
