/** @format */

export const reviewCompanyInfoUtils = ({
  t,
  props,
  personState,
  companyState,
  currentUserState,
  signeeCombinationState,
  boardMembershipState
}) => {
  /**
   * prepare data base data object
   */
  const data = {
    surName: '',
    firstName: '',
    companyName: '',
    initialValues: {},
    isRequesting: false
  };

  /**
   * extract firstName and surName of user
   */

  let { surName = '', firstName = '' } =
    (personState.data.details.length > 0 && personState.data.details[0]) || {};

  if (Object.keys(personState.data).length === 0) {
    firstName = currentUserState.data.firstName;
    surName = currentUserState.data.lastName;
  }

  data.surName = surName;
  data.firstName = firstName;

  const companyRecords = currentUserState.chosenCompany || {};

  const adminUserRecords =
    (currentUserState.chosenCompany &&
      currentUserState.chosenCompany.adminUser) ||
    {};

  /**
   * include company name with heading text
   */

  const companyName = companyRecords.companyName || '';
  data.companyName = t(`lang_${props.flow}:${props.view}.heading.text`, {
    companyName: companyName
  });

  /**
   * populate signee data for user
   */

  data.isSingleSigneeAndCurrentUser =
    currentUserState.existingRecords &&
    currentUserState.existingRecords.listOfSignees &&
    currentUserState.existingRecords.listOfSignees.length === 1 &&
    currentUserState.existingRecords.listOfSignees[0].ssn ===
      (personState.data && personState.data.personalNumber);

  /**
   * propagate signee combination state checks into data object
   */

  data.isSingleSigneeAndCurrentUser =
    signeeCombinationState.isSingleSigneeAndCurrentUser;

  data.currentUserIsSoleSigneeOfACombination =
    signeeCombinationState.currentUserIsSoleSigneeOfACombination;

  data.currentUserIsAdminUser =
    currentUserState.existingRecords &&
    currentUserState.existingRecords.adminUser &&
    currentUserState.existingRecords.adminUser.ssn ===
      personState.data.personalNumber;

  /**
   * set initial values for the Formik form
   */

  data.initialValues = {
    visitingAddress1:
      (companyRecords.visitingAddress &&
        companyRecords.visitingAddress.address1) ||
      '',
    visitingAddress2:
      (companyRecords.visitingAddress &&
        companyRecords.visitingAddress.address2) ||
      '',
    visitingCity:
      (companyRecords.visitingAddress && companyRecords.visitingAddress.city) ||
      '',
    visitingPostCode:
      (companyRecords.visitingAddress &&
        companyRecords.visitingAddress.postCode) ||
      '',
    mailingAddress1:
      (companyRecords.mailingAddress &&
        companyRecords.mailingAddress.address1) ||
      '',
    mailingAddress2:
      (companyRecords.mailingAddress &&
        companyRecords.mailingAddress.address2) ||
      '',
    mailingCity:
      (companyRecords.mailingAddress && companyRecords.mailingAddress.city) ||
      '',
    mailingPostCode:
      (companyRecords.mailingAddress &&
        companyRecords.mailingAddress.postCode) ||
      '',
    contactPersonFirstName: adminUserRecords.firstName || '',
    contactPersonLastName: adminUserRecords.lastName || '',
    contactPersonEmail: companyRecords.contactEmail || '',
    contactPersonPhone: companyRecords.contactPhone || ''
  };

  /**
   * check for states that are still requesting
   */

  data.isRequesting =
    currentUserState.requesting ||
    companyState.requesting ||
    personState.requesting ||
    signeeCombinationState.requesting ||
    boardMembershipState.requesting;

  return data;
};
