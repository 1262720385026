/** @format */

import React from 'react';

export default function RowItemBlock(props) {
  return (
    <>
      <div
        className={`profile-container-rowitem ${
          props.className ? props.className : ''
        }`}>
        <div className='profile-container-left'>
          <div className='item-row'>
            <div className='line-item-block'>
              <div className='item-col row-heading-container'>
                <h1 className='row-heading'>{props.heading}</h1>
                {props.exclamation && (
                  <div className='exclamation-container icon-exclamation row-heading-exclamation'></div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='profile-container-right'>{props.children}</div>
      </div>
    </>
  );
}
