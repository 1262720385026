/** @format */

import { getUserStatus } from 'utils/getUserStatus';
import { put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    const userData = payload.User && payload.User[0];

    let appRoutingData;

    /**
     * take actions if the user had already completed registration or performed part of it
     */

    if (userData) {
      Object.assign(userData, {
        ...userData.company,
        ...userData.address,
        ...userData.validationCode
      });

      const { isEmailVerified, isCompanyExists } = getUserStatus({
        user: userData,
        caller: 'app-routing-saga'
      });

      /**
       * update `user` state with userdata from the database
       */

      yield put({
        type: actionTypes.UPDATE_USER_DATA,
        payload: userData
      });

      /**
       * update `verification` state with email verification status
       */

      yield put({
        type: actionTypes.UPDATE_VERIFICATION_STATUS,
        payload: isEmailVerified
      });

      /**
       * check for registration completion status
       */

      appRoutingData = {
        isCompanyExists,
        isEmailVerified
      };
    }

    /**
     * update appRouting state with critical registration checkpoints
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: appRoutingData
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { checkRegistrationStatus: 'done' }
    });
  } catch (error) {
    /**
     * error must never happen. if it does. there is something wrong in the try block
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_FAILED,
      payload: error
    });
  }
}

export function* appRoutingStateSaga() {
  yield takeLatest(actionTypes.__APP_ROUTING_DATA_REQUESTED, workerSaga);
}
