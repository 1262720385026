/** @format */

export const emailVerificationCodeTemplate = ({ email, firstName }) => {
  return {
    template: 'tta_web_send_verification_code',
    source: 'NO_REPLY',
    isCorporateWeb: true,
    templateData: {
      destination: `${email}`,
      dataFields: {
        name: `${firstName}`
      }
    }
  };
};
