/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

export default function RegularHeaderProfileBlock(props) {
  const ProfileItems = () => {
    return (
      <div className='header-right-left-block'>
        <div className='profile-picture'>
          <div className='icon-profile'></div>
        </div>
        <div className='profile-info'>
          <div className='profile-name'>{props.fullName}</div>
          <div className='profile-location'>{props.location}</div>
        </div>
      </div>
    );
  };

  return props.disabled ? (
    <ProfileItems />
  ) : (
    <Link to={props.profileUrl}>
      <ProfileItems />
    </Link>
  );
}
