/** @format */

import i18next from 'i18next';

const getRoute = (key) => {
  return i18next.t(`lang_route:${key}`);
};

const routeService = {
  register_start: getRoute(`register_start`),
  register_login_mode: getRoute(`register_login_mode`),
  register_login: getRoute(`register_login`),
  register_bankid_redirect: getRoute(`register_bankid_redirect`),
  common_logging_in: getRoute(`common_logging_in`),
  common_logging_out: getRoute(`common_logging_out`),
  register_choose_company: getRoute(`register_choose_company`),
  register_company_info: getRoute(`register_company_info`),
  register_validate_email: getRoute(`register_validate_email`),
  register_validating_email: getRoute(`register_validating_email`),
  register_choose_signee_combination: getRoute(
    `register_choose_signee_combination`
  ),
  register_request_signee_approval: getRoute(
    `register_request_signee_approval`
  ),
  register_signee_approval_request_sent: getRoute(
    `register_signee_approval_request_sent`
  ),
  register_agree_to_terms: getRoute(`register_agree_to_terms`),
  register_terms_and_conditions: getRoute(`register_terms_and_conditions`),
  register_privacy_policy: getRoute(`register_privacy_policy`),
  register_mode_of_payment: getRoute(`register_mode_of_payment`),
  register_summary: getRoute(`register_summary`),
  register_sign_power_of_attorney: getRoute(`register_sign_power_of_attorney`),
  register_review_company_info: getRoute(`register_review_company_info`),

  register_add_state_pension: getRoute(`register_add_state_pension`),
  register_registration_complete: getRoute(`register_registration_complete`),

  profile_home: getRoute(`profile_home`),
  profile_my_account: getRoute(`profile_my_account`),
  profile_subscription: getRoute(`profile_subscription`),
  profile_email: getRoute(`profile_email`),

  profile_set_power_of_attorney: getRoute(`profile_set_power_of_attorney`),
  profile_faq_power_of_attorney: getRoute(`profile_faq_power_of_attorney`),
  profile_agree_to_terms: getRoute(`profile_agree_to_terms`),
  profile_terms_and_conditions: getRoute(`profile_terms_and_conditions`),
  profile_how_we_choose_funds: getRoute(`profile_how_we_choose_funds`),
  profile_how_we_calculate: getRoute(`profile_how_we_calculate`),
  profile_sustainability_policy: getRoute(`profile_sustainability_policy`),
  profile_cookies: getRoute(`profile_cookies`),
  profile_privacy_policy: getRoute(`profile_privacy_policy`),
  profile_stripe_payment: getRoute(`profile_stripe_payment`),
  profile_approve_power_of_attorney: getRoute(
    `profile_approve_power_of_attorney`
  ),
  profile_sign_power_of_attorney: getRoute(`profile_sign_power_of_attorney`),
  profile_creating_power_of_attorney: getRoute(
    `profile_creating_power_of_attorney`
  ),
  profile_bankid_redirect: getRoute(`profile_bankid_redirect`),
  register_redirect_user: getRoute(`register_redirect_user`),
  dashboard_homepage: getRoute(`dashboard_homepage`),
  dashboard_review_company_info: getRoute(`dashboard_review_company_info`),
  dashboard_agree_to_terms: getRoute(`dashboard_agree_to_terms`),
  dashboard_terms_and_conditions: getRoute(`dashboard_terms_and_conditions`),
  dashboard_privacy_policy: getRoute(`dashboard_privacy_policy`),
  dashboard_mode_of_payment: getRoute(`dashboard_mode_of_payment`),
  dashboard_summary: getRoute(`dashboard_summary`),
  dashboard_registration_complete: getRoute(`dashboard_registration_complete`)
};

export default routeService;
