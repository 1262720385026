/** @format */

/*****************
 **  Root-Saga  **
 *****************/

import { all, fork } from 'redux-saga/effects';
import { loginSaga } from 'stateContainer/sagas/login-saga';
import { logoutSaga } from 'stateContainer/sagas/logout-saga';
import { updateEmailSaga } from 'stateContainer/sagas/update-email-saga';
import { createCompanySaga } from 'stateContainer/sagas/create-company-saga';
import { chooseCompanySaga } from 'stateContainer/sagas/choose-company-saga';
import { fetchBaseDataSaga } from 'stateContainer/sagas/fetch-base-data-saga';
import { createEmployeeSaga } from 'stateContainer/sagas/create-employee-saga';
import { deleteEmployeeSaga } from 'stateContainer/sagas/delete-employee-saga';
import { appRoutingStateSaga } from 'stateContainer/sagas/app-routing-state-saga';
import { fetchPersonDataSaga } from 'stateContainer/sagas/fetch-person-data-saga';
import { fetchCompanyDataSaga } from 'stateContainer/sagas/fetch-company-data-saga';
import { fetchBoardMembershipSaga } from 'stateContainer/sagas/fetch-board-membership-saga';
import { sendVerificationCodeSaga } from 'stateContainer/sagas/send-verification-code-saga';
import { createEmployeeInBulkSaga } from 'stateContainer/sagas/create-employee-in-bulk-saga';
import { requestSigneeApprovalSaga } from 'stateContainer/sagas/request-signee-approval-saga';
import { fetchSigneeCombinationSaga } from 'stateContainer/sagas/fetch-signee-combination-saga';
import { confirmVerificationCodeSaga } from 'stateContainer/sagas/confirm-verification-code-saga';
import { approveCompanyRegistrationSaga } from 'stateContainer/sagas/approve-company-registration-saga';
import { createSigneeCombinationOnCompanySaga } from 'stateContainer/sagas/create-signee-combination-on-company-saga';
import { triggerCompanyRegistrationFullyApprovedSaga } from 'stateContainer/sagas/trigger-company-registration-fully-approved-saga';
import { fetchCurrentRegistrationStateOfChosenCompanySaga } from 'stateContainer/sagas/fetch-current-registration-state-of-chosen-company-saga';

const sagas = [
  loginSaga,
  logoutSaga,
  updateEmailSaga,
  createCompanySaga,
  chooseCompanySaga,
  fetchBaseDataSaga,
  createEmployeeSaga,
  deleteEmployeeSaga,
  appRoutingStateSaga,
  fetchPersonDataSaga,
  fetchCompanyDataSaga,
  createEmployeeInBulkSaga,
  sendVerificationCodeSaga,
  fetchBoardMembershipSaga,
  requestSigneeApprovalSaga,
  fetchSigneeCombinationSaga,
  confirmVerificationCodeSaga,
  approveCompanyRegistrationSaga,
  triggerCompanyRegistrationFullyApprovedSaga,
  fetchCurrentRegistrationStateOfChosenCompanySaga,
  createSigneeCombinationOnCompanySaga
];

function* rootSaga() {
  const sagaForks = sagas.map((saga) => fork(saga));
  yield all([...sagaForks]);
}

export default rootSaga;
