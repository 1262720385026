/** @format */

import { Storage } from 'aws-amplify';
import { createEmployeeInBulkService } from 'services';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import {
  manipulateEmployeesYetToSignup,
  manipulateSignedUpEmployees
} from 'utils/sagaUtils';

function* workerSaga({ payload }) {
  try {
    if (!!payload.organisationId && !!payload.url) {
      const response = yield call(createEmployeeInBulkService, payload);

      /**
       * discard uploaded csv from S3 bucket since it is no longer needed
       */

      Storage.remove(payload.key, payload.params);

      /**
       * process the updated list of employees and make them table friendly
       */

      let employeesList = [];

      const { listOfEmployees, organisationId } = response.data;

      const manipulatedListOfEmployeesYetToSignup =
        manipulateEmployeesYetToSignup(
          listOfEmployees.employee,
          organisationId
        );

      const manipulatedListOfSignedUpEmployees = manipulateSignedUpEmployees(
        listOfEmployees.user
      );

      employeesList = [
        ...manipulatedListOfSignedUpEmployees,
        ...manipulatedListOfEmployeesYetToSignup
      ];

      yield put({
        type: actionTypes.UPDATE_LIST_OF_EMPLOYEES_SUCCESS,
        payload: employeesList
      });

      /**
       * create and update user state with create user status
       */

      yield put({
        type: actionTypes.CREATE_EMPLOYEE_IN_BULK_SUCCESS,
        payload
      });

      /**
       * update appRouting state with registration progress
       */

      yield put({
        type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
        payload: { createEmployee: 'done' }
      });
    } else {
      throw new Error(JSON.stringify({ error: 'null values found', payload }));
    }
  } catch (error) {
    yield put({ type: actionTypes.CREATE_EMPLOYEE_IN_BULK_FAILED, error });
  }
}

export function* createEmployeeInBulkSaga() {
  yield takeLatest(actionTypes.CREATE_EMPLOYEE_IN_BULK_REQUESTED, workerSaga);
}
