/** @format */

import React from 'react';

export default function DashboardContent({
  tprop,
  changeTab,
  listOfEmployees
}) {
  const numberOfRegisteredUsers = listOfEmployees.length;

  const numberOfSignedUpUsers = listOfEmployees.filter(
    (user) => user.isSignedUpEmployee === true
  ).length;
  return (
    <div className='profile-heading'>
      <div className='homepage-tabitem-header-container'>
        <div className='homepage-tabitem-header-left'>
          <h1>{tprop.tabs[0].heading.text}</h1>
        </div>
      </div>
      <div className='stats-container' onClick={() => changeTab('employees')}>
        <div className='stats-box'>
          <h2>{tprop.tabs[0].subheading.registered.text}</h2>
          <div className='stats-number'>{numberOfRegisteredUsers}</div>
        </div>
        <div className='stats-box'>
          <h2>{tprop.tabs[0].subheading.signedUp.text}</h2>
          <div className='stats-number'>{numberOfSignedUpUsers}</div>
        </div>
      </div>
      <hr className='horizontal-divider color-grey' />
    </div>
  );
}
