/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

export default function LineItemBlock(props) {
  const {
    icon,
    text,
    unit,
    onClick,
    children,
    className,
    editButton,
    editButtonWithUrl
  } = props;

  return (
    <>
      <div className={`line-item-block ${className ? className : ''}`}>
        <div className='item-row'>
          {icon !== undefined && (
            <div className='item-col icon-container'>
              <div className={`box-icons icon-${icon}-square `}></div>
            </div>
          )}
          <div className='item-col content'>
            <div className='text-content'>
              {text && text}

              {children && children}

              {unit && <div className='unit'>{unit}</div>}
            </div>
          </div>

          {/* edit button leads to a url */}
          {editButtonWithUrl && (
            <div className='item-col edit-button-container'>
              <Link to={editButtonWithUrl}>
                <div className='icon-edit'></div>
              </Link>
            </div>
          )}

          {/* edit button fires a function */}
          {editButton && (
            <div className='item-col edit-button-container'>
              <div className='icon-edit' onClick={onClick}></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
