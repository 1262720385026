/** @format */

import _ from 'lodash';
import { mutations } from 'services';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import {
  removeSignupDate,
  manipulateSignedUpEmployees,
  manipulateEmployeesYetToSignup
} from 'utils/sagaUtils';

function* workerSaga({ payload }) {
  try {
    if (!!payload.ssn && !!payload.organisationId) {
      const response = yield call(mutations.createEmployeeMutation, payload);

      if (response.addEmployeeOnCompany === null) {
        throw new Error(response);
      }
      let { company = {} } = response.addEmployeeOnCompany;

      let listOfEmployees = [];
      if (company && (company.user || company.employee)) {
        /**
         *
         * important :
         * Do not manipulate the company.user object directly by assigning its
         * value to another variable since altering reassigned values will cause non-primitive
         * data types to modify the original values as well. we will use a deep clone instead
         *
         */

        let clonedUser = JSON.parse(JSON.stringify(company.user));

        clonedUser = removeSignupDate(clonedUser);

        const employee = removeSignupDate(company.employee);

        /*
         * Compare employee array with user array to find out users who have not yet signed up
         */

        const employeesYetToSignup = _.differenceWith(
          employee,
          clonedUser,
          _.isEqual
        );

        /**
         * Manipulate array items with custom data including combined first-name and last-name etc...
         */

        const manipulatedListOfEmployeesYetToSignup =
          manipulateEmployeesYetToSignup(employeesYetToSignup);

        const manipulatedListOfSignedUpEmployees = manipulateSignedUpEmployees(
          company.user
        );

        /**
         * Combine signed up users and yet-to-signup users
         */

        listOfEmployees = [
          ...manipulatedListOfSignedUpEmployees,
          ...manipulatedListOfEmployeesYetToSignup
        ];
      }

      /**
       * create and update user state with create user status
       */

      yield put({
        type: actionTypes.CREATE_EMPLOYEE_SUCCESS,
        payload: [...listOfEmployees]
      });

      /**
       * update appRouting state with registration progress
       */

      yield put({
        type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
        payload: { createEmployee: 'done' }
      });
    } else {
      throw new Error(JSON.stringify({ error: 'null values found', payload }));
    }
  } catch (error) {
    yield put({ type: actionTypes.CREATE_EMPLOYEE_FAILED, error });
  }
}

export function* createEmployeeSaga() {
  yield takeLatest(actionTypes.CREATE_EMPLOYEE_REQUESTED, workerSaga);
}
