/** @format */

import React from 'react';
import Toast from 'react-bootstrap/Toast';

export default function ToastMessage(props) {
  const { show, setShow, delay, autohide, heading, description } = props;

  return (
    <div className='toast-message'>
      <Toast
        animation={true}
        onClose={() => setShow(false)}
        show={show}
        delay={delay}
        autohide={autohide}>
        <Toast.Header>
          <strong className='me-auto'>{heading}</strong>
        </Toast.Header>
        <Toast.Body>{description}</Toast.Body>
      </Toast>
    </div>
  );
}
