/** @format */

import React from 'react';
import LogoutButton from 'components/LogoutButton';
import { withRouter } from 'react-router-dom';

export default withRouter(function SigneeApprovalRequestSent(props) {
  const { tprop, flow } = props;

  return (
    <>
      <LogoutButton flow={flow} />

      <div className='registration-complete-container centered-container centered-container-default-background-image'>
        <div className='centered-content'>
          <div className='registration-complete-top-container centered-content-top'>
            <div className='image-success'></div>

            <h1>{tprop.heading.text}</h1>
            <div className='registration-complete-top-contentlist'>
              {tprop.description.map((item, key) => (
                <p key={key}>{item.text}</p>
              ))}
            </div>
          </div>
          <div className='centered-content-bottom '>
            <div className='logo-the-third-act-white' />
            <div>
              <h2>{tprop.logo.text}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
