/** @format */

import React from 'react';
import config from 'config';
import { Link, withRouter } from 'react-router-dom';

import { getPrevButtonDestinationUrl } from 'utils/getPrevButtonDestinationUrl';

function MobileHeaderLeft(props) {
  const {
    flow,
    history,
    goBackUrl,
    location: { pathname },
    hidePreviousButton
  } = props;
  const destinationUrl = getPrevButtonDestinationUrl(pathname);

  const goPrevious = () => {
    if (flow === config.constants.flows.registration) {
      return history.push(destinationUrl);
    } else if (flow === config.constants.flows.common) {
      return history.goBack();
    } else {
      return history.push(goBackUrl);
    }
  };

  return (
    <div className='mobile-header-left'>
      <div className='mobile-header-logo-container'>
        {props.disabled ? (
          <div className='logo-the-third-act-white' />
        ) : (
          <Link to={props.homePageUrl}>
            <div className='logo-the-third-act-white' />
          </Link>
        )}
      </div>
      {hidePreviousButton ? (
        <></>
      ) : (
        <div className={`icon-arrow-left `} onClick={() => goPrevious()} />
      )}
    </div>
  );
}

export default withRouter(MobileHeaderLeft);
