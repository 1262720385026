/** @format */

import config from 'config';

const { logout } = config.app.criipto.endpoints;
const { post_logout_redirect_uri } = config.app.criipto.settings;

const getCriiptoLogoutUri = () => {
  const apiUri = `${logout}?post_logout_redirect_uri=${post_logout_redirect_uri}`;

  return apiUri;
};

export default getCriiptoLogoutUri;
