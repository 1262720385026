/** @format */

export const chooseCompanyUtils = ({
  t,
  props,
  personState,
  companyState,
  currentUserState,
  boardMembershipState,
  signeeCombinationState
}) => {
  /**
   * prepare data base data object
   */
  const data = {
    isRequesting: false,
    initialValues: {},
    selectDropdownOptions: [],
    isCompanyExists: false,
    firstNameWithHeading: ''
  };

  /**
   * check for states that are still requesting
   */

  data.isRequesting =
    currentUserState.requesting ||
    companyState.requesting ||
    personState.requesting ||
    signeeCombinationState.requesting ||
    boardMembershipState.requesting;

  /**
   * extract firstName and surName of user
   */

  let { firstName = '' } =
    (personState.details && personState.details[0]) || {};

  firstName = t(`lang_${props.flow}:${props.view}.heading.text`, {
    firstName: `${firstName}`
  });

  data.firstNameWithHeading = firstName;

  /**
   * populate dropdown options
   */

  const selectDropdownOptions = [];
  if (selectDropdownOptions.length <= 0) {
    boardMembershipState.data.engagements &&
      boardMembershipState.data.engagements.forEach((item) => {
        selectDropdownOptions.push({
          value: item.companyId,
          label: item.companyName
        });
      });
  }
  data.selectDropdownOptions = selectDropdownOptions;

  /**
   * set initial values for the Formik form
   */

  data.initialValues = {
    chosenCompany: '',
    organisationId: ''
  };

  return data;
};
