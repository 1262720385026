/** @format */

import { createEmployeeMutation } from 'services/graphqlService/mutations/create-employee-mutation';
import { deleteEmployeeMutation } from 'services/graphqlService/mutations/delete-employee-mutation';
import { registerCompanyMutation } from 'services/graphqlService/mutations/register-company-mutation';
import { createSigneeOnCompanyMutation } from 'services/graphqlService/mutations/create-signee-on-company-mutation';
import { registerCompanyWithSigneeMutation } from 'services/graphqlService/mutations/register-company-with-signee-mutation';
import { approveCompanyRegistrationMutation } from 'services/graphqlService/mutations/approve-company-registration-mutation';

export const mutations = {
  createEmployeeMutation,
  deleteEmployeeMutation,
  registerCompanyMutation,
  createSigneeOnCompanyMutation,
  registerCompanyWithSigneeMutation,
  approveCompanyRegistrationMutation
};
