/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const createSigneeOnCompanyMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    createSigneeCombinationOnCompany(
      ssn: "${data.ssn}"
      name: "${data.name}"
      email: "${data.email}"
      organisationId: "${String(data.organisationId).replace('-', '')}"
      ){
        _id
      }
    }`);
};
