/** @format */

import config from 'config';

const getAddressObject = (address) => {
  return {
    address: {
      address1: address.address1,
      address2: address.address2
    },
    postalCode: {
      value: address.postCode
    },
    city: {
      value: address.city
    }
  };
};

const getContactData = ({ currentUserData }) => {
  return {
    contact:
      (currentUserData.adminUser && currentUserData.adminUser[0].name) ||
      currentUserData.name ||
      '',
    contactEmail: currentUserData.contactEmail || '',
    contactPhone: currentUserData.contactPhone || '',
    signeeEmail:
      (currentUserData.isValidAdmin &&
        currentUserData.isValidAdmin[0] &&
        currentUserData.isValidAdmin[0].signeeEmail) ||
      currentUserData.contactEmail ||
      '',

    paymentMode: config.app.defaultPaymentMode
  };
};

export const getSummaryObject = ({ sessionState, currentUserState }) => {
  const currentUserData = currentUserState.data;
  const { name, organisationId, visitingAddress, mailingAddress } =
    currentUserData;
  const { chosenCompany } = currentUserState;
  const contactData = getContactData({ currentUserData, sessionState });

  const company = [
    {
      ...getAddressObject(visitingAddress)
    },
    {
      ...getAddressObject(mailingAddress)
    }
  ];

  const data = {
    company: company,
    contact: contactData,
    organisationId: organisationId,
    companyName: chosenCompany.companyName,
    paymentMode: config.app.defaultPaymentMode,
    listOfSignees: chosenCompany.listOfSignees || [],
    adminUserName: chosenCompany.adminUserName || name || ''
  };
  return data;
};
