/** @format */

import React from 'react';
import Linkify from 'react-linkify';

export default function LinkifyHelper(props) {
  return (
    <Linkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          target='blank'
          rel='noopener noreferrer'
          href={decoratedHref}
          key={key}>
          {decoratedText}
        </a>
      )}>
      {props.children}
    </Linkify>
  );
}
