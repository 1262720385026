/** @format */

/** Login action-types **/
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

/** Logout action-types **/
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'LOGOUT_FAILED';

/**fetch base data actions */

export const FETCH_BASEDATA_REQUESTED = 'FETCH_BASEDATA_REQUESTED';
export const FETCH_BASEDATA_SUCCESS = 'FETCH_BASEDATA_SUCCESS';
export const FETCH_BASEDATA_FAILED = 'FETCH_BASEDATA_FAILED';

/** Show session will expire is a fw minutes message */
export const SESSION_ABOUT_TO_EXPIRE_MESSAGE_REQUESTED =
  'SESSION_ABOUT_TO_EXPIRE_MESSAGE_REQUESTED';
export const SESSION_ABOUT_TO_EXPIRE_MESSAGE_SUCCESS =
  'SESSION_ABOUT_TO_EXPIRE_MESSAGE_SUCCESS';

/** Show final confirmation message before logging out user */
export const CONFIRM_END_OF_SESSION_REQUESTED =
  'CONFIRM_END_OF_SESSION_REQUESTED';
export const CONFIRM_END_OF_SESSION_SUCCESS = 'CONFIRM_END_OF_SESSION_SUCCESS';

/** Fetch-roaring-data action-types **/
export const PERSON_DATA_REQUESTED = 'PERSON_DATA_REQUESTED';
export const PERSON_DATA_SUCCESS = 'PERSON_DATA_SUCCESS';
export const PERSON_DATA_SKIPPED = 'PERSON_DATA_SKIPPED';
export const PERSON_DATA_FAILED = 'PERSON_DATA_FAILED';

/** Fetch-current-user-data action-types **/
export const FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_REQUESTED =
  'FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_REQUESTED';
export const FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SUCCESS =
  'FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SUCCESS';
export const FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SKIPPED =
  'FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SKIPPED';
export const FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_FAILED =
  'FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_FAILED';

export const FETCH_BOARD_MEMBERSHIP_REQUESTED =
  'FETCH_BOARD_MEMBERSHIP_REQUESTED';
export const FETCH_BOARD_MEMBERSHIP_SUCCESS = 'FETCH_BOARD_MEMBERSHIP_SUCCESS';
export const FETCH_BOARD_MEMBERSHIP_SKIPPED = 'FETCH_BOARD_MEMBERSHIP_SKIPPED';
export const FETCH_BOARD_MEMBERSHIP_FAILED = 'FETCH_BOARD_MEMBERSHIP_FAILED';

export const COMPANY_DATA_REQUESTED = 'COMPANY_DATA_REQUESTED';
export const COMPANY_DATA_SUCCESS = 'COMPANY_DATA_SUCCESS';
export const COMPANY_DATA_SKIPPED = 'COMPANY_DATA_SKIPPED';
export const COMPANY_DATA_FAILED = 'COMPANY_DATA_FAILED';

export const FETCH_SIGNEE_COMBINATION_REQUESTED =
  'FETCH_SIGNEE_COMBINATION_REQUESTED';
export const FETCH_SIGNEE_COMBINATION_SUCCESS =
  'FETCH_SIGNEE_COMBINATION_SUCCESS';
export const FETCH_SIGNEE_COMBINATION_SKIPPED =
  'FETCH_SIGNEE_COMBINATION_SKIPPED';
export const FETCH_SIGNEE_COMBINATION_FAILED =
  'FETCH_SIGNEE_COMBINATION_FAILED';

export const COMPANY_CHOICE_REQUESTED = 'COMPANY_CHOICE_REQUESTED';
export const COMPANY_CHOICE_SUCCESS = 'COMPANY_CHOICE_SUCCESS';
export const COMPANY_CHOICE_FAILED = 'COMPANY_CHOICE_REQUESTED';

/** Update-company-data for registration action-types **/
export const UPDATE_COMPANY_DATA_REQUESTED = 'UPDATE_COMPANY_DATA_REQUESTED';
export const UPDATE_COMPANY_DATA_SUCCESS = 'UPDATE_COMPANY_DATA_SUCCESS';
export const UPDATE_COMPANY_DATA_FAILED = 'UPDATE_COMPANY_DATA_FAILED';

/** Clone-roaring-data on registration action-types **/
export const REGISTER_COMPANY_REQUESTED = 'REGISTER_COMPANY_REQUESTED';
export const REGISTER_COMPANY_SUCCESS = 'REGISTER_COMPANY_SUCCESS';
export const REGISTER_COMPANY_SKIPPED = 'REGISTER_COMPANY_SKIPPED';
export const REGISTER_COMPANY_FAILED = 'REGISTER_COMPANY_FAILED';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

/** Send-verification-code action-types **/
export const SEND_VERIFICATION_CODE_REQUESTED =
  'SEND_VERIFICATION_CODE_REQUESTED';
export const SEND_VERIFICATION_CODE_SUCCESS = 'SEND_VERIFICATION_CODE_SUCCESS';
export const SEND_VERIFICATION_CODE_FAILED = 'SEND_VERIFICATION_CODE_FAILED';

export const UPDATE_VERIFICATION_STATUS = 'UPDATE_VERIFICATION_STATUS';

/** Confirm-verification-code action-types **/
export const CONFIRM_VERIFICATION_CODE_REQUESTED =
  'CONFIRM_VERIFICATION_CODE_REQUESTED';
export const CONFIRM_VERIFICATION_CODE_SUCCESS =
  'CONFIRM_VERIFICATION_CODE_SUCCESS';
export const CONFIRM_VERIFICATION_CODE_FAILED =
  'CONFIRM_VERIFICATION_CODE_FAILED';

/** update-email action-types **/
export const UPDATE_EMAIL_REQUESTED = 'UPDATE_EMAIL_REQUESTED';
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS';
export const UPDATE_EMAIL_FAILED = 'UPDATE_EMAIL_FAILED';

/** set-power-of-attorney-validity action-types **/
export const SET_VALIDITY_FOR_POWER_OF_ATTORNEY =
  'SET_VALIDITY_FOR_POWER_OF_ATTORNEY';

/** set-terms-agreed state **/
export const SET_IS_TERMS_AGREED_REQUESTED = 'SET_IS_TERMS_AGREED_REQUESTED';

/** check registration state of user to define app routing logic **/
export const __APP_ROUTING_DATA_REQUESTED = '__APP_ROUTING_DATA_REQUESTED';
export const __APP_ROUTING_DATA_REFRESHED = '__APP_ROUTING_DATA_REFRESHED';
export const __APP_ROUTING_DATA_SUCCESS = '__APP_ROUTING_DATA_SUCCESS';
export const __APP_ROUTING_DATA_FAILED = '__APP_ROUTING_DATA_FAILED';
export const __APP_ROUTING_CHECKPOINT_LOGGED =
  '__APP_ROUTING_CHECKPOINT_LOGGED';

export const SET_TERMS_POLICY_AGREED = 'SET_TERMS_POLICY_AGREED';
export const SET_PAYMENT_MODE_CHOSEN = 'SET_PAYMENT_MODE_CHOSEN';

export const REQUEST_SIGNEE_APPROVAL_REQUESTED =
  'REQUEST_SIGNEE_APPROVAL_REQUESTED';
export const REQUEST_SIGNEE_APPROVAL_SUCCESS =
  'REQUEST_SIGNEE_APPROVAL_SUCCESS';
export const REQUEST_SIGNEE_APPROVAL_FAILED = 'REQUEST_SIGNEE_APPROVAL_FAILED';

export const FETCH_REQUESTID_FROM_URL = 'FETCH_REQUESTID_FROM_URL';

export const COMPANY_REGISTRATION_STATE_REQUESTED =
  'COMPANY_REGISTRATION_STATE_REQUESTED';
export const COMPANY_REGISTRATION_STATE_SUCCESS =
  'COMPANY_REGISTRATION_STATE_SUCCESS';
export const COMPANY_REGISTRATION_STATE_FAILED =
  'COMPANY_REGISTRATION_STATE_FAILED';

export const CHOOSE_SIGNEE_COMBINATION = 'CHOOSE_SIGNEE_COMBINATION';
export const STORE_SIGNEE_COMBINATION_REQUESTED =
  'STORE_SIGNEE_COMBINATION_REQUESTED';
export const STORE_SIGNEE_COMBINATION_SUCCESS =
  'STORE_SIGNEE_COMBINATION_SUCCESS';
export const STORE_SIGNEE_COMBINATION_FAILED =
  'STORE_SIGNEE_COMBINATION_FAILED';

export const CREATE_SIGNEE_COMBINATION_ON_COMPANY_REQUESTED =
  'CREATE_SIGNEE_COMBINATION_ON_COMPANY_REQUESTED';
export const CREATE_SIGNEE_COMBINATION_ON_COMPANY_SUCCESS =
  'CREATE_SIGNEE_COMBINATION_ON_COMPANY_SUCCESS';
export const CREATE_SIGNEE_COMBINATION_ON_COMPANY_FAILED =
  'CREATE_SIGNEE_COMBINATION_ON_COMPANY_FAILED';

export const CONFIRM_COMPANY_REGISTRATION_DATA =
  'CONFIRM_COMPANY_REGISTRATION_DATA';

export const APPROVE_COMPANY_REGISTRATION_REQUESTED =
  'APPROVE_COMPANY_REGISTRATION_REQUESTED';
export const APPROVE_COMPANY_REGISTRATION_SUCCESS =
  'APPROVE_COMPANY_REGISTRATION_SUCCESS';
export const APPROVE_COMPANY_REGISTRATION_FAILED =
  'APPROVE_COMPANY_REGISTRATION_FAILED';

export const SEND_COMPANY_REGISTRATION_FULLY_APPROVED_REQUESTED =
  'SEND_COMPANY_REGISTRATION_FULLY_APPROVED_REQUESTED';
export const SEND_COMPANY_REGISTRATION_FULLY_APPROVED_SUCCESS =
  'SEND_COMPANY_REGISTRATION_FULLY_APPROVED_SUCCESS';
export const COMPANY_REGISTRATION_FULLY_APPROVED_FAILED =
  'COMPANY_REGISTRATION_FULLY_APPROVED_FAILED';

export const CREATE_EMPLOYEE_REQUESTED = 'CREATE_EMPLOYEE_REQUESTED';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_FAILED = 'CREATE_EMPLOYEE_FAILED';
export const CREATE_EMPLOYEE_SKIPPED = 'CREATE_EMPLOYEE_SKIPPED';

export const CREATE_EMPLOYEE_IN_BULK_REQUESTED =
  'CREATE_EMPLOYEE_IN_BULK_REQUESTED';
export const CREATE_EMPLOYEE_IN_BULK_SUCCESS =
  'CREATE_EMPLOYEE_IN_BULK_SUCCESS';
export const CREATE_EMPLOYEE_IN_BULK_FAILED = 'CREATE_EMPLOYEE_IN_BULK_FAILED';

export const DELETE_EMPLOYEE_REQUESTED = 'DELETE_EMPLOYEE_REQUESTED';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILED = 'DELETE_EMPLOYEE_FAILED';

export const UPDATE_LIST_OF_EMPLOYEES_SUCCESS =
  'UPDATE_LIST_OF_EMPLOYEES_SUCCESS';
