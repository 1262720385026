/** @format */

import config from 'config';
import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import TableBody from 'views/Dashboard/HomePage/SortableTable/TableBody';
import TableHead from 'views/Dashboard/HomePage/SortableTable/TableHead';

/*
 * ref: https://blog.logrocket.com/creating-react-sortable-table/
 */

const { sortingLocale } = config.app;

export default function SortableTable({ data, columns, onClick }) {
  const [tableData, setTableData] = useState(data);

  const handleSorting = (sortField, sortOrder) => {
    if (!tableData.length > 0) {
      return;
    }
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField]
            .toString()
            .localeCompare(b[sortField].toString(), sortingLocale, {
              numeric: true
            }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  return (
    <>
      <Table responsive striped hover className='table'>
        <TableHead columns={columns} handleSorting={handleSorting} />
        <TableBody columns={columns} tableData={tableData} onClick={onClick} />
      </Table>
    </>
  );
}
