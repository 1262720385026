/** @format */

import config from 'config';
import { invokeRestApi } from 'utils/invokeRestApi';

const {
  services: { CREATE_EMPLOYEE_IN_BULK_SERVICE_PATHNAME }
} = config.app;

export async function createEmployeeInBulkService(payload) {
  return await invokeRestApi({
    service: CREATE_EMPLOYEE_IN_BULK_SERVICE_PATHNAME,
    payload
  });
}

export default {
  createEmployeeInBulkService
};
