/** @format */

import app from 'config/app';
import awsConfig from 'config/aws';
import constants from 'config/constants';

const config = {
  app,
  awsConfig,
  constants
};

export default config;
