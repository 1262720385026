/** @format */

import { API } from 'aws-amplify';
import { store } from 'stateContainer/store';
import { confirmEndOfSessionAction } from 'stateContainer/actions';

export async function makeGraphQlRequest(query) {
  const {
    session: { requesting, sessionEndTime }
  } = store.getState();

  const currentTime = new Date().getTime();

  if (!requesting && currentTime > sessionEndTime) {
    /**
     * Logout user if session credentials are missing
     */
    return store.dispatch(confirmEndOfSessionAction());
  } else {
    /**
     * process the request if session is valid
     */

    return await API.graphql({ query, authMode: 'AWS_IAM' })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log('error: ', error.errors[0]);
        throw error;
      });
  }
}

export default {
  makeGraphQlRequest
};
