/** @format */

const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_CONFIG_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_CONFIG_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_CONFIG_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_CONFIG_COGNITO_APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: 'apiBasePath',
        endpoint: process.env.REACT_APP_API_GATEWAY_URL,
        region: process.env.REACT_APP_AWS_CONFIG_COGNITO_REGION
      }
    ],
    graphql_endpoint: `${process.env.REACT_APP_API_GATEWAY_URL}/common/graphql`,
    graphql_endpoint_iam_region: process.env.REACT_APP_AWS_CONFIG_COGNITO_REGION
  },
  Storage: {
    bucket: process.env.REACT_APP_S3_BUCKET,
    region: process.env.REACT_APP_AWS_CONFIG_COGNITO_REGION
  }
};

export default awsConfig;
