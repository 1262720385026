/** @format */

import React from 'react';
import RegularHeaderMenu from 'components/Layout/Header/RegularHeader/RegularHeaderRight/RegularHeaderMenu';
import RegularHeaderProfileBlock from 'components/Layout/Header/RegularHeader/RegularHeaderRight/RegularHeaderProfileBlock';

export default function RegularHeaderRight(props) {
  return (
    <div className='header-right'>
      <RegularHeaderProfileBlock
        fullName={props.fullName}
        location={props.location}
        profileUrl={props.profileUrl}
        {...props}
      />

      <RegularHeaderMenu flow={props.flow} {...props} />
    </div>
  );
}
