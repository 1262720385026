/** @format */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Field, ErrorMessage } from 'formik';

export default function Radio(props) {
  const { name, value, className, labeltext, labelstyle } = props;
  return (
    <>
      <Row className='radio-row'>
        <Col sm={1}>
          <Field
            className={`form-control radio-input ${className || ''}`}
            type='radio'
            name={name}
            value={value}
            {...props}
          />
        </Col>
        <Col sm={8} className='radio-label'>
          <label htmlFor={name} className={`${labelstyle && labelstyle}`}>
            {labeltext}
          </label>
        </Col>
        <ErrorMessage name={name} className='error' />
      </Row>
    </>
  );
}
