/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const approveCompanyRegistrationMutation = (data) => {
  return makeGraphQlRequest(`
    mutation {
      approveCompanyRegistration(
        fid: "${data.fid}",
        ssn: "${data.ssn}",
        organisationId: "${data.organisationId}",
        isTermsAgreed: ${data.isTermsAgreed},
        isPolicyAgreed: ${data.isPolicyAgreed},
        paymentMode: "${data.paymentMode}",
        visitingAddress1: "${data.visitingAddress1}",
        visitingAddress2: "${data.visitingAddress2}",
        visitingCity: "${data.visitingCity}",
        visitingPostCode: "${data.visitingPostCode}",
        mailingAddress1: "${data.mailingAddress1}",
        mailingAddress2: "${data.mailingAddress2}",
        mailingCity: "${data.mailingCity}",
        mailingPostCode: "${data.mailingPostCode}",
        countryCode:"SE"
      )
      {
        _id
      }
    }
  `);
};
