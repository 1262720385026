/** @format */

import routeService from 'services/routeService';

export const getPrevButtonDestinationUrl = (pathname) => {
  let destination;
  switch (pathname) {
    case routeService.register_terms_and_conditions:
      destination = routeService.register_agree_to_terms;
      break;
    case routeService.register_privacy_policy:
      destination = routeService.register_agree_to_terms;
      break;
    case routeService.register_sign_power_of_attorney:
      destination = routeService.register_summary;
      break;
    case routeService.register_summary:
      destination = routeService.register_agree_to_terms;
      break;
    case routeService.register_agree_to_terms:
      destination = routeService.register_set_power_of_attorney;
      break;
    case routeService.register_faq_power_of_attorney:
      destination = routeService.register_set_power_of_attorney;
      break;
    case routeService.register_set_power_of_attorney:
      destination = routeService.register_company_info;
      break;
    case routeService.register_validate_email:
      destination = routeService.register_company_info;
      break;
    case routeService.register_company_info:
      destination = routeService.register_choose_company;
      break;
    default:
      destination = routeService.register_choose_company;
  }
  return destination;
};
