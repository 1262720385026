/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const createEmployeeMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    addEmployeeOnCompany(
      identity: "${data.ssn}",
      firstName: "${data.firstName}",
      lastName: "${data.lastName}",
      email: "${data.email}",      
      organisationId: "${data.organisationId}"
    ){
      _id
      identity
      ssn : identity
      firstName
      lastName
      email
      company{
        employee{
          _id
          ssn: identity
          firstName
          lastName
          email
          signupDate{
            formatted
          }
          firstName
          lastName
        }
        user {
          _id
          firstName
          lastName
          email
          ssn
          email
          signupDate: createdAt{
            formatted
          }
          firstName
          lastName
        }
      }
    }
  }`);
};
