/** @format */

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'components/ButtonLoader';
import FormField from 'components/Forms/FormField';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import { registerCompanyRequestAction } from 'stateContainer/actions';
import CompanyExistsModal from 'components/Modals/CompanyExistsModal';
import CompanyNotFoundModal from 'components/Modals/CompanyNotFoundModal';
import { companyInfoUtils } from 'views/Registration/CompanyInfo/companyInfoUtils';
import { validationSchema } from 'views/Registration/CompanyInfo/validationSchema';
import ManualRegistrationRequiredModal from 'components/Modals/ManualRegistrationRequiredModal';

export default function CompanyInfo(props) {
  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isChosenCompanyExists,
    hasEverySigneeApproved,
    isContactEmailValidated,
    isPendingApprovalFromSignees,
    isManualRegistrationRequired,
    isPendingContactEmailValidation
  } = useSelector((state) => state.appRouting);
  const sessionState = useSelector((state) => state.session.data);
  const companyState = useSelector((state) => state.company) || {};
  const personState = useSelector((state) => state.person.data) || {};
  const currentUserState = useSelector((state) => state.currentUser) || {};
  const emailVerificationState = useSelector((state) => state.verification);
  const boardMembershipState = useSelector((state) => state.boardMembership);
  const signeeCombinationState = useSelector(
    (state) => state.signeeCombination
  );

  const getCompanyInfo = companyInfoUtils({
    t,
    props,
    personState,
    companyState,
    sessionState,
    currentUserState,
    boardMembershipState,
    emailVerificationState,
    signeeCombinationState
  });

  /**
   * include signee email into signee combination if isSingleSigneeAndCurrentUser
   */

  const getUpdatedSigneeCombination = ({ email }) => {
    if (signeeCombinationState.isSingleSigneeAndCurrentUser) {
      const updatedCombination = getCompanyInfo.listOfSignees.map(
        (combination) => {
          return combination.map((item) => {
            if (item.personalNumber === personState.personalNumber) {
              return (item = { ...item, email: email });
            } else {
              return item;
            }
          });
        }
      );

      return updatedCombination[0];
    } else if (signeeCombinationState.currentUserIsSoleSigneeOfACombination) {
      const updatedCombination = [];

      getCompanyInfo.listOfSignees.map((item) => {
        if (item[0].personalNumber === personState.personalNumber) {
          return updatedCombination.push({ ...item[0], email: email });
        } else return {};
      });

      return updatedCombination[0];
    }
  };

  const onSubmit = (values) => {
    const data = {
      fid: sessionState.fid,
      ssn: sessionState.ssn,
      firstName: values.contactPersonFirstName,
      lastName: values.contactPersonLastName,
      name: `${values.contactPersonFirstName} ${values.contactPersonLastName}`,
      contactPhone: values.contactPersonPhone,
      contactEmail: values.contactPersonEmail,
      organisationId: companyState.data.companyId,
      companyCode: companyState.data.industryCode,
      companyName: companyState.data.companyName,
      companyEmail: companyState.data.email || '',
      phoneNumber: companyState.data.phoneNumber || '',
      website: companyState.data.webAddress || '',
      visitingAddress1: values.visitingAddress1,
      visitingAddress2: values.visitingAddress2,
      visitingCity: values.visitingCity,
      visitingPostCode: values.visitingPostCode,
      visitingAddress: {
        address1: values.visitingAddress1,
        address2: values.visitingAddress2,
        city: values.visitingCity,
        postCode: values.visitingPostCode
      },
      mailingAddress: {
        address1: values.mailingAddress1,
        address2: values.mailingAddress2,
        city: values.mailingCity,
        postCode: values.mailingPostCode
      },
      mailingAddress1: values.mailingAddress1,
      mailingAddress2: values.mailingAddress2,
      mailingCity: values.mailingCity,
      mailingPostCode: values.mailingPostCode,
      isChosenCompanyExists,
      isContactEmailValidated,
      isSingleSigneeAndCurrentUser:
        signeeCombinationState.isSingleSigneeAndCurrentUser,
      currentUserIsSoleSigneeOfACombination:
        signeeCombinationState.currentUserIsSoleSigneeOfACombination,
      isPendingContactEmailValidation,
      successUrl: routeService.register_validate_email,
      failedUrl: routeService.register_choose_company,
      verifyEmailUrl: routeService.register_validate_email,
      redirectUserUrl: routeService.register_redirect_user,
      listOfSignees: getUpdatedSigneeCombination({
        email: values.contactPersonEmail
      })
    };

    dispatch(registerCompanyRequestAction(data));
  };

  return (
    <div className='company-info-view' id='registration-flow'>
      {/* {isChosenCompanyExists &&
        !isPendingContactEmailValidation &&
        isPendingApprovalFromSignees && (
          <Redirect to={routeService.register_redirect_user} />
        )} */}

      {isManualRegistrationRequired && (
        <ManualRegistrationRequiredModal
          {...props}
          successUrl={routeService.register_choose_company}
        />
      )}

      {!currentUserState.requesting &&
        isContactEmailValidated &&
        isChosenCompanyExists &&
        !hasEverySigneeApproved &&
        isPendingApprovalFromSignees && (
          <>
            <CompanyExistsModal
              show={true}
              redirectUrl={routeService.register_choose_company}
            />
          </>
        )}

      {!currentUserState.requesting &&
        isChosenCompanyExists &&
        hasEverySigneeApproved && (
          <>
            <CompanyExistsModal
              show={true}
              redirectUrl={routeService.register_choose_company}
            />
          </>
        )}
      {companyState.error && (
        <>
          <CompanyNotFoundModal
            show={true}
            redirectUrl={routeService.register_choose_company}
          />
        </>
      )}

      {/* {isCompanyExists && isEmailVerified  && (
        <>
          <CompanyExistsModal
            show={true}
            redirectUrl={routeService.register_choose_company}
          />
        </>
      )} */}

      {/* {isChosenCompanyExists &&
        isEmailVerified &&
        isPendingApprovalFromSignees && (
          <Redirect to={routeService.register_redirect_user} />
        )} */}
      <SplitLayout
        flow={props.flow}
        largeLayout
        isRequesting={getCompanyInfo.isRequesting}
        stepsWizard={props.stepsWizard}
        className='postlogin-template'
        backgroundImage='registration-welcome-left-background-image'>
        {!companyState.error && !getCompanyInfo.isRequesting && (
          <div className='text-left personal-info-container'>
            <div className='split-layout-left-heading-container'>
              <h1>{getCompanyInfo.companyNameText}</h1>
            </div>

            <Formik
              initialValues={getCompanyInfo.initialValues}
              validationSchema={validationSchema(t)}
              onSubmit={onSubmit}>
              <Form className='form-group'>
                <div className='content'>
                  <div className='block-container'>
                    <div className='left-block'>
                      <Row>
                        <Col>
                          <FormField
                            name='visitingAddress1'
                            labeltext={tprop.input.visitingAddress[0].label}
                          />
                        </Col>
                      </Row>
                      <Row className='row-reset-height'>
                        <Col>
                          <FormField name='visitingAddress2' />
                        </Col>
                      </Row>
                      <Row className='address2-container'>
                        <Col sm={4}>
                          <FormField
                            sm={1}
                            name='visitingPostCode'
                            labeltext={tprop.input.visitingAddress[2].label}
                          />
                        </Col>
                        <Col sm={8}>
                          <FormField
                            sm={1}
                            name='visitingCity'
                            labeltext={tprop.input.visitingAddress[3].label}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormField
                            name='mailingAddress1'
                            labeltext={tprop.input.mailingAddress[0].label}
                          />
                        </Col>
                      </Row>
                      <Row className='row-reset-height'>
                        <Col>
                          <FormField name='mailingAddress2' />
                        </Col>
                      </Row>

                      <Row className='address2-container'>
                        <Col sm={5}>
                          <FormField
                            sm={1}
                            name='mailingPostCode'
                            labeltext={tprop.input.mailingAddress[2].label}
                          />
                        </Col>
                        <Col sm={7}>
                          <FormField
                            sm={1}
                            name='mailingCity'
                            labeltext={tprop.input.mailingAddress[3].label}
                          />
                        </Col>
                      </Row>
                    </div>
                    <div className='right-block'>
                      <Row>
                        <Col sm={4}>
                          <FormField
                            name='contactPersonFirstName'
                            labeltext={tprop.input.firstName.label}
                          />
                        </Col>
                        <Col className='add-top-margin-14' sm={8}>
                          <FormField
                            name='contactPersonLastName'
                            labeltext={' '}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormField
                            sm={1}
                            name='contactPersonEmail'
                            labeltext={tprop.input.email.label}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormField
                            sm={1}
                            name='contactPersonPhone'
                            labeltext={tprop.input.mobile.label}
                          />
                        </Col>
                      </Row>
                      <div className='split-layout-left-form-button-container'>
                        <div className='text-center'>
                          {getCompanyInfo.isRequesting ? (
                            <ButtonLoader />
                          ) : (
                            <Button
                              className={`${props.btnColor}`}
                              type='submit'>
                              {tprop.buttons.text}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        )}
      </SplitLayout>
    </div>
  );
}
