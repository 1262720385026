/** @format */

import { logoutService } from 'services';
import { push } from 'connected-react-router';
import { persistor } from 'stateContainer/store';
import routeService from 'services/routeService';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

const LOGOUT_LANDING_URL = routeService.common_logging_out;

function* workerSaga({ payload }) {
  try {
    yield call(logoutService, payload);
    yield put(push(LOGOUT_LANDING_URL));
    localStorage.removeItem('persist:root');
    persistor.purge();
    yield put({ type: actionTypes.LOGOUT_SUCCESS });
  } catch (error) {
    yield put({ type: actionTypes.LOGOUT_FAILED, error });
    yield put(push(LOGOUT_LANDING_URL));
  }
}

export function* logoutSaga() {
  yield takeLatest(actionTypes.LOGOUT_REQUESTED, workerSaga);
}
