/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const registerCompanyWithSigneeMutation = (data) => {
  return makeGraphQlRequest(`mutation {
    createCompanyOnAdminUserAndSignee(
      fid: "${data.fid}",
      ssn: "${data.ssn}",
      firstName: "${data.firstName}",
      lastName: "${data.lastName}",
      contactPhone: "${data.contactPhone}",
      contactEmail: "${data.contactEmail}",
      companyName:"${data.companyName}",
      organisationId: "${data.organisationId}",
      companyCode: "${data.companyCode}",
      companyEmail: "${data.companyEmail}",
      phoneNumber: "${data.phoneNumber}"
      website: "${data.website}",
      visitingAddress1: "${data.visitingAddress1}",
      visitingAddress2: "${data.visitingAddress2}",
      visitingCity: "${data.visitingCity}",
      visitingPostCode: "${data.visitingPostCode}",
      mailingAddress1: "${data.mailingAddress1}",
      mailingAddress2: "${data.mailingAddress2}",
      mailingCity: "${data.mailingCity}",
      mailingPostCode: "${data.mailingPostCode}",
      countryCode:"SE"
    )
    {
      _id
    }
  }`);
};
