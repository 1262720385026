/** @format */

import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

export default function ValidationStatus(props) {
  return (
    <div
      className={`rounded-circle validating-email-circle ${
        props.className && props.className
      }`}>
      {props.isEmailVerified ? (
        <div className='image-success'></div>
      ) : (
        <div className='centered-content-bottom'>
          <div className='loading-indicator-container'>
            <PuffLoader size={96} color={'var(--color-black)'} loading />
          </div>
        </div>
      )}
    </div>
  );
}
