/** @format */

import { fetchCompanyDataService } from 'services';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ organisationId }) {
  try {
    /**
     * fetch company-data from roaring
     */

    const response = yield call(fetchCompanyDataService, {
      organisationId
    });

    /**
     * update company data state with info fetched from roaring
     */

    const data =
      (response.data && {
        ...response.data.records[0],
        status: response.data.status
      }) ||
      {};

    /**
     * update approuting state
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: { isFetchCompanyDataComplete: true }
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchCompanyData: 'done' }
    });

    yield put({
      type: actionTypes.COMPANY_DATA_SUCCESS,
      data: { ...data }
    });
  } catch (error) {
    /**
     * throw error when the roaring call fails
     */

    yield put({ type: actionTypes.COMPANY_DATA_FAILED, error: true });
  }
}

export function* fetchCompanyDataSaga() {
  yield takeLatest(actionTypes.COMPANY_DATA_REQUESTED, workerSaga);
}
