/** @format */

import { fetchBoardMembershipService } from 'services';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ ssn }) {
  try {
    /**
     * fetch board-membership-data from roaring
     */

    const response = yield call(fetchBoardMembershipService, {
      ssn
    });

    /**
     * check if current user is part of any boardmemberships
     */

    const isCurrentUserHasBoardMemberships =
      response.data &&
      response.data.engagements &&
      response.data.engagements.length > 0;

    /**
     * update approuting state
     */

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: {
        isFetchBoardMembershipComplete: true,
        isCurrentUserHasBoardMemberships
      }
    });

    /**
     * update board-membership data with info fetched from roaring
     */

    yield put({
      type: actionTypes.FETCH_BOARD_MEMBERSHIP_SUCCESS,
      data: { ...response.data, status: response.data.status },
      isCurrentUserHasBoardMemberships
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { fetchBoardMembership: 'done' }
    });
  } catch (error) {
    console.log('error: ' + error);
    /**
     * throw error when the saga fails
     */

    yield put({ type: actionTypes.FETCH_BOARD_MEMBERSHIP_FAILED, error });
  }
}

export function* fetchBoardMembershipSaga() {
  yield takeLatest(actionTypes.FETCH_BOARD_MEMBERSHIP_REQUESTED, workerSaga);
}
