/** @format */

import * as Yup from 'yup';

export const validationSchema = (t) =>
  Yup.object().shape({
    firstName: Yup.string().required(t(`lang_error:forms.required`)),
    lastName: Yup.string().required(t(`lang_error:forms.required`)),
    email: Yup.string()
      .email(t(`lang_error:forms.formatInvalid`))
      .required(t(`lang_error:forms.required`))
  });

export const validationSchemaForFileUpload = (t) =>
  Yup.object().shape({
    file: Yup.mixed().optional(t(`lang_error:forms.required`))
  });
