/** @format */

import { push } from 'connected-react-router';
import { put, takeLatest, call } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import { sendCompanyRegistrationFullyApprovedService } from 'services';

function* workerSaga({ payload }) {
  const recipientList = [
    {
      email: payload.signeeEmail,
      recipientName: payload.signeeName,
      companyName: payload.companyName,
      adminUserName: payload.adminUserName
    }
  ];

  for (let i = 0; i < payload.listOfSignees.length; i++) {
    if (!payload.isSingleSigneeAndCurrentUser) {
      recipientList.push({
        email: payload.listOfSignees[i].email,
        recipientName: payload.listOfSignees[i].name,
        companyName: payload.companyName
      });
    }
  }

  try {
    for (let j = 0; j < recipientList.length; j++) {
      const response = yield call(sendCompanyRegistrationFullyApprovedService, {
        ...recipientList[j]
      });

      /**
       * update email verification state
       */

      yield put({
        type: actionTypes.SEND_COMPANY_REGISTRATION_FULLY_APPROVED_SUCCESS,
        data: response.data
      });
    }

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { requestSigneeApproval: 'done' }
    });

    yield put(push(payload.successUrl));
  } catch (error) {
    yield put({
      type: actionTypes.COMPANY_REGISTRATION_FULLY_APPROVED_FAILED,
      error
    });
  }
}

export function* triggerCompanyRegistrationFullyApprovedSaga() {
  yield takeLatest(
    actionTypes.SEND_COMPANY_REGISTRATION_FULLY_APPROVED_REQUESTED,
    workerSaga
  );
}
