/** @format */

import lang_route from './lang_route.json';
import lang_error from './lang_error.json';
import lang_common from './lang_common.json';
import lang_dashboard from './lang_dashboard.json';
import lang_registration from './lang_registration.json';
import lang_privacy_policy from './lang_privacy_policy.json';
import lang_terms_and_conditions from './lang_terms_and_conditions.json';

const english = {
  lang_error,
  lang_route,
  lang_common,
  lang_dashboard,
  lang_registration,
  lang_privacy_policy,
  lang_terms_and_conditions
};

export default english;
