/** @format */

import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Formik, Form } from 'formik';
import { routeService } from 'services';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import LinkifyHelper from 'utils/linkifyHelper';
import ButtonLoader from 'components/ButtonLoader';
import FormField from 'components/Forms/FormField';
import { useSelector, useDispatch } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import ValidatingEmailStatus from 'components/ValidatingEmailStatus';
import {
  sendVerificationCodeAction,
  confirmVerificationCodeAction
} from 'stateContainer/actions';

import { getValidationCodeSchema } from 'utils/getValidationCodeSchema';

export default function ValidateEmail(props) {
  const {
    data: { contactEmail, ssn, firstName },
    chosenCompany: { organisationId }
  } = useSelector((state) => state.currentUser);

  const { tprop } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { error, requesting } = useSelector((state) => state.verification);

  const descriptionText = t(
    `lang_${props.flow}:${props.view}.description.text`,
    { email: contactEmail }
  );

  const verificationFailedError = t(
    `lang_error:validateEmail.verificationFailed`
  );
  const technicalError = t(`lang_error:technicalError`);

  const initialValues = {
    verificationCode: ''
  };

  const onSubmit = (values) => {
    const data = {
      ssn: ssn,
      email: contactEmail,
      organisationId,
      verificationCode: values.verificationCode,
      successUrl: routeService.register_validating_email,
      failedUrl: `${routeService.register_validate_email}?validation_failed`
    };
    dispatch(confirmVerificationCodeAction(data));
  };

  const requestResendCode = () => {
    dispatch(
      sendVerificationCodeAction({
        email: contactEmail,
        firstName,
        successUrl: `${routeService.register_validate_email}`
      })
    );
  };

  const isValidationFailed =
    props.location.search.includes('validation_failed');

  return (
    <>
      <LinkifyHelper>
        {requesting ? (
          <div className='validate-email-view'>
            <ValidatingEmailStatus
              className='validate-email-view'
              isEmailVerified={false}
              flow={props.flow}
            />
          </div>
        ) : (
          <div className='validate-email-view' id='registration-flow'>
            <SplitLayout
              flow={props.flow}
              stepsWizard={props.stepsWizard}
              className='postlogin-template'
              backgroundImage='registration-welcome-left-background-image'>
              <span className='validate-email-container'>
                <div className='text-content'>
                  <div className='split-layout-left-heading-container'>
                    <h1>{tprop.heading.text}</h1>
                  </div>
                  <span className='split-layout-left-subheading-container'>
                    <p>{descriptionText}</p>
                  </span>
                </div>{' '}
                <Formik
                  initialValues={initialValues}
                  validationSchema={getValidationCodeSchema(t)}
                  onSubmit={onSubmit}>
                  {({ isValid, dirty }) => (
                    <Form className='form-group validate-email-form'>
                      <div className='content'>
                        <Row>
                          <Col>
                            <FormField
                              name='verificationCode'
                              className='form-control validate-email-form-control'
                              labeltext={
                                t(`lang_${props.flow}:validate_email.input`)[0]
                                  .label
                              }
                              errorstyle='validate-email-form-error'
                              labelstyle='validate-email-form-label'
                              maxLength='5'
                              autoComplete='false'
                            />
                          </Col>
                        </Row>
                        {requesting ? (
                          <ButtonLoader className='resend-validation-button-loader' />
                        ) : (
                          <div className='resend-code-button-container'>
                            <Button
                              type='button'
                              className='resend-code-button'
                              onClick={() => requestResendCode()}>
                              <small className='font-weight-bold'>
                                {tprop.verification.description.map(
                                  (item, key) =>
                                    item.props && item.props.underline ? (
                                      <u key={key}> {item.text}</u>
                                    ) : (
                                      <span key={key}> {item.text}</span>
                                    )
                                )}
                              </small>
                            </Button>
                          </div>
                        )}
                      </div>
                      <div className='validation-error-container'>
                        {isValidationFailed && (
                          <div className='validation-error'>
                            <small>
                              {verificationFailedError.text}{' '}
                              <span
                                className='resend-link'
                                onClick={() => requestResendCode()}>
                                <u>{verificationFailedError.button.text}</u>
                              </span>
                            </small>
                          </div>
                        )}
                      </div>

                      {error && !isValidationFailed && (
                        <div className='validation-error'>
                          <small>{technicalError.text}</small>
                        </div>
                      )}

                      <div className='validate-email-button'>
                        <Button
                          type='submit'
                          className={props.btnColor}
                          disabled={!isValid || !dirty}>
                          {tprop.buttons.text}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </span>
            </SplitLayout>
          </div>
        )}
      </LinkifyHelper>
    </>
  );
}
