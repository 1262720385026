/** @format */

/********************
 **  Root-Reducer  **
 ********************/

import { combineReducers } from 'redux';
import { history } from 'stateContainer/history';
import { connectRouter } from 'connected-react-router';
import * as actionTypes from 'stateContainer/action-types';
import { loginReducer } from 'stateContainer/reducers/login-reducer';
import { personDataReducer } from 'stateContainer/reducers/person-data-reducer';
import { companyDataReducer } from 'stateContainer/reducers/company-data-reducer';
import { currentUserReducer } from 'stateContainer/reducers/current-user-reducer';
import { appRoutingReducer } from 'stateContainer/reducers/app-routing-reducer.js';
import { signeeCombinationReducer } from 'stateContainer/reducers/signee-combination-saga';
import { boardMembershipReducer } from 'stateContainer/reducers/board-membership-reducer';
import { verificationCodeReducer } from 'stateContainer/reducers/verification-code-reducer.js';

const appReducer = combineReducers({
  appRouting: appRoutingReducer,
  boardMembership: boardMembershipReducer,
  company: companyDataReducer,
  currentUser: currentUserReducer,
  person: personDataReducer,
  router: connectRouter(history),
  session: loginReducer,
  signeeCombination: signeeCombinationReducer,
  verification: verificationCodeReducer
});

const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_SUCCESS) {
    return (state = {
      router: state.router
    });
  }
  return appReducer(state, action);
};

export default rootReducer;
