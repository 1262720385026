/** @format */

import config from 'config';
import { invokeRestApi } from 'utils/invokeRestApi';

const {
  services: {
    FETCH_PERSON_DATA_SERVICE_PATHNAME,
    FETCH_SIGN_RIGHTS_SERVICE_PATHNAME,
    FETCH_COMPANY_DATA_SERVICE_PATHNAME,
    FETCH_SIGNEE_COMBINATION_SERVICE_PATHNAME,
    FETCH_BOARD_MEMBERSHIP_SERVICE_PATHNAME
  }
} = config.app;

export async function fetchPersonDataService({ ssn }) {
  return await invokeRestApi({
    service: FETCH_PERSON_DATA_SERVICE_PATHNAME,
    payload: { ssn }
  });
}

export async function fetchSignRightsService({ ssn, organisationId }) {
  return await invokeRestApi({
    service: FETCH_SIGN_RIGHTS_SERVICE_PATHNAME,
    payload: { ssn, organisationId }
  });
}

export async function fetchCompanyDataService({ organisationId }) {
  return await invokeRestApi({
    service: FETCH_COMPANY_DATA_SERVICE_PATHNAME,
    payload: { organisationId }
  });
}

export async function fetchSigneeCombinationService({ organisationId }) {
  return await invokeRestApi({
    service: FETCH_SIGNEE_COMBINATION_SERVICE_PATHNAME,
    payload: { organisationId }
  });
}

export async function fetchBoardMembershipService({ ssn }) {
  return await invokeRestApi({
    service: FETCH_BOARD_MEMBERSHIP_SERVICE_PATHNAME,
    payload: { ssn }
  });
}

export default {
  fetchPersonDataService,
  fetchSignRightsService,
  fetchCompanyDataService,
  fetchBoardMembershipService,
  fetchSigneeCombinationService
};
