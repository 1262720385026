/** @format */

import { routeService } from 'services';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import { withRouter, useLocation } from 'react-router-dom';

export default withRouter(function RedirectUser(props) {
  const { search } = useLocation();

  const {
    isSingleSignee,
    isCurrentUserOneOfSignee,
    isSingleSigneeAndCurrentUser,
    currentUserIsSoleSigneeOfACombination
  } = useSelector((state) => state.signeeCombination);

  const getDestinationUrl = () => {
    // current user is the sole signee so take them through the regular registration flow
    if (isSingleSigneeAndCurrentUser) {
      return `${routeService.register_agree_to_terms}${search}`;
      // has a single but not the current user
    } else if (isSingleSignee && !isCurrentUserOneOfSignee) {
      return `${routeService.register_choose_signee_combination}${search}`;
      // has multiple signees or combinations and current user is one of them
    } else if (!isSingleSignee && isCurrentUserOneOfSignee) {
      // current user is the sole signee in a combination
      if (currentUserIsSoleSigneeOfACombination) {
        return `${routeService.register_agree_to_terms}${search}`;
      } else {
        return `${routeService.register_choose_signee_combination}${search}`;
      }

      //requires multiple signees and current user is not one of them
    } else if (!isSingleSignee && !isCurrentUserOneOfSignee) {
      return `${routeService.register_choose_signee_combination}${search}`;
    }
    // default route
    return `${routeService.register_agree_to_terms}${search}`;
  };

  useEffect(() => {
    const that = props;
    const destinationUrl = getDestinationUrl();
    setTimeout(() => {
      that.history.push(destinationUrl);
    }, 500); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='redirect-user-view' id='registration-flow'>
      <SplitLayout
        flow={props.flow}
        largeLayout
        isRequesting={true}
        usePageLoader={true}
        stepsWizard={props.stepsWizard}
        className='postlogin-template'
      />
    </div>
  );
});
