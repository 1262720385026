/** @format */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';
import { logoutRequestAction } from 'stateContainer/actions';

export default function SessionExpiredModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);

  const toggleCloseModal = () => {
    dispatch(logoutRequestAction());
    setShowModal(false);
  };

  const headingText = t(`lang_common:session_expiry.expired.heading.text`);

  const descriptionText = t(
    `lang_common:session_expiry.expired.description.text`
  );

  const buttonText = t(`lang_common:session_expiry.expired.buttons.text`);

  return (
    <>
      <div>
        <Modal
          className='message-modal session-timeout-modal-container'
          show={showModal}
          onHide={toggleCloseModal}>
          <Modal.Header>
            <Modal.Title className='message-modal-header'>
              {headingText}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='modal-description'>{descriptionText}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              className='btn-pink btn btn-primary'
              onClick={() => toggleCloseModal()}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
