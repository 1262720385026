/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LinkifyHelper from 'utils/linkifyHelper';
import MembershipLogos from 'components/Layout/Footer/MembershipLogos';

export default function Footer() {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  const legal = t(`lang_footer:legal`);
  const ttalogo = t(`lang_footer:ttalogo`);
  const aboutUs = t(`lang_footer:about_us`);
  const contact = t(`lang_footer:contact`);
  const membership = t(`lang_footer:membership`);
  const appStoreUrl = t(`lang_footer:app.appstore.url`);
  const playStoreUrl = t(`lang_footer:app.playstore.url`);
  const copyright = t(`lang_footer:copyright`, { year: year });

  return (
    <footer className='footer'>
      <div className='footer-inner'>
        <LinkifyHelper>
          <div className='footer-top-container'>
            <div className='footer-main-logo-content'>
              <Link to={ttalogo.url}>
                <div className='logo-the-third-act-white' />
              </Link>
            </div>
            <div className='footer-main-text-content'>
              <div className='footer-main-text-content-left'>
                <ul className='footer-link-container'>
                  <li>
                    <h1>{aboutUs.heading.text}</h1>
                  </li>
                  {aboutUs.items.map((item, key) => (
                    <Link
                      key={key}
                      to={{ pathname: item.url }}
                      target='_blank'
                      rel='noopener noreferrer'>
                      <li>{item.text}</li>
                    </Link>
                  ))}
                </ul>
              </div>
              <div className='footer-main-text-content-middle'>
                <ul className='footer-link-container'>
                  <li>
                    <h1>{legal.heading.text}</h1>
                  </li>
                  {legal.items.map((item, key) => (
                    <Link
                      key={key}
                      to={{ pathname: item.url }}
                      target='_blank'
                      rel='noopener noreferrer'>
                      <li>{item.text}</li>
                    </Link>
                  ))}
                </ul>
              </div>
              <div className='footer-main-text-content-right'>
                <ul className='footer-link-container'>
                  <li>
                    <h1>{contact.heading.text}</h1>
                  </li>
                  {contact.items.map((item, key) => (
                    <li key={key}>{item.text}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className='footer-membership-content'>
              <div className='footer-membership-text'>
                <ul className='footer-link-container'>
                  <li>
                    <h1>{membership.heading.text}</h1>
                  </li>
                  <li>{membership.description.text}</li>
                </ul>
              </div>
              <div className='membership-logo-container'>
                <MembershipLogos membership={membership} />
              </div>
            </div>
          </div>

          <div className='footer-center-container'>
            <div className='membership-logo-container'>
              <MembershipLogos membership={membership} />
            </div>
            <div className='footer-mobile-app-icons-container'>
              <Link
                to={{ pathname: appStoreUrl }}
                target='_blank'
                rel='noopener noreferrer'>
                <div className='logo-appstore-black'></div>
              </Link>
              <Link
                to={{ pathname: playStoreUrl }}
                target='_blank'
                rel='noopener noreferrer'>
                <div className='logo-playstore-black'></div>
              </Link>
            </div>
          </div>
        </LinkifyHelper>
      </div>
      <div className='footer-bottom-outer-wrapper'>
        <div className='footer-bottom-container'>
          <div className='footer-copyright-top-container'>
            <ul>
              {copyright.top.map((item, key) => (
                <li key={key}>{item.text}</li>
              ))}
            </ul>
          </div>
          <div className='footer-copyright-bottom-container'>
            <ul>
              {copyright.bottom.map((item, key) => (
                <li key={key}>{item.text}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
