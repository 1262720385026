/** @format */

import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

export default function CenteredMessageLayout(props) {
  return (
    <div className='logout-container centered-container centered-container-default-background-image'>
      <div
        className={`centered-content ${
          props.className ? props.className : 'centered-content-theme-white'
        }`}>
        <div className='centered-content-top'>
          <div className='logo-the-third-act-white' />
          {props.description && <h1>{props.description}</h1>}
        </div>
        <div className='centered-content-bottom'>
          <div className='loading-indicator-container'>
            {!props.error && (
              <PuffLoader size={96} color={'var(--color-pink)'} loading />
            )}
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}
