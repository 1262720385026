/** @format */

import React from 'react';

export default function TableBody({ tableData, columns, onClick }) {
  return (
    <>
      <tbody>
        {tableData.map((data, key) => {
          return (
            <tr
              key={key}
              onClick={() => onClick({ ...data, editMode: true })}
              className='table-row-button'>
              {columns.map(({ accessor }) => {
                const tData = data[accessor] ? data[accessor] : '-';

                return <td key={accessor}>{tData}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
}
