/** @format */

import { mutations } from 'services';
import { put, call, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';
import {
  manipulateEmployeesYetToSignup,
  manipulateSignedUpEmployees
} from 'utils/sagaUtils';

function* workerSaga({ payload }) {
  try {
    const response = yield call(mutations.deleteEmployeeMutation, payload.data);

    /**
     * process the updated list of employees and make them table friendly
     */

    let employeesList = [];

    const { company, organisationId } = response.removeEmployeeFromCompany;

    const manipulatedListOfEmployeesYetToSignup =
      manipulateEmployeesYetToSignup(company.employee, organisationId);

    const manipulatedListOfSignedUpEmployees = manipulateSignedUpEmployees(
      company.user
    );

    employeesList = [
      ...manipulatedListOfSignedUpEmployees,
      ...manipulatedListOfEmployeesYetToSignup
    ];

    yield put({
      type: actionTypes.DELETE_EMPLOYEE_SUCCESS,
      payload: employeesList
    });

    /**
     * update appRouting state with registration progress
     */

    yield put({
      type: actionTypes.__APP_ROUTING_CHECKPOINT_LOGGED,
      payload: { deleteEmployee: 'done' }
    });
  } catch (error) {
    yield put({ type: actionTypes.DELETE_EMPLOYEE_FAILED, error });
  }
}

export function* deleteEmployeeSaga() {
  yield takeLatest(actionTypes.DELETE_EMPLOYEE_REQUESTED, workerSaga);
}
