/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

export const isUserEmailVerifiedQuery = (data) => {
  return makeGraphQlRequest(`
    {
      User(email: "${data.email}", ssn: "${data.ssn}") {
        email
        validationCode {
          isEmailVerified:isVerified
        }
      }
    }    
    `);
};
