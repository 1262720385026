/** @format */

import { queries } from 'services';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from 'stateContainer/action-types';

function* workerSaga({ payload }) {
  try {
    let existingRecords = {};

    /**
     * run through the existing registration records against the manually input organisationId
     */

    const response = yield call(
      queries.fetchCurrentRegistrationStateOfChosenCompanyQuery,
      {
        organisationId: payload.organisationId,
        ssn: payload.ssn
      }
    );

    /**
     * check if all signees have approved registration of a company
     */

    const companySignee =
      (response.Company && response.Company[0] && response.Company[0].signee) ||
      [];

    const hasEverySigneeApproved =
      (companySignee.length > 0 &&
        companySignee.every((signee) => signee.hasApproved === true)) ||
      false;

    const isPendingApprovalFromSignees =
      !hasEverySigneeApproved && companySignee.length > 0;

    const companyData =
      (response.Company &&
        response.Company.length > 0 &&
        response.Company[0]) ||
      {};

    const adminUserData =
      (companyData.adminUser &&
        companyData.adminUser.length > 0 &&
        companyData.adminUser[0]) ||
      {};

    const currentUserIsAdminUser = adminUserData.ssn === payload.ssn;

    const isValidAdminData =
      (companyData.isValidAdmin &&
        companyData.isValidAdmin.length > 0 &&
        companyData.isValidAdmin[0]) ||
      {};

    existingRecords = {
      ...existingRecords,
      company: companyData,
      adminUser: adminUserData,
      isValidAdmin: isValidAdminData
    };

    const chosenCompany = {
      ...companyData,
      ssn: payload.ssn,
      adminUserName: adminUserData.name,
      adminUser: adminUserData,
      listOfSignees: companyData.signee
    };

    const isChosenCompanyExists =
      companyData.organisationId && companyData.organisationId.length > 0;

    const isContactEmailValidated = companyData.isContactEmailValidated;

    const isPendingContactEmailValidation =
      isChosenCompanyExists && !isContactEmailValidated;

    yield put({
      type: actionTypes.__APP_ROUTING_DATA_SUCCESS,
      payload: {
        isChosenCompanyExists: isChosenCompanyExists,
        hasEverySigneeApproved: hasEverySigneeApproved,
        isPendingApprovalFromSignees: isPendingApprovalFromSignees,
        isContactEmailValidated: isContactEmailValidated,
        isPendingContactEmailValidation: isPendingContactEmailValidation,
        currentUserIsAdminUser: currentUserIsAdminUser,
        caller: 'fetch-current-registration-state-saga'
      }
    });

    /**
     * update store with status
     */

    yield put({
      type: actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_SUCCESS,
      existingRecords: existingRecords,
      chosenCompany: chosenCompany
    });
  } catch (error) {
    /**
     * throw error when the saga fails
     */
    yield put({
      type: actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_FAILED
    });
  }
}
export function* fetchCurrentRegistrationStateOfChosenCompanySaga() {
  yield takeLatest(
    actionTypes.FETCH_CURRENT_REGISTRATION_STATE_OF_CHOSENCOMPANY_REQUESTED,
    workerSaga
  );
}
