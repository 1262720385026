/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import SplitLayout from 'components/Layout/SplitLayout';
import { fetchCorporateApprovalDataAction } from 'stateContainer/actions';

export default function Start(props) {
  const { flow, tprop, btnColor } = props;

  const dispatch = useDispatch();
  const search = window.location.search;
  if (search.includes('cid') && search.includes('uid')) {
    const queryString = search;
    const urlParams = new URLSearchParams(queryString);
    const organisationId = urlParams.get('cid');
    const uuid = urlParams.get('uid');

    dispatch(fetchCorporateApprovalDataAction({ organisationId, uuid }));
  }

  return (
    <>
      <SplitLayout
        flow={flow}
        hidePreviousButton={true}
        hideMobileHeader={true}
        hideLogoutButton={true}
        className='prelogin-template'>
        <Link to={'/'}>
          <div className='logo-the-third-act' />
        </Link>

        {tprop.heading && (
          <h1 className='start-page-heading'>{tprop.heading.text}</h1>
        )}

        <div className='logo-bankid' />

        {tprop.buttons && (
          <div className='button-container'>
            {tprop.buttons.map((button, key) => (
              <div key={key}>
                <Link to={button.url}>
                  <Button className={`btn-primary-transparent ${btnColor}`}>
                    {button.text}{' '}
                    <span className='text-capitalize'>{button.subText}</span>
                  </Button>
                </Link>
              </div>
            ))}
          </div>
        )}
      </SplitLayout>
    </>
  );
}
