/** @format */

import * as Yup from 'yup';

export const validationSchema = (t) =>
  Yup.object({
    visitingAddress1: Yup.string().optional(t(`lang_error:forms.required`)),
    visitingAddress2: Yup.string().optional(),
    visitingPostCode: Yup.string().optional(t(`lang_error:forms.required`)),
    visitingCity: Yup.string().optional(t(`lang_error:forms.required`)),
    mailingAddress1: Yup.string().required(t(`lang_error:forms.required`)),
    mailingAddress2: Yup.string().optional(),
    mailingPostCode: Yup.string().required(t(`lang_error:forms.required`)),
    mailingCity: Yup.string().required(t(`lang_error:forms.required`)),
    contactPersonFirstName: Yup.string().required(
      t(`lang_error:forms.required`)
    ),
    contactPersonLastName: Yup.string().required(
      t(`lang_error:forms.required`)
    ),
    contactPersonEmail: Yup.string()
      .email(t(`lang_error:forms.formatInvalid`))
      .required(t(`lang_error:forms.required`)),
    contactPersonPhone: Yup.number()
      .typeError(t('lang_error:forms.mustBeNumeric'))
      .required(t(`lang_error:forms.required`))
  });
