/** @format */

import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';

export default function ButtonLoader(props) {
  return (
    <div className={`button-loader ${props.className && props.className}`}>
      <PuffLoader
        color={'var(--color-pink)'}
        loading
        className='button-loader'
      />
    </div>
  );
}
