/** @format */

import config from 'config';
import { isMobile } from 'react-device-detect';

const {
  scope,
  client_id,
  same_device,
  redirect_uri,
  response_type,
  another_device,
  force_same_device
} = config.app.criipto.settings;

const { authorize } = config.app.criipto.endpoints;

// choose to trigger an eid service in same device / another device
const getAcrValues = (samedevice) => {
  let acr_values = another_device;
  if (samedevice) {
    acr_values = JSON.parse(samedevice) ? same_device : another_device;
  } else if (force_same_device || isMobile) {
    acr_values = same_device;
  }

  return acr_values;
};

const getCriiptoAuthorizeUri = ({ samedevice }) => {
  const acr_values = getAcrValues(samedevice);
  const authorizeApiUri = `${authorize}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&acr_values=${acr_values}&scope=${scope}&ui_locales=sv`;

  return authorizeApiUri;
};

export default getCriiptoAuthorizeUri;
