/** @format */

export const requestSigneeApprovalTemplate = ({
  url,
  ssn,
  signeeName,
  signeeEmail,
  companyName,
  requestersName,
  organisationId
}) => {
  return {
    template: 'sign_right_approval',
    source: 'NO_REPLY',
    isCorporateWeb: true,
    templateData: {
      destination: signeeEmail,
      dataFields: {
        ssn,
        url: url,
        signeeName,
        signeeEmail,
        companyName,
        requestersName,
        organisationId
      }
    }
  };
};
