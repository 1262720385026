/** @format */

import React from 'react';

export default function SummaryBox(props) {
  const { iteration, companyData, labelData } = props;

  return (
    <>
      <div className='summary-box'>
        <div className='summary-box-subheading'>{labelData.address.label}</div>
        <div className='summary-box-address font-weight-bold'>
          <div>{companyData.address.address1}</div>
          {companyData.address.address2 &&
            companyData.address.address2.length > 0 && (
              <div>{companyData.address.address2}</div>
            )}
        </div>
        <div className='summary-box-locality-block'>
          <div className='summary-box-locality-block-postcode'>
            <div className='summary-box-subheading'>
              {labelData.postalCode.label}{' '}
            </div>
            <div className='summary-box-content font-weight-bold'>
              {companyData.postalCode.value}
            </div>
          </div>
          <div className='summary-box-locality-block-city'>
            <div className='summary-box-subheading'>{labelData.city.label}</div>
            <div className='summary-box-content font-weight-bold'>
              {companyData.city.value}
            </div>
          </div>
        </div>
      </div>
      {iteration === 0 && (
        <svg className='horizontal-line-long'>
          <line
            className='horizontal-seperator-line horizontal-seperator-line-pink'
            x1='0'
            y1='10'
            x2='350'
            y2='10'></line>
        </svg>
      )}
    </>
  );
}
