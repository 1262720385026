/** @format */

import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import LogoutButton from 'components/LogoutButton';
import NavDropdown from 'react-bootstrap/NavDropdown';
import NavigationLinks from 'components/Layout/Header/NavigationLinks';

export default function RegularHeaderMenu(props) {
  const [show, setShow] = React.useState(false);

  return (
    <div className='header-right-right-block'>
      <div
        className='menu-switch'
        data-target='#basic-nav-dropdown'
        data-toggle='hamburger-dropdown show'
        onClick={() => setShow(!show)}>
        <div className='hamburger-menu'></div>
      </div>

      <Navbar bg='none'>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='me-auto'>
            <div
              aria-expanded='true'
              className={`hamburger-dropdown  dropdown nav-item ${
                show ? 'show' : ''
              }`}>
              <Link
                aria-haspopup='true'
                aria-expanded={show ? 'true' : 'false'}
                id='basic-nav-dropdown'
                to='#'
                className='dropdown-toggle nav-link'
                role='button'></Link>
              <div
                aria-labelledby='basic-nav-dropdown'
                className={`dropdown-menu ${show ? 'show' : ''}`}>
                <div className='menu-wrapper'>
                  <div className='menu-left-container '>
                    <NavigationLinks />
                  </div>
                  <div className='menu-right-container'>
                    <div className='menu-heading-container'></div>
                    <NavDropdown.Item href='#' className='menu-button'>
                      <div className='icon-logout'></div>
                      <LogoutButton
                        flow={props.flow}
                        className='button-logout'
                      />
                    </NavDropdown.Item>
                  </div>
                </div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
