/** @format */

export default {
  /**
   * application flows
   */
  flows: {
    registration: 'registration',
    common: 'common',
    dashboard: 'dashboard'
  },
  /**
   * flowOrigins to identify the source ['flow'] aka from where a flow originated
   */
  flowOrigins: {
    registration: 'registration',
    dashboard: 'dashboard'
  },
  /**
   * views represent every single page in the application excluding modals
   */
  views: {
    start: 'start',
    login_mode: 'login_mode',
    login: 'login',
    bankid_redirect: 'bankid_redirect',
    logging_in: 'logging_in',
    logging_out: 'logging_out',
    choose_company: 'choose_company',
    company_info: 'company_info',
    validate_email: 'validate_email',
    validating_email: 'validating_email',
    choose_signee_combination: 'choose_signee_combination',
    request_signee_approval: 'request_signee_approval',
    signee_approval_request_sent: 'signee_approval_request_sent',
    review_company_info: 'review_company_info',
    agree_to_terms: 'agree_to_terms',
    terms_and_conditions: 'terms_and_conditions',
    privacy_policy: 'privacy_policy',
    mode_of_payment: 'mode_of_payment',
    summary: 'summary',
    registration_complete: 'registration_complete',
    page_not_found: 'page_not_found',
    how_we_choose_funds: 'how_we_choose_funds',
    cookies: 'cookies',
    sustainability_policy: 'sustainability_policy',
    how_we_calculate: 'how_we_calculate',
    homepage: 'homepage',
    redirect_user: 'redirect_user'
  },
  colors: {
    // this object would represent the classNames of colors instead of the actual color
    blue: 'btn-blue',
    pink: 'btn-pink'
  }
};
