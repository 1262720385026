/** @format */

import React from 'react';
import { routeService } from 'services';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PublicRoute from 'components/RoutedComponent/PublicRoute';
import PrivateRoute from 'components/RoutedComponent/PrivateRoute';

const RoutedComponent = (props) => {
  const { t } = useTranslation();
  const { flow, view, path, isPrivateRoute, Component } = props;
  const RouteType = !isPrivateRoute ? PublicRoute : PrivateRoute;

  /** scroll window o the top for all views  */
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  if (!path) {
    return (
      <Redirect
        to={`${routeService.register_start}?error=path-given-in-routes-is-unreachable`}
      />
    );
  }
  return (
    <RouteType {...props}>
      <Component {...props} tprop={t(`lang_${flow}:${view}`)} />
    </RouteType>
  );
};

const areEqual = (prevProps, nextProps) => {
  return nextProps === prevProps;
};

export default React.memo(RoutedComponent, areEqual);
