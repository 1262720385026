/** @format */

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

export default function LogoutConfirmationModal(props) {
  const { t } = useTranslation();

  const toggleCloseModal = (triggerLogoutAction) => {
    triggerLogoutAction === true && props.handleLogout();
    props.setShowModal(false);
  };

  const logoutModalText = t('lang_common:logout.modal');

  return (
    <>
      <Modal
        className='message-modal logout-message-modal'
        show={props.showModal}
        onHide={toggleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className='message-modal-header'>
            {logoutModalText.header.text}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> {logoutModalText.description.text}</Modal.Body>
        <Modal.Footer>
          {logoutModalText.buttons.map((button, key) => (
            <Button
              variant='secondary'
              className='btn-pink btn btn-primary'
              onClick={() => toggleCloseModal(button.triggerLogoutAction)}
              key={key}>
              {button.text}
            </Button>
          ))}
        </Modal.Footer>
      </Modal>
    </>
  );
}
