/** @format */

import config from 'config';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import CheckBox from 'components/Forms/CheckBox';
import { useDispatch, useSelector } from 'react-redux';
import SplitLayout from 'components/Layout/SplitLayout';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { isTermsAndPolicyAgreedAction } from 'stateContainer/actions';
import { agreeToTermsUtils } from 'views/Common/AgreeToTerms/agreeToTermsUtils';
import { routeService } from 'services';

export default function AgreeToTerms(props) {
  const { t } = useTranslation();
  const { tprop } = props;
  const dispatch = useDispatch();
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [isPolicyAgreed, setIsPolicyAgreed] = useState(false);

  const signeeCombinationState = useSelector(
    (state) => state.signeeCombination
  );
  const companyState = useSelector((state) => state.company) || {};
  const personState = useSelector((state) => state.person.data) || {};
  const currentUserState = useSelector((state) => state.currentUser) || {};
  const boardMembershipState = useSelector((state) => state.boardMembership);

  const getAgreeToTermsData = agreeToTermsUtils({
    t,
    props,
    personState,
    companyState,
    currentUserState,
    boardMembershipState,
    signeeCombinationState
  });

  const buttonUrl =
    props.flowOrigin === config.constants.flows.registration
      ? routeService.register_mode_of_payment
      : routeService.dashboard_mode_of_payment;

  const PageContent = () => (
    <>
      <div className='content'>
        <div className='text-content'>
          <div className='split-layout-left-heading-container'>
            <h1>{tprop.heading.text}</h1>
          </div>
          <span className='split-layout-left-subheading-container'>
            <p>{tprop.description.text}</p>
          </span>
        </div>

        <div className='terms-checkbox'>
          <CheckBox
            name='termsAndConditions'
            value='isTermsAgreed'
            checked={isTermsAgreed}
            onChange={() => setIsTermsAgreed(!isTermsAgreed)}
            url={tprop.input[0].url}
            label={tprop.input[0].text}
          />
        </div>

        <div className='terms-checkbox'>
          <CheckBox
            name='privacyPolicy'
            value='isPolicyAgreed'
            checked={isPolicyAgreed}
            onChange={() => setIsPolicyAgreed(!isPolicyAgreed)}
            url={tprop.input[1].url}
            label={tprop.input[1].text}
          />
        </div>
      </div>
      <div className='agree-terms-button-container'>
        <div className='summary-submit-button-container'>
          {tprop.buttons.map((button, key) => (
            <Link key={key} to={() => buttonUrl}>
              <Button
                className={`btn-primary-transparent ${props.btnColor}`}
                disabled={!isTermsAgreed || !isPolicyAgreed}
                onClick={() => onSubmit()}>
                {button.text}
              </Button>
            </Link>
          ))}
        </div>
      </div>
    </>
  );

  const onSubmit = () => {
    return dispatch(
      isTermsAndPolicyAgreedAction({ isTermsAgreed, isPolicyAgreed })
    );
  };

  return (
    <div className='agree-terms-view'>
      {/* {!currentUserState.email &&
        !emailVerificationState.isEmailVerified &&
        !currentUserState.corporateApprovalData && (
          <Redirect to={routeService.register_choose_company} />
        )} */}

      {props.flow === config.constants.flows.dashboard ? (
        <div id='dashboard-flow'>
          <DashboardLayout
            {...props}
            isRequesting={currentUserState.requesting}
            hidePreviousButton={true}
            goBackUrl={routeService.onboarding_welcome_to_onboarding}>
            <PageContent />
          </DashboardLayout>
        </div>
      ) : (
        <div id='registration-flow'>
          <SplitLayout
            flow={props.flow}
            isRequesting={getAgreeToTermsData.isRequesting}
            stepsWizard={props.stepsWizard}
            className='postlogin-template agree-terms-container'
            backgroundImage='registration-welcome-left-background-image'>
            <PageContent />
          </SplitLayout>
        </div>
      )}
    </div>
  );
}
