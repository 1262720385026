/** @format */

export const sendCompanyRegistrationFullyApprovedTemplate = ({
  email,
  adminUserName,
  recipientName,
  companyName
}) => {
  return {
    template: 'aggreement_approval_performed_for_company',
    source: 'NO_REPLY',
    isCorporateWeb: true,
    templateData: {
      destination: `${email}`,
      dataFields: {
        url: window.location.origin,
        companyName: `${companyName}`,
        recipientName: `${recipientName}`,
        adminUserName: adminUserName
      }
    }
  };
};
