/** @format */

import { Storage } from 'aws-amplify';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { useTranslation } from 'react-i18next';
import FormField from 'components/Forms/FormField';
import ToastMessage from 'components/ToastMessage';
import ButtonLoader from 'components/ButtonLoader';
import { getNumericValue } from 'utils/geNumericValue';
import { useSelector, useDispatch } from 'react-redux';
import RowItemBlock from 'components/Layout/RowItemBlock';
import LineItemBlock from 'components/Layout/LineItemBlock';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import {
  validationSchema,
  validationSchemaForFileUpload
} from 'views/Dashboard/HomePage/AddEmployee/validationSchema';
import {
  createEmployeeAction,
  deleteEmployeeAction,
  createEmployeesInBulkAction
} from 'stateContainer/actions';

export default function AddEmployee(props) {
  const { tprop, tabkey } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [getCsvFile, setCsvFile] = useState(null);
  const [showSaveSuccess, setShowSaveSuccess] = useState(false);
  const [getSocialSecurityNumber, setSocialSecurityNumber] = useState(
    props.chosenEmployeeData.ssn || ''
  );

  const currentUserState = useSelector((state) => state.currentUser);

  const {
    existingRecords: {
      company: { organisationId }
    }
  } = currentUserState;

  const initialValues = {
    firstName: props.chosenEmployeeData.firstName || '',
    lastName: props.chosenEmployeeData.lastName || '',
    email: props.chosenEmployeeData.email || '',
    ssn: getSocialSecurityNumber,
    cancelAccount: false,
    file: ''
  };

  /**
   * close the add-employee prompt
   * @param {object {buttonType}} values
   * @returns triggers close of add employee window or returns false
   */

  const closeAddEmployee = ({ buttonType }) => {
    if (buttonType === 'button') {
      props.setShowAddEmployee(false);
    }
  };

  /**
   * handles form submission for adding single employee
   * @param object values
   * @returns fires redux actions to save the form data
   */

  const booleanText = tprop.tabs[1].table.booleanText;

  const onSubmit = (values) => {
    const randomNumber = Math.floor(Math.random() * -100000 * (99999 - 1) + 1);
    const data = {
      _id: props.chosenEmployeeData._id || randomNumber,
      ssn: getSocialSecurityNumber,
      identity: getSocialSecurityNumber,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      organisationId: organisationId,
      signupDate: '-',
      fullName: `${values.firstName} ${values.lastName}`,
      hasSignedUp: booleanText.no,
      isSignedUpEmployee: false,
      wasManipulated: true
    };

    if (values.cancelAccount === true) {
      /**
       * remove deleted item from array
       */

      const getUpdatedList = () => {
        return (
          props.listOfEmployees &&
          props.listOfEmployees.filter((item) => item.ssn !== values.ssn)
        );
      };
      const updatedList = getUpdatedList();

      dispatch(deleteEmployeeAction({ data, updatedList }));
      setShowSaveSuccess(true);
    } else {
      dispatch(createEmployeeAction(data));
      setShowSaveSuccess(true);
    }
  };

  /**
   * handles form submission for uploading list of employees using csv
   * @param object values
   * @returns fires redux actions to save the csv file and create multiple users
   *
   * CSV format requires the following headers along with data
   * firstName,lastName,email,identity
   */

  const onSubmitFile = () => {
    /**
     * create unique filename
     */
    const timestamp = Math.floor(Date.now());
    const fileName = `${timestamp}__${getCsvFile.name}`;

    /**
     * upload the csv to users private folder in s3 bucket and get presigned url
     */

    const params = {
      level: 'private',
      contentType: 'text/csv'
    };

    Storage.put(`uploads/${fileName}`, getCsvFile, params).then((result) => {
      Storage.get(result.key, params).then((url) => {
        const data = {
          url: url,
          params: params,
          key: result.key,
          organisationId: organisationId
        };
        dispatch(createEmployeesInBulkAction(data));
      });
    });
  };

  const inputTextArray = tprop.tabs[tabkey].add_employee_prompt.input;

  const showDeleteEmployeeTickBox =
    Object.entries(props.chosenEmployeeData).length > 0;

  const saveSuccessMessage =
    tprop.tabs[tabkey].add_employee_prompt.notifications.saveSuccess;

  const bulkUploadText = tprop.tabs[1].add_employee_prompt.bulk_upload;

  return (
    <>
      <div className='homepage-view'>
        <ToastMessage
          show={showSaveSuccess && currentUserState.requesting === true}
          setShow={setShowSaveSuccess}
          heading={saveSuccessMessage.heading.text}
          description={saveSuccessMessage.description.text}
          delay={3000}
          autohide={currentUserState.requesting !== true}
        />
        <div className='add-single-employee-container'>
          <div className='add-employee-prompt'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema(t)}>
              {() => (
                <Form className='form-group'>
                  {inputTextArray.map((item, key) => {
                    if (item.props.name === 'ssn') {
                      return (
                        <RowItemBlock
                          heading={item.label}
                          key={key}
                          className='add-employee-prompt'>
                          <LineItemBlock className=''>
                            <FormField
                              name={item.props.name}
                              className='form-control'
                              placeholder={item.placeholder}
                              onChange={(e) =>
                                setSocialSecurityNumber(
                                  getNumericValue(e.target.value)
                                )
                              }
                              value={getSocialSecurityNumber}
                              type={item.props.type}
                              errorstyle='profile-form-error'
                            />
                          </LineItemBlock>
                        </RowItemBlock>
                      );
                    } else if (
                      item.props.name === 'cancelAccount' &&
                      !showDeleteEmployeeTickBox
                    ) {
                      return <span key={key}></span>;
                    } else {
                      return (
                        <RowItemBlock
                          heading={item.label}
                          key={key}
                          className='add-employee-prompt'>
                          <LineItemBlock className=''>
                            <FormField
                              name={item.props.name}
                              className='form-control'
                              placeholder={item.placeholder}
                              type={item.props.type}
                              errorstyle='profile-form-error'
                            />
                          </LineItemBlock>
                        </RowItemBlock>
                      );
                    }
                  })}

                  <div className='add-employee-prompt-button-container text-center'>
                    {currentUserState.requesting &&
                    currentUserState.isCreatingEmployee ? (
                      <ButtonLoader />
                    ) : (
                      tprop.tabs[tabkey].add_employee_prompt.buttons.map(
                        (button, key) => (
                          <Button
                            className={`${props.btnColor}`}
                            type={button.props.type}
                            key={key}
                            onClick={() =>
                              closeAddEmployee({
                                buttonType: button.props.type
                              })
                            }>
                            {button.text}
                          </Button>
                        )
                      )
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <div className='add-multiple-employees-container'>
          <div className='add-employee-prompt'>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmitFile}
              validationSchema={validationSchemaForFileUpload(t)}>
              {() => (
                <Form className='form-group'>
                  <RowItemBlock className='add-employee-prompt'>
                    <OverlayTrigger
                      className='tooltip-overlay'
                      trigger={['hover', 'focus']}
                      placement='top'
                      overlay={
                        <Popover id='popover-basic' title='Popover bottom'>
                          <h4 className='popover-header'>
                            {bulkUploadText.tooltip.heading.text}
                          </h4>
                          <div className='popover-body'>
                            <ul>
                              {bulkUploadText.tooltip.description.map(
                                (item, key) => (
                                  <li key={key}>{item.text}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </Popover>
                      }>
                      <span className='d-inline-block'>
                        <h1>{bulkUploadText.heading.text}</h1>
                      </span>
                    </OverlayTrigger>

                    <LineItemBlock className=''>
                      <input
                        id='file'
                        name='file'
                        type='file'
                        className='form-control'
                        errorstyle='profile-form-error'
                        onChange={(event) => {
                          setCsvFile(event.currentTarget.files[0]);
                        }}
                      />
                    </LineItemBlock>
                  </RowItemBlock>

                  <div className='add-employee-prompt-button-container text-center'>
                    {(currentUserState.requesting &&
                      !currentUserState.isCreatingEmployee) ||
                    currentUserState.isUploadingInBulk ? (
                      <ButtonLoader />
                    ) : (
                      <Button className={`${props.btnColor}`} type='submit'>
                        {bulkUploadText.buttons.text}
                      </Button>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
