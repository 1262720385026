/** @format */

import { makeGraphQlRequest } from 'services/graphqlService/makeGraphQlRequest';

/**
 * The purpose of this query is to check whether current user who is logging in
 * is a Signee or and AdminUser. This step is necessary to populate relevant flows
 * that include showing pending approval message
 */

export const fetchBaseDataQuery = (ssn) => {
  return makeGraphQlRequest(`
    {
      AdminUser(ssn:"${ssn}"){
        ssn
        fid
        firstName
        lastName
        phone
        email
        validationCode {
          isVerified
        }
        company{
          name
          organisationId
          signee{
            hasApproved
          }
          employee{
            _id
            ssn: identity
            firstName
            lastName
            email
             signupDate{
               formatted
            }
            firstName
            lastName
          }
          user{
            _id
            firstName
            lastName
            email
            ssn
            email
             signupDate: createdAt{
              formatted
            }
            firstName
            lastName
          }
        }
      }
      Signee(ssn:"${ssn}"){
        ssn
        fid
        name
        email
        hasApproved
        company {
          organisationId
          companyName: name
          contactEmail
          contactPhone
          phoneNumber
          paymentMode
          website
          isContactEmailValidated
          visitingAddress{
            address1
            address2
            postCode
            city
          }
          mailingAddress{
            address1
            address2
            postCode
            city
          }
          adminUser {
            fid
            ssn
            firstName
            lastName
            name
            phone
            email
          }
          signeeList : signee{
            ssn
            fid
            name
            email
            hasApproved
          }
        }
      }
    }
  `);
};
