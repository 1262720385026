/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

export default function CheckBox(props) {
  const { name, onChange, value, checked, url, label } = props;
  return (
    <>
      <div className='checkbox'>
        <input
          type='checkbox'
          id='checkbox'
          name={name}
          onChange={onChange}
          value={value}
          checked={checked}
        />
        <label htmlFor={name}>
          <span>
            {url && <Link to={url}>{label}</Link>}
            {!url && label}
          </span>
        </label>
      </div>
    </>
  );
}
