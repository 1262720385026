/** @format */

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'react-bootstrap';

export default function CompanyNotFoundModal(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const [showModal, setShowModal] = useState(true);

  const toggleCloseModal = () => {
    setShowModal(false);
    history.push({ pathname: props.redirectUrl });
  };

  const headingText = t(`lang_error:companyNotFound.heading.text`);

  const descriptionText = t(`lang_error:companyNotFound.description.text`);

  const buttonText = t(`lang_error:companyNotFound.buttons.text`);
  return (
    <>
      <div>
        <Modal
          className='message-modal session-timeout-modal-container'
          show={showModal}
          onHide={toggleCloseModal}>
          <Modal.Header>
            <Modal.Title className='message-modal-header'>
              {headingText}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='modal-description'>{descriptionText}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              className='btn-pink btn btn-primary'
              onClick={() => toggleCloseModal()}>
              {buttonText}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}
