/** @format */

import { routeService } from 'services';
import { Redirect } from 'react-router-dom';
import getUrlParams from 'utils/getUrlParams';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CenteredMessageLayout from 'components/Layout/CenteredMessageLayout';
import {
  loginRequestAction,
  logoutRequestAction
} from 'stateContainer/actions';

export default function LoggingIn(props) {
  const { tprop } = props;
  const dispatch = useDispatch();
  const [getError, setError] = useState();
  const sessionState = useSelector((state) => state.session);
  const { corporateApprovalData } = useSelector((state) => state.currentUser);
  const { isAuthenticated, requesting } = sessionState || {};

  const { id_token, error, error_description } = getUrlParams([
    'error',
    'id_token',
    'error_description'
  ]);

  const successUrl = corporateApprovalData
    ? routeService.register_agree_to_terms
    : routeService.register_choose_company;

  useEffect(() => {
    if (id_token && !isAuthenticated) {
      dispatch(
        loginRequestAction({
          token: id_token,
          successUrl: successUrl,
          failedUrl: routeService.register_login_mode,
          dashboardUrl: routeService.dashboard_homepage
        })
      );
    } else if (error) {
      dispatch(logoutRequestAction());
      setError(error_description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const translation = error ? tprop.error : tprop.success;

  return isAuthenticated && !requesting ? (
    <Redirect to={routeService.register_choose_company}></Redirect>
  ) : (
    <>
      <CenteredMessageLayout
        description={translation.description.text}
        error={getError}
        className='centered-content-theme-transparent'
      />
    </>
  );
}
