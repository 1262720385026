/** @format */

import config from 'config';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button from 'react-bootstrap/Button';
import CheckBox from 'components/Forms/CheckBox';
import routeService from 'services/routeService';
import SplitLayout from 'components/Layout/SplitLayout';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { setIsPaymentModeChosenAction } from 'stateContainer/actions';

export default function ModeOfPayment(props) {
  const { tprop } = props;
  const dispatch = useDispatch();

  const [isPaymentModeChosen, setIPaymentModeChosen] = useState(false);

  const onSubmit = () => {
    return dispatch(setIsPaymentModeChosenAction({ isPaymentModeChosen }));
  };

  const buttonUrl =
    props.flowOrigin === config.constants.flows.registration
      ? routeService.register_summary
      : routeService.dashboard_summary;

  const PageContent = () => {
    return (
      <div className='content'>
        <div className='text-content'>
          <div className='split-layout-left-heading-container'>
            <h1>{tprop.heading.text}</h1>
          </div>
          <span className='split-layout-left-subheading-container'>
            <p>{tprop.description.text}</p>
          </span>
        </div>

        <div className='terms-checkbox'>
          <CheckBox
            name='invoice'
            value='isPaymentModeChosen'
            checked={isPaymentModeChosen}
            onChange={() => setIPaymentModeChosen(!isPaymentModeChosen)}
            label={tprop.input[0].text}
          />
        </div>

        <Link to={buttonUrl}>
          <Button
            className={`${props.btnColor}`}
            disabled={!isPaymentModeChosen}
            onClick={() => onSubmit()}>
            {tprop.buttons[0].text}
          </Button>
        </Link>
      </div>
    );
  };

  return (
    <div className='mode-of-payment-view'>
      {props.flow === config.constants.flows.dashboard ? (
        <div id='dashboard-flow'>
          <DashboardLayout
            {...props}
            hidePreviousButton={true}
            goBackUrl={routeService.onboarding_welcome_to_onboarding}>
            <PageContent />
          </DashboardLayout>
        </div>
      ) : (
        <div id='registration-flow'>
          <SplitLayout
            flow={props.flow}
            stepsWizard={props.stepsWizard}
            className='postlogin-template mode-of-payment-content'
            backgroundImage='registration-welcome-left-background-image'>
            <PageContent />
          </SplitLayout>
        </div>
      )}
    </div>
  );
}
